import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  insideTabContainer: {
    "&.sb-card": {
      borderRadius: 0,
      boxShadow: "none"
    },
  },
  root:{
  }
}));

export default useStyles;
