import React, { useState } from "react";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import MainHoursEdit from "../mainHours/MainHoursEdit";
import SbuxHeading from "../../../../../components/SbuxHeading";
import SbuxLayer from "../../../../../components/SbuxLayer";
import SbuxDialog from "../../../../../components/SbuxDialog";
import { getLastUpdated } from "../../../utils";
import { ellipse } from "../../../../../utils/stringUtil";
import useStyles from "../../styles";

const MainHoursMasterRow = ({ row }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleMainHoursEditDialogOpen = () => {
    setOpen((p) => !p);
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={toggleMainHoursEditDialogOpen}
        >
          {row["hoursCode"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={toggleMainHoursEditDialogOpen}
        >
          {ellipse(row["hoursName"])}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={toggleMainHoursEditDialogOpen}
        >
          {ellipse(row["description"])}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={toggleMainHoursEditDialogOpen}
        >
          {getLastUpdated(row)}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>

      {open && (
        <SbuxLayer isOpen={open}>
          <SbuxDialog
            toggleDialogOpen={toggleMainHoursEditDialogOpen}
            isrenderCloseButton={true}
            isClickAwayEnabled={true}
          >
            <div>
              <SbuxHeading
                tagName="h6"
                size="xs"
                className={classes.dialogTitle}
              >
                {`Details`}
              </SbuxHeading>
              <MainHoursEdit
                row={row}
                toggleMainHoursEditDialogOpen={toggleMainHoursEditDialogOpen}
              />
            </div>
          </SbuxDialog>
        </SbuxLayer>
      )}
    </>
  );
};

export default MainHoursMasterRow;
