import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import Divider from "@mui/material/Divider";
import { dialByPhoneNumber } from "../../../content/common/agentCall";
import { useDispatch } from "react-redux";
import { setHistoryDialNumberValidateStatus } from "../../../../services/history";
import useStyles from "../styles";

const PhoneNumberDialog = ({
  openDialPrompt,
  parsedPhoneNumber,
  toggleOpenDialPrompt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOkayDialNumber = () => {
    dispatch(setHistoryDialNumberValidateStatus(null));
    dialByPhoneNumber(
      parsedPhoneNumber.formattedPhoneNumber,
      toggleOpenDialPrompt,
      dispatch
    );
  };

  return (
    <>
      <SbuxLayer isOpen={openDialPrompt}>
        <SbuxDialog
          toggleDialogOpen={toggleOpenDialPrompt}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              <SbuxTranslate>{`Dial Phone Number`}</SbuxTranslate>
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <SbuxTranslate>{`Are you sure you would like to dial`}</SbuxTranslate>
              <span>{` ${parsedPhoneNumber.phoneNumber}?`}</span>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton type="save" onClick={handleOkayDialNumber}>
                <SbuxTranslate>{"Okay"}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="cancel" onClick={toggleOpenDialPrompt}>
                <SbuxTranslate>{"Cancel"}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default PhoneNumberDialog;
