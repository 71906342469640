import { Paper, Box } from "@mui/material";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import {
  getStopContactData,
  deleteStopContact,
} from "../../../../../services/stopContact";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { EditStopContactsRow } from "./EditStopContactRow";
import { columns } from "./columns";
import SbuxLoader from "../../../../../components/SbuxLoader";

const StopContactTable = () => {
  const dispatch = useDispatch();
  const [isDisableAddNewButton, setIsDisableAddNewButton] = useState(true);
  const [newStopContactData, setNewStopContactData] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const fetchStopContactData = async () => {
    await dispatch(getStopContactData());
  };

  useEffect(() => {
    fetchStopContactData();
  }, []);

  const {
    stopContact: { stopContactData, stopContactStatus },
  } = useSelector((state) => state);

  useEffect(() => {
    if (stopContactData.length === 0 || selectedContacts.length === 0) {
      setIsDisableAddNewButton(true);
    } else {
      setIsDisableAddNewButton(false);
    }
    setNewStopContactData(stopContactData);
  }, [stopContactData]);

  const handleStopContact = async () => {
    const res = await dispatch(deleteStopContact(selectedContacts));
    if (res?.payload?.status === 200) {
      setIsDisableAddNewButton(true);
    }
  };

  const handleEditRowClick = (row) => {
    const updatedTableData = [...newStopContactData];
    const index = updatedTableData.findIndex(
      (item) => item.contactId === row.contactId
    );
    if (index > -1) {
      updatedTableData[index] = row;
      const selectedVal = updatedTableData.filter((item) => item.isChecked);
      selectedVal.length === 0
        ? setIsDisableAddNewButton(true)
        : setIsDisableAddNewButton(false);
      setSelectedContacts(selectedVal);
      setNewStopContactData(updatedTableData);
    }
  };

  if (stopContactStatus === "pending") {
    return <SbuxLoader />;
  }

  return (
    <SbuxEnhancedTable
      rows={newStopContactData}
      columns={columns}
      defaultSortingField={"name"}
      defaultSortOrder={"asc"}
      RowSource={EditStopContactsRow}
      showFooter={true}
      showSearchBar={true}
      isManager={true}
      showAddNewButton
      handleAddNewButtonClick={handleStopContact}
      isdisableAddNewButton={isDisableAddNewButton}
      addNewButtonTitle={<SbuxTranslate>{"Delete"}</SbuxTranslate>}
      isCustomButtonDisabled={!newStopContactData?.length}
      handleEditRowClick={handleEditRowClick}
      handleRefreshClick={fetchStopContactData}
    />
  );
};

export default StopContactTable;
