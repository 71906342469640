import { useState } from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import SbuxTextField from "../../../../components/SubxTextField";
import useStyles from "../styles";
import {
  getNumberErrorMessage,
  getTextLengthErrorMessage,
  getDuplicateNameErrorMessage,
} from "../utils";
import { isNumeric } from "../utils";

export const DataManagementCustomInput = ({
  rows,
  value,
  handleInputChange,
  type,
  required,
  data,
  formValues,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const classes = useStyles();

  const checkDuplicate = (data, value) => {
    if (data?.partitionKey) {
      const index = rows.findIndex(
        (row) =>
          row[data?.name]?.toString()?.trim() === value?.toString()?.trim()
      );
      if (index >= 0) {
        return true;
      }
    }
    return false;
  };

  const validateInput = (data, value) => {
    if (type === "number" && value && !isNumeric(value)) {
      return getNumberErrorMessage(data.label, type);
    }
    return "";
  };

  const handleCustomInputChange = (data, value) => {
    let localIsError = false;
    let localErrorMsg = "";
    setInputValue(value);

    localErrorMsg = validateInput(data, value);
    if (localErrorMsg) {
      localIsError = true;
      setErrorMessage(localErrorMsg);
      setIsError(true);
    } else if (data && data.maxLength && value.length > data.maxLength) {
      localIsError = true;
      localErrorMsg = getTextLengthErrorMessage(data.maxLength);
      setErrorMessage(localErrorMsg);
      setIsError(true);
    } else if (checkDuplicate(data, value)) {
      localIsError = true;
      localErrorMsg = getDuplicateNameErrorMessage(value);
      setErrorMessage(localErrorMsg);
      setIsError(true);
    } else {
      localIsError = false;
      setErrorMessage("");
      setIsError(false);
    }

    const newData = {
      ...data,
      isError: localIsError,
      errorMessage: localErrorMsg,
    };
    if (value) {
      newData.value = value;
    }
    formValues.set(newData.label, newData);
    return handleInputChange(newData, value, formValues);
  };

  return (
    <Box className={classes.inputRoot}>
      {type === "boolean" ? (
        <FormControl className={classes.formControl}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={inputValue}
            onChange={(e) => {
              return handleCustomInputChange(data, e.target.value);
            }}
            disabled={data?.disabled}
            required={required}
            defaultChecked={"true"}
          >
            <Box className={classes.radioGroup}>
              <FormControlLabel
                value="true"
                control={<Radio disabled={disabled} />}
                label={<Box className={classes.textField}>{"True"}</Box>}
              />
              <FormControlLabel
                value="false"
                control={<Radio disabled={disabled} />}
                label={<Box className={classes.textField}>{"False"}</Box>}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      ) : (
        <>
          <SbuxTextField
            variant={"outlined"}
            value={inputValue}
            handleChange={(e) => {
              return handleCustomInputChange(data, e.target.value);
            }}
            errorMessage={errorMessage}
            isError={isError}
            disabled={data?.disabled || disabled}
            displayCounter={true}
            maxLength={data?.maxLength}
          />
        </>
      )}
    </Box>
  );
};
