import { useEffect, useState } from "react";
import SbuxLoader from "../../components/SbuxLoader";
import { useDispatch, useSelector } from "react-redux";
import InitCcp from "./components/InitCcp";
import { getCcpCredentials } from "../../services/ccp";

const CCP = () => {
  const dispatch = useDispatch();
  const {
    userInstance: { connectedInstance: { instanceId, instanceUrl } = {} },
    ccp: {
      status,
      isCcpInitialized,
      ccpCredentials: {
        AccessToken,
        AccessTokenExpiration,
        RefreshToken,
        RefreshTokenExpiration,
      },
    },
  } = useSelector((state) => state);

  const [loginUrl, setLoginUrl] = useState(null);
  const [ccpUrl, setCcpUrl] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    instanceId && !isCcpInitialized && dispatch(getCcpCredentials(instanceId));
  }, [instanceId, isCcpInitialized]);

  useEffect(() => {
    if (AccessToken) {
      const actionUrl = `${instanceUrl}/auth/sign-in`;
      const loginFormUrl = `connect-login.html?accessToken=${AccessToken.trim()}
      &accessTokenExpiration=${AccessTokenExpiration.trim()}
      &refreshToken=${RefreshToken.trim()}
      &refreshTokenExpiration=${RefreshTokenExpiration.trim()}
      &actionUrl=${actionUrl.trim()}`;
      setLoginUrl(loginFormUrl);
      setCcpUrl(`${instanceUrl}/${process.env.REACT_APP_CONNECT_CCP_VERSION}`);
    }
  }, [AccessToken]);

  return (
    <>
      {status !== "success" ? (
        <SbuxLoader />
      ) : (
        loginUrl && ccpUrl && <InitCcp ccpUrl={ccpUrl} loginUrl={loginUrl} />
      )}
    </>
  );
};

export default CCP;
