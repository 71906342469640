import React, { useState, useEffect, useCallback, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TemporaryMsgAutoCompleteTimeTextField from "./TemporaryMsgAutoCompleteTimeTextField";
import {
  isEndDateTimeValid,
  isStartDateTimeValid,
  isStartAndEndDateTimeValid,
} from "../../../utils";
import moment from "moment";

const TemporaryMsgAutoCompleteTime = ({
  name,
  startDate,
  endDate,
  startTimeValue,
  endTimeValue,
  startTimeOptions,
  endTimeOptions,
  handleStartTimeChange,
  handleEndTimeChange,
  startTimeOption,
  endTimeOption,
  disabled = false,
  row,
  timeZone,
}) => {
  const [startOptions, setStartOptions] = useState([]);
  const [endOptions, setEndOptions] = useState([]);
  const [startInputValue, setStartInputValue] = useState("");
  const [endInputValue, setEndInputValue] = useState("");
  const [startTimeError, setStartTimeError] = useState(false);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState("");
  const [endTimeError, setEndTimeError] = useState(false);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState("");
  let rowObj = Object.assign({}, row);

  useEffect(() => {
    startTimeOptions &&
      startTimeOptions.length > 0 &&
      setStartOptions(startTimeOptions);
    endTimeOptions &&
      endTimeOptions.length > 0 &&
      setEndOptions(endTimeOptions);
  }, []);

  useEffect(() => {
    startTimeValue && setStartInputValue(startTimeValue);
    endTimeValue && setEndInputValue(endTimeValue);

    if (startTimeValue !== startInputValue) {
      setEndTimeErrorMessage("");
      setEndTimeError(false);
    }
  }, [startTimeValue, endTimeValue]);

  useEffect(() => {
    startTimeValue &&
      endTimeValue &&
      handleEndDateAndTimeOnChange(
        startDate,
        endDate,
        startTimeValue,
        endTimeValue
      );
  }, [startDate, endDate, endTimeValue, rowObj?.status, timeZone]);

  const updated = useRef(false);
  useEffect(() => {
    if (updated.current) {
      if (startTimeValue) {
        const isSEValid = isStartAndEndDateTimeValid(
          startDate,
          endDate,
          startTimeValue,
          endTimeValue,
          startTimeOption,
          endTimeOption,
          timeZone
        );
        if (isSEValid) {
          setStartTimeErrorMessage("");
          setStartTimeError(false);
        } else {
          setStartTimeErrorMessage("Please enter a valid Start time");
          setStartTimeError(true);
        }
        handleEndDateTimeError(
          startDate,
          endDate,
          startTimeValue,
          endTimeValue,
          timeZone
        );
      }
    }
    if (!updated.current) {
      updated.current = true;
    }
  }, [
    startDate,
    endDate,
    startTimeValue,
    endTimeValue,
    startTimeOption,
    endTimeOption,
    timeZone,
  ]);

  const handleEndDateAndTimeOnChange = (
    startDate,
    endDate,
    startTime,
    endTime
  ) => {
    handleEndDateTimeError(
      startDate,
      endDate,
      startTimeValue,
      endTime,
      timeZone
    );

    setEndInputValue(endTime);
  };

  const handleStartTimeOnInputChange = (event, newValue) => {
    handleStartDateTimeError(newValue, startDate, endInputValue, timeZone);
    setStartInputValue(newValue);
    moment(newValue, "hh:mm A", true).isValid() &&
      handleStartTimeChange(newValue);
  };

  const handleStartDateTimeError = (newValue, startDate, endInputValue) => {
    if (isStartDateTimeValid(newValue, startDate, endInputValue, timeZone)) {
      setStartTimeErrorMessage("");
      setStartTimeError(false);
    } else {
      setStartTimeErrorMessage("Please enter a valid Start time");
      setStartTimeError(true);
    }
  };
  const handleEndDateTimeError = (
    startDate,
    endDate,
    startTimeValue,
    endTimeValue
  ) => {
    if (
      isEndDateTimeValid(
        startDate,
        endDate,
        startTimeValue,
        endTimeValue,
        startTimeOption,
        endTimeOption,
        timeZone
      )
    ) {
      setEndTimeErrorMessage("");
      setEndTimeError(false);
    } else {
      setEndTimeErrorMessage("Please enter a valid End time");
      setEndTimeError(true);
    }
  };

  const handleEndTimeOnInputChange = (event, newValue) => {
    handleEndDateAndTimeOnChange(
      startDate,
      endDate,
      startInputValue,
      newValue,
      rowObj.status,
      timeZone
    );
    handleEndTimeChange(newValue);
  };

  return (
    <Autocomplete
      size="small"
      disableClearable
      freeSolo
      value={name === "Start Time" ? startInputValue : endInputValue}
      inputValue={name === "Start Time" ? startInputValue : endInputValue}
      onInputChange={
        name === "Start Time"
          ? handleStartTimeOnInputChange
          : handleEndTimeOnInputChange
      }
      sx={{
        fontSize: 14,
        marginTop: -0.5,
        "& .MuiInputBase-input": {
          height: "1rem",
        },
      }}
      disabled={disabled}
      options={
        name === "Start Time"
          ? startOptions && startOptions.map((option) => option.label)
          : endOptions && endOptions.map((option) => option.label)
      }
      renderInput={(params) => (
        <TemporaryMsgAutoCompleteTimeTextField
          isError={name === "Start Time" ? startTimeError : endTimeError}
          errorMessage={
            name === "Start Time" ? startTimeErrorMessage : endTimeErrorMessage
          }
          params={params}
        />
      )}
      ListboxProps={{
        style: {
          maxHeight: 150,
          fontSize: 12,
        },
      }}
    />
  );
};

export default TemporaryMsgAutoCompleteTime;
