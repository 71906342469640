import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import SbuxStyledTablePagination from "./SbuxStyledTablePagination";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import SbuxTablePaginationActions from "./SbuxTablePaginationActions";
import SbuxTranslate from "../SbuxTranslate";
import SbuxEnhancedTableHead from "./SbuxEnhancedTableHead";
import SbuxButton from "../SbuxButton";
import { StyledGridOverlay } from "../SbuxDataGrid/styled";
import { Paper, Box, Tab } from "@mui/material";
import { getRowsPerPage } from "../../features/content/utils";
import useTextTranslate from "../../features/content/hooks/useTextTranslate";
import { DARK_GRAY_HOVER, DARK_GRAY } from "../../constants";

const SbuxBasicTable = ({
  BodySource,
  headerGroupCells,
  headerCells,
  rows,
  rowsPerPage,
  page,
  order,
  orderBy,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRefreshClick,
  onRequestSort,
  showFooter,
  showModalTable = false,
  defaultRowsPerPage,
  isManager = false,
  isTranslationRequired = true,
}) => {
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const textOfTranslatedText = useTextTranslate("of");

  return (
    <>
      <Paper>
        <TableContainer
          sx={{
            height:
              showModalTable && isManager // admin modal
                ? "35vh"
                : showModalTable && !isManager //history modal
                ? "65vh"
                : "75vh",
            width: "100%",
            minWidth: showModalTable && !isManager ? "29vw" : "100%",
            overflowY: showModalTable && !isManager ? "hidden" : "auto",
            overFlowX: "hidden",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: showModalTable && isManager ? "35vh" : "59vh",
              overflowY: rows && rows.length === 0 ? "hidden" : "auto",
            }}
          >
            <Table stickyHeader aria-label="custom pagination table">
              <SbuxEnhancedTableHead
                headerGroupCells={headerGroupCells}
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                isTranslationRequired={isTranslationRequired}
              />

              <TableBody>
                {rows && rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ border: "none" }}>
                      <Box
                        sx={{
                          marginTop:
                            showModalTable && isManager ? "-20%" : "-10%",
                        }}
                      >
                        <StyledGridOverlay>
                          <img
                            width="120"
                            height="100"
                            src={"/images/instance.png"}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: 2,
                              fontSize: 20,
                              fontWeight: 600,
                              color: "#006241",
                            }}
                          >
                            {isTranslationRequired ? (
                              <SbuxTranslate>{`No data found`}</SbuxTranslate>
                            ) : (
                              <Box>{`No data found`}</Box>
                            )}
                            <Box
                              sx={{
                                paddingTop: 2,
                                fontSize: 15,
                                fontWeight: 600,
                              }}
                            >
                              <SbuxButton
                                type="save"
                                height={28}
                                width={80}
                                onClick={handleRefreshClick}
                              >
                                {isTranslationRequired ? (
                                  <SbuxTranslate>{`Refresh`}</SbuxTranslate>
                                ) : (
                                  "Refresh"
                                )}
                              </SbuxButton>
                            </Box>
                          </Box>
                        </StyledGridOverlay>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    <BodySource />
                    {emptyRows > 0 && <TableRow />}
                  </>
                )}
              </TableBody>
              {showFooter && (
                <TableFooter
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderBottom: "1px solid #fff",
                    backgroundColor: "#f2f2f2",
                    position: "absolute",
                    bottom: isManager ? "-1%" : 0,
                    height: 50,
                    width: "100%",
                  }}
                >
                  <TableRow
                    sx={{
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    <SbuxStyledTablePagination
                      rowsPerPageOptions={getRowsPerPage(defaultRowsPerPage)}
                      labelRowsPerPage={
                        isTranslationRequired ? (
                          <SbuxTranslate>{`Rows per page`}</SbuxTranslate>
                        ) : (
                          <>{`Rows per page`}</>
                        )
                      }
                      labelDisplayedRows={(from = page) =>
                        `${from.from}-${
                          from.to === -1 ? from.count : from.to
                        } ${textOfTranslatedText} ${from.count}`
                      }
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        MenuProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              "&:hover": {
                                backgroundColor: DARK_GRAY_HOVER,
                              },
                              "&.Mui-selected": {
                                backgroundColor: DARK_GRAY,
                                color: "white",
                                "&:hover": {
                                  backgroundColor: DARK_GRAY,
                                },
                              },
                            },
                          },
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={SbuxTablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Box>
        </TableContainer>
      </Paper>
    </>
  );
};

export default SbuxBasicTable;
