import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputButton: {
    height: 25,
    marginRight: 5,
    "&:focus": {
      outline: "0 !important",
    },
    padding: "4px 10px",
  },
  input: {
    "& .MuiInput-input": {
      height: 15,
      fontSize: 13,
      border: "1px solid",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderRadius: 5,
      maxWidth: 100,
      marginRight: "10%",
      verticalAlign: "middle",
      backgroundColor: "white",
      padding: 4,
    },
  },
  inputValue: {
    maxWidth: 150,
    overflowWrap: "break-word",
    paddingTop: 4,
    marginRight: "6%",
  },
  dialogRoot: {
    minWidth: 500,
    minHeight: 120,
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogButton: {
    marginRight: 10,
  },
  cellFocus: {
    cursor: "pointer",
  },
}));

export default useStyles;
