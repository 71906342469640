import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  numberInput: {
    "& > input": {
      width: `43px !important`,
    },
  },
}));

export default useStyles;
