import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";

const AutoCompleteTextField = ({ isError, errorMessage, params }) => {
  return (
    <Box>
      <TextField
        autoComplete="chrome-off"
        variant="standard"
        {...params}
        error={isError}
        sx={{
          width: 120,
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiInput-root": {
            fontSize: 14,
            height: 21,
            marginRight: 2,
            width: "100%",
          },
          "& .MuiInput-underline": {
            borderBottom: 1.5,
            borderBottomColor: isError ? "red" : "#006241 !important",
          },
          "& .MuiInput-underline:focus-within": {
            borderBottomColor: isError ? "red" : "#006241",
          },
          "& .MuiInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "& .MuiInput-root:before": {
            borderBottom: 0,
          },
          "& .MuiInputBase-input": {
            fontSize: 14,
            padding: 0,
          },
          "& .MuiInput-underline:after": {
            borderBottom: 0,
          },
          "input[type='time']::-webkit-calendar-picker-indicator": {
            display: "none",
          },
        }}
      />
      {isError && (
        <Tooltip
          title={
            <Box
              sx={{
                backgroundColor: "#006241",
                color: "white",
                fontSize: 14,
              }}
            >
              {errorMessage}
            </Box>
          }
        >
          <IconButton
            disableFocusRipple
            disableRipple
            sx={{
              cursor: "pointer",
              color: "red",
              fontSize: 16,
              width: "2vw",
              marginTop: 2,
              height: 18,
              "&:focus": {
                outline: 0,
              },
              "&.MuiIconButton-root": {
                padding: 0,
                paddingLeft: "5px",
              },
            }}
          >
            <ErrorOutlineOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default AutoCompleteTextField;
