import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClosureMsg,
  updateRepeatOccureneceClosureMsg,
} from "../../../../services/closureMsg";
import useStyles from "../styles";

const ClosureSingleDeleteDialog = ({
  row,
  title,
  name,
  open,
  isSingleInstance,
  toggleDialogOpen,
  toggleCancelDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    closureMsg: { closureOriginalData },
  } = useSelector((state) => state);

  const handleOkayClick = async () => {
    toggleDialogOpen();
    if (row.type === "Repeat" && isSingleInstance) {
      let occurrenceId = null;
      for (const key in row.occurrences) {
        const value = row.occurrences[key];
        if (
          value.startDate === row.startDate &&
          value.endDate === row.endDate
        ) {
          occurrenceId = key;
        }
      }

      const deleteSingleOccurence = {
        id: row.id,
        hoursCode: row.hoursCode,
        occurrenceId: occurrenceId,
        repeatPattern: { ...row.repeatPattern },
      };
      await dispatch(deleteClosureMsg(deleteSingleOccurence));
    } else {
      const deleteObj = {
        id: row.id,
        hoursCode: row.hoursCode,
      };
      await dispatch(deleteClosureMsg(deleteObj));
    }
  };

  return (
    <>
      <SbuxLayer isOpen={open}>
        <SbuxDialog
          isModalOnModal={true}
          toggleDialogOpen={toggleDialogOpen}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
          toggleModalOnModalDialog={toggleCancelDialog}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              {title}
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <SbuxTranslate>{`Are you sure you want to delete `}</SbuxTranslate>
              <span>{`${name}?`}</span>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton type="save" onClick={handleOkayClick}>
                <SbuxTranslate>{`Okay`}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="cancel" onClick={toggleCancelDialog}>
                <SbuxTranslate>{`Cancel`}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default ClosureSingleDeleteDialog;
