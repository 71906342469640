import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    textTransform: "none",
    paddingBottom: 4,
    marginRight: 4,
    "& .MuiSvgIcon-root": {
      fontSize: "3rem",
      color: "#006241",
      right: 1,
      paddingBottom: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#006241 !important",
    },

    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize: 13,
      padding: "12px 26px 3px 12px",
      "&:focus": {
        borderRadius: 4,
      },
    },
  },
  menuItem: {
    height: 26,
    "&.MuiMenuItem-root": {
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: "#006241",
      fontWeight: 400,
    },
    "&.MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#006241",
      color: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#006241",
      },
    },
  },
}));

export default useStyles;
