import Dialog from "@cxeweb/pattern-library/lib/components/dialog";
import useStyles from "./styles";

const SbuxDialog = ({
  toggleDialogOpen,
  imageUrl,
  width,
  height,
  confirmText,
  cancelText,
  isrenderCloseButton,
  isClickAwayEnabled = false,
  handleConfirm,
  isButtonDisabled = false,
  renderButtons = true,
  isModalOnModal = false,
  toggleModalOnModalDialog = null,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      imageUrl={imageUrl}
      imageAltText={`image within a dialog`}
      imageProps={{
        wrapInFlex: {
          enabled: true,
          width,
          height,
        },
      }}
      confirmText={confirmText}
      confirmButtonProps={{
        disabled: isButtonDisabled,
        visualStyle: "default",
        className: `${classes.saveButton}`,
      }}
      cancelButtonProps={{
        visualStyle: "default",
        className: `${classes.cancelButton}`,
      }}
      cancelText={cancelText}
      onCancel={isModalOnModal ? toggleModalOnModalDialog : toggleDialogOpen}
      onConfirm={handleConfirm}
      onMaskClick={() => {
        isClickAwayEnabled && toggleDialogOpen();
      }}
      renderCloseButton={isrenderCloseButton}
      useLegacyVersion
      renderButtons={renderButtons}
    >
      {children}
    </Dialog>
  );
};

export default SbuxDialog;
