import { useEffect, useState } from "react";
import SbuxEnhancedTable from "../../../../components/SbuxTable/SbuxEnhancedTable";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import QuickConnectsRow from "./QuickConnectsRow";
import { quickConnectsColumns, quickConnectsFilterColumns } from "./columns";
import { CONTACT_CONNECTED_STATUS } from "../../../../constants";

const QuickConnectsTable = ({
  endpoints,
  ccpContactStatus,
  filteredItem,
  setFilteredItem,
  quickConnectsReset,
}) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (endpoints && endpoints.length > 0) {
      let mappedRows =
        endpoints.length > 0 &&
        endpoints.reduce((acc, cur, index) => {
          const curValues = Object.values(cur);
          const isExists = curValues.some((f) => filteredItem.includes(f));
          if (isExists) {
            acc.push({
              id: index,
              ...cur,
            });
          }
          return acc;
        }, []);
      mappedRows =
        ccpContactStatus === CONTACT_CONNECTED_STATUS
          ? mappedRows
          : mappedRows.filter((endpoint) => endpoint.phoneNumber !== null);
      setRows(mappedRows);
    }
  }, [endpoints, ccpContactStatus, filteredItem]);

  return (
    <SbuxEnhancedTable
      rows={quickConnectsReset && filteredItem.length === 0 ? [] : rows}
      filterColumns={quickConnectsFilterColumns}
      filterTitle={<SbuxTranslate>{"Quick Connects Filter"}</SbuxTranslate>}
      columns={quickConnectsColumns}
      defaultSortingField={"name"}
      defaultSortOrder={"asc"}
      defaultFilterColumns={filteredItem}
      setFilteredItem={setFilteredItem}
      RowSource={QuickConnectsRow}
      hasExpandAll={false}
    />
  );
};

export default QuickConnectsTable;
