import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { createOrUpdateTemporaryMsg } from "../../../../services/temporaryMsg";
import useStyles from "../styles";

const TemporaryMsgDisableDialog = ({
  row,
  title,
  name,
  open,
  toggleDialogOpen,
  toggleCancelDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOkayClick = async () => {
    toggleDialogOpen();
    await dispatch(
      createOrUpdateTemporaryMsg({
        ...row,
        disabled: true,
      })
    );
  };

  return (
    <>
      <SbuxLayer isOpen={open}>
        <SbuxDialog
          isModalOnModal={true}
          toggleDialogOpen={toggleDialogOpen}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
          toggleModalOnModalDialog={toggleCancelDialog}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              {title}
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <SbuxTranslate>{`Are you sure you want to disable `}</SbuxTranslate>
              <span>{`${name}?`}</span>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton type="save" onClick={handleOkayClick}>
                <SbuxTranslate>{`Okay`}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="cancel" onClick={toggleCancelDialog}>
                <SbuxTranslate>{`Cancel`}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default TemporaryMsgDisableDialog;
