import { makeStyles } from "@mui/styles";
import { COLOR_GREEN_ACCENT, COLOR_RED } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    height: "auto",
    minHeight: 100,
    padding: 8,
  },
  button: {
    marginLeft: "8px !important",
    borderRadius: "28px !important",
    fontWeight: 600,
    boxShadow: "none !important",
    lineHeight: 1.2,
    textTransform: "none",
    width: "auto",
    "&:hover": {
      boxShadow: "none !important",
      backgroundColor: "unset !important",
    },
    "&:focus": {
      outlineColor: "inherit !important",
    },
    "& + .MuiButton-outlined:not([disabled])": {
      // Works for a random button only.. wha wha?
      backgroundColor: "unset !important",
      borderColor: `${COLOR_GREEN_ACCENT} !important`,
      color: `${COLOR_GREEN_ACCENT} !important`,
    },
    "& + .MuiButton-contained": {
      backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
    },
  },
  green: {
    backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
    "&:hover": {
      boxShadow: "none !important",
      backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
    },
  },
  greenO: {
    backgroundColor: "unset !important",
    borderColor: `${COLOR_GREEN_ACCENT} !important`,
    color: `${COLOR_GREEN_ACCENT} !important`,
  },
  row: {
    display: "flex",
    alignItems: "center",
    paddingTop: 8,

    "& > .MuiIconButton-root": {
      width: 30,
    },
    // input
    "& > .MuiBox-root > .MuiFormControl-root": {
      width: "100%",
      maxWidth: "150px",
      margin: 0,
      height: 27,
      "& > .MuiInputBase-root": {
        height: 32,
        padding: 6,
        "& > .MuiInputBase-input": {
          height: 32,
          fontSize: 14,
          marginBottom: 0,
        },
      },
      "& + .MuiTextField-root": {
        marginTop: 2,
        marginBottom: 2,
        height: 32,
      },
    },
    // dropdown
    "& > .MuiFormControl-root": {
      "& > .MuiInputBase-root": {
        height: 32,
        padding: 0,
        width: 130,
        flexShrink: 0,
        marginRight: 8,
      },
    },

    "& > span": {
      flex: "1 1 auto",
      fontSize: 12,
      width: 100,
      maxWidth: 100,
      flexShrink: 0,
    },
    "& > .MuiInputBase-root": {
      height: 32,
      padding: 0,
    },
    "& > .react-datepicker-wrapper": {
      width: "auto",
    },
  },
  datePicker: {
    padding: 4,
    width: 150,
    height: 32,
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    fontSize: 12,
  },
  filterBar: {
    height: 35,
    display: "flex",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 8,

    "& > .MuiIconButton-root": {
      outline: "none !important",
      color: "red",
    },

    "& > span.title": {
      fontSize: 12,
    },
  },
  actionButtons: {
    display: "flex",
    paddingTop: 8,
    justifyContent: "end",
  },
  actionTop: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 8,
    "& > span": {
      fontSize: 12,
      width: "auto",
      marginRight: 8,
    },
  },
  badge: {
    backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
  },
  invisible: {
    visibility: "hidden",
  },
  validator: {
    outline: `0 !important`,
    color: `${COLOR_RED} !important`,
    "&:focus": {
      outline: 0,
    },
  },
}));

export default useStyles;
