import SbuxEnhancedTableHead from "./SbuxEnhancedTableHead";
import { Paper, TableContainer, Table, TableBody } from "@mui/material";

const SbuxCustomTable = ({
  width,
  height,
  columns,
  isTranslationRequired,
  children,
  tableLayout = "initial",
}) => {
  return (
    <TableContainer sx={{ width: width, height: height, overflowX: "hidden" }}>
      <Table stickyHeader sx={{ width, tableLayout }}>
        <SbuxEnhancedTableHead
          headerCells={columns}
          isTranslationRequired={isTranslationRequired}
        />
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SbuxCustomTable;
