import Card from "@cxeweb/pattern-library/lib/components/card";
import useStyles from "./styles";

const SbuxCard = ({ imagePosition, imageUrl, children, className="root" }) => {
  const classes = useStyles();
  return (
  <Card imagePosition={imagePosition} imageUrl={imageUrl} containerClasses={classes[className]}>
    {children}
  </Card>
  );
};

export default SbuxCard;
