import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import { MEDIA_FILE_DOES_NOT_EXISTS } from "../../../../constants";

const MediafileAutoComplete = ({
  data,
  handleSelectChange = null,
  value,
  disabled,
  width = null,
  maxWidth = null,
  height = null,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState(null);
  const {
    mediaMgt: { mediaMgtData },
  } = useSelector((state) => state);

  useEffect(() => {
    setOptions(data);
    setSelectedValue(null);
  }, [data]);

  useEffect(() => {
    if (value) {
      const result = mediaMgtData.data.find((item) => item.filePath === value);
      setSelectedValue({
        name: result.fileName,
        value,
      });
    }
  }, [value]);

  const handleHighlightChange = (event, option, reason) => {
    if (option && reason === "keyboard") {
      setSelectedValue(option);
    }
  };
  const handleOnChange = (event, newValue) => {
    const selected = newValue?.value ?? null;
    setSelectedValue(selected);
    handleSelectChange(selected);
  };
  const handleClearButtonClick = () => {
    setSelectedValue(null); // Clear the selected value
    handleSelectChange(null);
  };

  return (
    <Autocomplete
      size="small"
      disableClearable
      disablePortal
      options={!options ? [{ name: "loading...", value: "" }] : options}
      autoHighlight
      noOptionsText={
        <Box sx={{ fontSize: 14 }}>{MEDIA_FILE_DOES_NOT_EXISTS}</Box>
      }
      disabled={disabled}
      value={selectedValue}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onHighlightChange={handleHighlightChange}
      onChange={handleOnChange}
      ListboxProps={{
        sx: { fontSize: 13 },
      }}
      sx={{
        "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
          fontSize: 13,
        },
        textTransform: "none",
        paddingBottom: 4,
        marginRight: 4,
        width: width,
        height: height ?? 27,
        maxWidth: maxWidth ?? "100%",
        "& .MuiSvgIcon-root": {
          fontSize: "2.4rem",
          color: "#006241",
          right: 1,
          height: "1em",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#006241 !important",
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
          backgroundColor: "#006241 !important",
          color: "#ffff",
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
          {
            backgroundColor: "#006241 !important",
            color: "#ffff",
          },
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component={"li"} {...props}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <Box sx={{ position: "relative" }}>
          <TextField {...params} size="small" />
          {selectedValue && (
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={handleClearButtonClick}
              sx={{
                position: "absolute",
                right: "1.8rem",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "red",
                fontSize: 14,
                "&:focus": {
                  outline: 0,
                },
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      )}
    />
  );
};

export default MediafileAutoComplete;
