import { makeStyles } from "@mui/styles";
import { COLOR_LIGHT_RED } from "../../../constants";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    minWidth: 400,
    minHeight: 20,
    paddingBottom: 2,
  },
  dialogContentContainer: {
    disply: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
    width: "85%",
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 6,
  },
  dialogButtons: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "flex-end",
  },
  dialogButton: {
    marginRight: 10,
    fontSize: 14,
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  cellFocus: {
    cursor: "pointer",
    width: "20vw",
  },
  stopContactCell: {
    cursor: "pointer",
    width: "20vw",
    minWidth: 100,
    flex: 1,
  },
  resize: {
    fontSize: 16,
  },
  datePicker: {
    padding: 4,
    width: 120,
    height: 26,
  },
  timePicker: {
    padding: 4,
    width: 90,
    height: 26,
  },
  recDatePicker: {
    width: 120,
    height: 26,
  },
  boderColor: {
    border: `1px solid ${COLOR_LIGHT_RED}`,
  },
  disabledBox: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  inputRoot: {
    marginBottom: -1.5,
    marginTop: -1,
    marginLeft: -0.5,
    width: "100%",
    "@media (min-width: 1023px)": {
      "& .MuiTextField-root": {
        width: "50ch",
      },
    },
  },
  selectTable: {
    marginRight: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
  textField: {
    fontSize: 14,
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
  },
  formControl: {
    flex: 1,
    marginTop: 1,
  },
  stopContactcheckbox: {
    "& .MuiSvgIcon-root": { fontSize: 22 },
    color: "#006241",
    "&.Mui-checked": {
      color: "#006241",
    },
  },
  expandablerow: {
    "& > *": {
      borderBottom: "unset",
      backgroundColor: (props) => (props.isHighlighted ? "#cba258" : "unset"),
    },
  },
  iconButton: {
    "&:focus": {
      outline: 0,
    },
    "&.MuiIconButton-root": {
      padding: "0",
      paddingLeft: "5px",
    },
  },
}));

export default useStyles;
