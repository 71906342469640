import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navHeading: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "15px",
    flexDirection: "row",
  },
  navTitle: {
    maxWidth: 600,
    fontSize: "1.6rem",
    paddingTop: "4px",
    "@media (max-width:499px)": {
      paddingBottom: 10,
    },
  },
  navButton: {
    flex: 1,
    fontSize: 14,
    height: 33,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    border: "1px solid lightgrey",
    height: "78.8vh",
    marginTop: "-15%",
  },
  heading: {
    flex: 1,
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

export default useStyles;
