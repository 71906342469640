import Layer from "@cxeweb/pattern-library/lib/components/layer";
import useStyles from "./styles";

const SbuxLayer = ({ isOpen,children }) => {
  const classes=useStyles();
  return <Layer isOpen={isOpen} className={classes.root}>
    {children}
  </Layer>
};

export default SbuxLayer;
