import { useState } from "react";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxCustomRow from "../../../../../components/SbuxTable/SbuxCustomRow";
import SbuxCustomTable from "../../../../../components/SbuxTable/SbuxCustomTable";
import useTextTranslate from "../../../hooks/useTextTranslate";
import { temporaryColumns } from "../temporaryMsg/columns";
import { Box } from "@mui/material";
import { getTextLengthErrorMessage } from "../../utils";

const TemporaryMsgAdd = ({ row, sequenceNumber, handleTemporaryMsgRow }) => {
  const descriptionPlaceHolder = useTextTranslate("Description");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [code, setCode] = useState(sequenceNumber);
  const [info, setInfo] = useState("");
  const [rowObj, setRowObj] = useState(row);
  const [fileNameLengthError, setFileNameLengthError] = useState(false);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    setDescriptionError(value.length < 1 ? true : false);
    const addRow = {
      ...rowObj,
      description: value,
    };
    setRowObj(addRow);
    handleTemporaryMsgRow(addRow);
  };

  const handleInfoChange = (event) => {
    const value = event.target.value;
    setInfo(value);
    const addRow = {
      ...rowObj,
      info: value,
    };
    if (value.length === 150) {
      setFileNameLengthError(true);
    } else {
      setFileNameLengthError(false);
    }
    setRowObj(addRow);
    handleTemporaryMsgRow(addRow);
  };

  return (
    <Box sx={{ height: "76vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          paddingTop: 0.5,
        }}
        noValidate
        autoComplete="off"
      >
        <SbuxCustomTable
          width="60vw"
          height="76vh"
          columns={temporaryColumns}
          isTranslationRequired
        >
          <SbuxCustomRow
            name="Code"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField variant={"outlined"} disabled value={code} />
              </Box>
            }
          />

          <SbuxCustomRow
            name="Description"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  placeholder={descriptionPlaceHolder}
                  value={description}
                  handleChange={handleDescriptionChange}
                  errorMessage={"Description required at least 1 character"}
                  isError={descriptionError}
                  error={descriptionError}
                  required
                />
              </Box>
            }
            isRequired
          />
          <SbuxCustomRow
            name="Info"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  value={info}
                  handleChange={handleInfoChange}
                  errorMessage={
                    fileNameLengthError ? getTextLengthErrorMessage(150) : null
                  }
                  isError={fileNameLengthError}
                  error={fileNameLengthError}
                  displayCounter={true}
                  maxLength={150}
                  multiline={true}
                />
              </Box>
            }
          />
        </SbuxCustomTable>
      </Box>
    </Box>
  );
};

export default TemporaryMsgAdd;
