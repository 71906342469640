import { useEffect, useCallback } from "react";
import SbuxHeading from "../../components/SbuxHeading";
import SbuxLinkListColumn from "../../components/SbuxLinkListColumn";
import SbuxLoader from "../../components/SbuxLoader";
import SbuxLayer from "../../components/SbuxLayer";
import SbuxDialog from "../../components/SbuxDialog";
import { useDispatch, useSelector } from "react-redux";
import { getUserInstance, getConnectedInstance } from "../../services/instance";
import classNames from "classnames";
import useStyles from "./styles";
import { Box } from "@mui/material";

const SbuxInstance = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    auth: { cognitoUserId },
    userInstance: { instances, showModal, status },
  } = useSelector((state) => state);

  useEffect(async () => {
    if (cognitoUserId) {
      await dispatch(getUserInstance(cognitoUserId));
    }
  }, [cognitoUserId]);

  const handleDialog = useCallback(
    async (instance) => {
      await dispatch(getConnectedInstance(instance));
    },
    [dispatch]
  );

  const createLinks = () => {
    if (!instances || instances.length === 0) {
      return [];
    }
    const sortedInstances = [...instances].sort((a, b) =>
      a.friendlyName.localeCompare(b.friendlyName)
    );
    return sortedInstances.reduce((acc, cur) => {
      acc.push({
        text: cur.friendlyName,
        onClick: () => handleDialog(cur),
        tagName: "button",
      });
      return acc;
    }, []);
  };

  return (
    <>
      {status !== "success" && status !== "rejected" ? (
        <SbuxLoader />
      ) : (
        status === "success" && (
          <SbuxLayer isOpen={showModal}>
            <SbuxDialog
              imageUrl={"/images/instance.png"}
              isrenderCloseButton={false}
            >
              <div className={classNames("mx-auto", classes.root)}>
                <Box sx={{ padding: "10px !important" }}>
                  <SbuxHeading tagName="h1" size="md">
                    {`Available Instances`}
                  </SbuxHeading>

                  <SbuxHeading
                    tagName="h6"
                    size="xs"
                    className={classes.heading}
                  >
                    {`Please make a selection`}
                  </SbuxHeading>

                  <SbuxLinkListColumn
                    linkColumnStyle={classes.linkColumn}
                    linkStyle={classNames(
                      classes.linkButton,
                      classes["&.button:focus"]
                    )}
                    links={createLinks()}
                  />
                </Box>
              </div>
            </SbuxDialog>
          </SbuxLayer>
        )
      )}
    </>
  );
};

export default SbuxInstance;
