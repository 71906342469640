import { useState, useEffect } from "react";
import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import { Divider, Box } from "@mui/material";
import useStyles from "../styles";
import SbuxCustomTable from "../../../../components/SbuxTable/SbuxCustomTable";
import { dataMgtColumns } from "../../manager/table/dataMgt/columns";
import SbuxCustomRow from "../../../../components/SbuxTable/SbuxCustomRow";
import { DataManagementCustomInput } from "./DataManagementCustomInput";

const DataManagemenetDialog = ({
  title,
  open,
  toggleDialogOpen,
  tableName,
  onSave,
  row,
  columns,
  addNew,
  rows,
  crudOperations,
}) => {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const tempFormValues = new Map();
  const [formValues, setFormValues] = useState(new Map());

  useEffect(() => {
    if (row && !addNew) {
      const initialData = Object.entries(row).map(([label, value]) => {
        const item = columns.find((column) => column.id === label);

        if (!!item) {
          tempFormValues.set(item?.label, { ...item, value: value || "" });

          return {
            label: item?.label,
            value: value || "",
            disabled: item?.disabled,
            type: item?.type,
            uiPosition: item?.uiPosition,
            required: item?.required,
            maxLength: item?.maxLength,
            lockedForEdit: item?.lockedForEdit,
            partitionKey: item?.partitionKey,
            name: item?.id,
          };
        }

        return undefined; // hideOnUi = true
      }).filter(data => !!data);
      
      setFormValues(tempFormValues);
      setRowData(initialData.sort((x, y) => x.uiPosition - y.uiPosition));
    } else {
      const emptyData = columns.map((column) => {
        const item = {
          label: typeof column === "string" ? column : column.label,
          value: "",
          type: column.type,
          uiPosition: column?.uiPosition,
          required: column?.required,
          maxLength: column?.maxLength,
          lockedForEdit: false,
          partitionKey: column?.partitionKey,
          name: column?.id,
        };
        tempFormValues.set(item.label, item);
        return item;
      });
      setFormValues(tempFormValues);
      setRowData(emptyData);
    }
  }, [row, columns]);

  const handleOkayClick = async () => {
    const listDataPayload = {
      payload: {
        attributes: rowData.map((data) => {
          const item = columns.find((column) => column.label === data.label);
          const modifiedValue = formValues.get(item.label);
          return {
            name: item.id,
            value: modifiedValue?.value,
          };
        }),
      },
      tableName: tableName,
      isEdit: !addNew,
    };
    onSave(listDataPayload);
  };

  const handleInputChange = (data, value, formValues) => {
    setFormValues(formValues);

    let isError = false;
    for (const [key, eachControl] of formValues?.entries()) {
      if (eachControl?.isError) {
        isError = true;
      } else if (eachControl.required && eachControl.value?.length === 0) {
        isError = true;
      }
    }
    setIsSaveDisabled(isError);
  };

  const isFormDisable = !crudOperations?.includes("UPDATE") && !addNew;

  return (
    <>
      <SbuxLayer isOpen={open}>
        <SbuxDialog
          toggleDialogOpen={toggleDialogOpen}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              {title}
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <Box className={classes.dialogContentContainer}>
                <SbuxCustomTable
                  width="100%"
                  height="65vh"
                  columns={dataMgtColumns}
                  isTranslationRequired
                  tableLayout="fixed"
                >
                  {rowData.map((data, idx) => {
                    const isDisabled = !!data?.lockedForEdit || isFormDisable;
                    return (
                      <SbuxCustomRow
                        key={idx}
                        name={data.label}
                        value={
                          <DataManagementCustomInput
                            rows={rows}
                            formValues={formValues}
                            data={data}
                            idx={idx}
                            label={data?.label}
                            value={rowData[idx]?.value}
                            handleInputChange={handleInputChange}
                            disabled={isDisabled}
                            type={data?.type}
                            required={data?.required}
                            maxLength={data?.maxLength}
                            isError={data?.isError}
                            errorMessage={data?.errorMessage}
                          />
                        }
                        isRequired={data?.required}
                        maxLength={data?.maxLength}
                      />
                    );
                  })}
                </SbuxCustomTable>
              </Box>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton
                type="save"
                onClick={handleOkayClick}
                disabled={isSaveDisabled}
              >
                <SbuxTranslate>{`Save`}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="cancel" onClick={toggleDialogOpen}>
                <SbuxTranslate>{`Cancel`}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default DataManagemenetDialog;
