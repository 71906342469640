import { makeStyles } from "@mui/styles";
import { COLOR_GREEN_ACCENT, COLOR_NEUTURAL_WARM } from "../../constants";
const useStyles = makeStyles((theme) => ({
  saveButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 6,
    fontSize: 13,
    height: 28,
    width: 80,
    outline: "0 !important",
    backgroundColor: COLOR_GREEN_ACCENT,
    color: COLOR_NEUTURAL_WARM,
    "&:hover": {
      backgroundColor: `${COLOR_GREEN_ACCENT} !important`,
      color: COLOR_NEUTURAL_WARM,
    },
  },
  cancelButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 6,
    fontSize: 13,
    height: 28,
    width: 80,
    outline: "0 !important",
  },
}));
export default useStyles;
