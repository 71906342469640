import HeaderCrate from "./HeaderCreate";
import ContentCrate from "./ContentCrate";
import Navbar from "./Navbar";
import Footer from "../components/SbuxFooter";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "SoDoSans, Helvetica Neue, Helvetica, Arial, sans-serif",
    },
  },
});

const CrateLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Navbar />
        <HeaderCrate />
        <ContentCrate />
        {children}
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
        }}
      >
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default CrateLayout;
