import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const SbuxTextField = ({
  value,
  name = null,
  variant = null,
  label = null,
  disabled = null,
  placeholder = null,
  isError = null,
  errorMessage = null,
  required = null,
  handleChange,
  displayCounter = false,
  maxLength,
  multiline = false,
  rows = 4,
}) => {
  // let maxLength = multiline ? 2000 : 55;
  return (
    <Box
      sx={{
        "& .MuiFormHelperText-root": {
          marginLeft: 0,
          marginRight: 0,
        },
      }}
    >
      <Box
        sx={{
          paddingLeft: 0.5,
          marginBottom: -0.6,
          fontSize: 13,
          fontWeight: 600,
          color: "#006241",
        }}
      >
        {label}
      </Box>
      <TextField
        variant={variant}
        name={name}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        inputProps={maxLength}
        multiline={multiline}
        rows={multiline ? rows : 1}
        rowsMax={value ? 10 : 1}
        helperText={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            {isError ? (
              <span style={{ fontSize: 14, flex: 1 }}>{`${errorMessage}`}</span>
            ) : (
              ""
            )}
            {displayCounter && (
              <span
                style={
                  multiline
                    ? { margin: "0 1px 0 auto", fontSize: 12 }
                    : { marginLeft: "auto", fontSize: 14 }
                }
              >{`${value.length}/${maxLength}`}</span>
            )}
          </Box>
        }
        error={isError}
        required={required ? required : null}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiInput-root": {
            fontSize: 14,
            height: 51,
            marginRight: 2,
            width: "100%",
          },
          "& .MuiInput-underline": {
            border: variant === "outlined" && 1,
            borderBottom: variant === "standard" && 1.5,
            borderColor: "#006241 !important",
          },
          "& .MuiInput-underline:focus-within": {
            borderColor: "#006241",
          },
          "& .MuiInput-root:before": {
            borderBottom: "none",
          },
          "& .MuiInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "& .MuiInputBase-input": {
            fontSize: 16,
            padding: 0,
            marginBottom: -0.7,
            height: multiline ? "85px !important" : "auto",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000000c7",
          },
          "& .MuiInput-underline:after": {
            borderBottom: 0,
          },
          "& .MuiOutlinedInput-root": {
            height: multiline ? "auto" : 50,
            minHeight: multiline ? 50 : "auto",
            padding: multiline ? "0.5rem" : "0 0 0.5rem 0.5rem",
            "&.Mui-focused fieldset": {
              borderColor: isError ? "red" : "#006241",
            },
          },
          "& .MuiFormLabel-root": {
            fontSize: 12,
            "&.Mui-focused": {
              color: "#006241",
              fontSize: 12,
              fontWeight: 600,
            },
          },
        }}
      />
    </Box>
  );
};

export default SbuxTextField;
