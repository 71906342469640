import SbuxEnhancedTable from "../../../../components/SbuxTable/SbuxEnhancedTable";
import ResourcesRow from "./ResourcesRow";
import { predicateBy } from "../../utils";
import { RESOURCES_SORT_FIELD_UIPOSITION } from "../../../../constants";

const ResourcesTable = ({ resourcesData, handleRefrehClick }) => {
  const resourcesDataSorted = [...resourcesData].sort(
    predicateBy(RESOURCES_SORT_FIELD_UIPOSITION)
  );

  return (
    <SbuxEnhancedTable
      rows={resourcesDataSorted}
      columns={null}
      groupColumns={null}
      defaultSortOrder={"asc"}
      RowSource={ResourcesRow}
      showSearchBar={false}
      showFooter={false}
      defaultRowsPerPage={0}
      handleRefreshClick={handleRefrehClick}
    />
  );
};

export default ResourcesTable;
