import SbuxCard from "../../../../../components/SbuxCard";
import { useEffect } from "react";
import GenesysRecordingsDataGrid from "./components/GenesysRecordingsDataGrid";
import { useSelector, useDispatch } from "react-redux";
import {
  getGenesysRecordingsAudio,
  getGenesysRecordingsVideo,
  getGenesysRecordings,
} from "../../../../../services/genesysRecordings";
import { getDataGridColumns } from "./columns";
import { filterList } from "./filters";

const GenesysRecordingsTabs = () => {
  const dispatch = useDispatch();
  const selectGenesysRecordings = (state) => state.genesysRecordings;
  const { videoMediaContent, audioMediaContent } = useSelector((state) =>
    selectGenesysRecordings(state)
  );

  const {
    userInstance: {
      connectedInstance: {
        appConfig: { genesysConfig },
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!!genesysConfig?.callType)
      dispatch(
        getGenesysRecordings({
          limit: 25,
          callType: genesysConfig?.callType,
        })
      );
  }, []);

  useEffect(() => {
    if (videoMediaContent) {
      window.open(videoMediaContent.mediaContent, "_blank");
    }
  }, [videoMediaContent]);

  useEffect(() => {
    if (audioMediaContent) {
      window.open(audioMediaContent.mediaContent, "_blank");
    }
  }, [audioMediaContent]);

  const handleAudioDowload = async (value) => {
    await dispatch(getGenesysRecordingsAudio(value));
  };

  const handleVideoDowload = async (value) => {
    await dispatch(getGenesysRecordingsVideo(value));
  };

  return (
    <SbuxCard className="insideTabContainer">
      <GenesysRecordingsDataGrid
        columns={getDataGridColumns(handleAudioDowload, handleVideoDowload)}
        filterList={filterList}
      />
    </SbuxCard>
  );
};

export default GenesysRecordingsTabs;
