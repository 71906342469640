import React from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { COLOR_DARK_RED } from "../../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../../styles";

const DataManagementRow = ({
  row,
  handleEditRowClick,
  handleDeleteRowClick,
  columns,
}) => {
  const classes = useStyles();

  const {
    dataMgt: { metaConfigData, tableListData },
  } = useSelector((state) => state);

  const handleEditClick = () => {
    handleEditRowClick(row);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleDeleteRowClick(row);
  };

  const getTypeValue = (type) => {
    let val = type;
    if (type === "true" || type === true) {
      val = "true";
    } else if (type === "false" || type === false) {
      val = "false";
    }
    return val;
  };

  const renderValue = (row, cell) => {
    if (cell?.id !== "action") {
      if (cell?.type === "boolean") {
        return getTypeValue(row[cell?.id]);
      }
      return row[cell?.id];
    }
    return (
      <IconButton
        onClick={handleDeleteClick}
        color="primary"
        className={classes.iconButton}
      >
        <DeleteOutline style={{ color: COLOR_DARK_RED }} />
      </IconButton>
    );
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {columns?.map((cell, idx) => (
          <SbuxStyledTableCell
            key={cell.id}
            className={classes.cellFocus}
            onClick={handleEditClick}
            sx={{
              ...(cell?.id === "action" && {
                width: "100px",
                maxWidth: "100px",
              }),
            }}
          >
            {renderValue(row, cell)}
          </SbuxStyledTableCell>
        ))}
      </SbuxStyledTableRow>
    </>
  );
};

export default DataManagementRow;
