import SbuxStyledTableCell from "./SbuxStyledTableCell";
import SbuxStyledTableRow from "./SbuxStyledTableRow";
import Box from "@mui/material/Box";

const SbuxCustomRow = ({ name, value, isRequired, disabled }) => {
  return (
    <SbuxStyledTableRow
      sx={{
        "& > *": { borderBottom: "unset" },
        opacity: disabled ? 0.65 : 1,
        pointerEvents: disabled ? "none" : "initial",
      }}
    >
      {name && (
        <SbuxStyledTableCell sx={{ cursor: "pointer", minWidth: 180 }}>
          <Box>{name}</Box>
        </SbuxStyledTableCell>
      )}

      {value && (
        <SbuxStyledTableCell sx={{ display: "flex" }}>
          <Box>{value}</Box>
          <>
            {isRequired && (
              <span style={{ color: "red", fontSize: 22, marginTop: -5 }}>
                *
              </span>
            )}
          </>
        </SbuxStyledTableCell>
      )}
    </SbuxStyledTableRow>
  );
};

export default SbuxCustomRow;
