import { useState } from "react";
import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Divider,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import useStyles from "../styles";

const ClosureMsgEditDialog = ({
  title,
  open,
  handleRadioOkClick,
  toggleDialogOpen,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("singleInstance");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleOkayClick = () => {
    handleRadioOkClick(value);
  };

  return (
    <>
      <SbuxLayer isOpen={open}>
        <SbuxDialog
          toggleDialogOpen={toggleDialogOpen}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              {title}
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <Box
                sx={{ display: "flex", width: 300, flexDirection: "column" }}
              >
                <Box sx={{ flex: 1 }}>
                  <SbuxTranslate>{`This is a single instance in a repeating series. What do you want to open? `}</SbuxTranslate>
                </Box>
                <FormControl sx={{ flex: 1, marginTop: 1 }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="singleInstance"
                      control={<Radio />}
                      label={<Box sx={{ fontSize: 14 }}>{"Just this one"}</Box>}
                    />
                    <FormControlLabel
                      value="entireSeries"
                      control={<Radio />}
                      label={
                        <Box sx={{ fontSize: 14 }}>{"The entire series"}</Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton type="save" onClick={handleOkayClick}>
                <SbuxTranslate>{`Okay`}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="cancel" onClick={toggleDialogOpen}>
                <SbuxTranslate>{`Cancel`}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default ClosureMsgEditDialog;
