import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Auth, Amplify } from "aws-amplify";
import { clientLog } from "./logging";

const oauth = {
  domain: process.env.REACT_APP_COGNITO_APPWEBDOMAIN,
  scope: ["email", "openid", "aws.cognito.signin.user.admin"],
  redirectSignIn: process.env.REACT_APP_AGENTDESKTOP_REDIRECTSIGNIN_URL,
  redirectSignOut: process.env.REACT_APP_AGENTDESKTOP_REDIRECTSIGNOUT_URL,
  responseType: "code",
  options: {
    AdvancedSecurityDataCollectionFlag: true,
  },
};

// Amplify.Logger.LOG_LEVEL = "DEBUG";
// window.LOG_LEVEL = "DEBUG";

export const apiConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    mandatorySignIn: true,
    authenticationFlowType: "USER_SRP_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "MyAPIGatewayAPI",
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      },
      {
        name: "ResourceManagerAPI",
        endpoint: process.env.RESOURCE_MANAGER_BACKEND_API_ENDPOINT,
      },
      {
        name: "ResourceManagerAPI",
        endpoint: process.env.REACT_APP_RESOURCE_MANAGER_BACKEND_API_ENDPOINT,
      },
    ],
  },
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_BACKEND_API_APPSYNC_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_BACKEND_API_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_BACKEND_API_APPSYNC_DEFAULTAUTHMODE,
  oauth,
};

Amplify.configure(apiConfig);

export const signIn = createAsyncThunk(
  "auth",
  async (status, rejectWithValue) => {
    try {
      if (status === "rejected") {
        return process.env.REACT_APP_COGNITO_DISPLAY_FEDERATION_SELECTOR ===
          "true"
          ? await Auth.federatedSignIn()
          : await Auth.federatedSignIn({
              provider: process.env.REACT_APP_COGNITO_FEDERATION_PROVIDER,
            });
      }
      return await Auth.currentAuthenticatedUser();
    } catch (err) {
      return rejectWithValue(`Something went wrong in accessing auth api`);
    }
  }
);

export const logOutOfCcp = async (instanceUrl, component, userEmail) => {
  await clientLog({
    component,
    message: `Logging out user ${userEmail}`,
  });
  await fetch(
    `${instanceUrl}/${process.env.REACT_APP_CONNECT_CCP_LOGOUT_PATH}`,
    {
      credentials: "include",
      mode: "no-cors",
    }
  );
  const eventBus = connect.core.getEventBus();
  eventBus.trigger(connect.EventType.TERMINATE);
};

export const signOut = createAsyncThunk(
  "auth",
  async (params, rejectWithValue) => {
    try {
      const { instanceUrl, component, userEmail } = params;
      await logOutOfCcp(instanceUrl, component, userEmail);
      return await Auth.signOut({ global: true });
    } catch (err) {
      return rejectWithValue(`Something went wrong in accessing auth api`);
    }
  }
);

export const getResetTimers = createAction("auth/resetTimers");
