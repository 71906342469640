import { useState, useEffect } from "react";
import SbuxButton from "../../../../../components/SbuxButton";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import { Box } from "@mui/material";
import MainHoursConfigTableRow from "../mainHours/MainHoursConfigTableRow";
import { createOrUpdateMainHours } from "../../../../../services/mainHours";
import { useDispatch } from "react-redux";
import { detailColumns } from "./columns";
import useStyles from "../../styles";
import { isEmptyString } from "../../../../../utils/stringUtil";
import { Height } from "@mui/icons-material";

const MainHoursConfigTable = ({ rowData, toggleDialogOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hoursConfig, setHoursConfig] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    const result = handleRowDataUpdate(rowData.hoursConfig);
    setHoursConfig(result);
  }, []);

  const handleRowDataUpdate = (hoursConfig) => {
    return hoursConfig && hoursConfig.length > 0
      ? hoursConfig.reduce((acc, cur, index) => {
          acc.push({
            id: index,
            ...cur,
          });
          return acc;
        }, [])
      : [];
  };

  const handleButtonClick = () => {
    let rows = [...hoursConfig];
    let rowCount = rows && rows.length > 0 ? rows.length : 0;
    const initializeHoursConfig = {
      id: rowCount,
      day: "sunday",
      start: "09:00",
      end: "17:00",
      description: "",
      isOpenFor24Hours: false,
    };
    rows.push(initializeHoursConfig);
    setHoursConfig([...rows]);
  };

  const handleDeleteRowClick = (row) => {
    let rows = [...hoursConfig];
    let filteredRows = rows.filter((f) => f.id !== row.id);
    setHoursConfig(filteredRows);
  };

  const handle24HoursClick = (row) => {
    let rows = [...hoursConfig];
    rows = rows?.reduce((acc, cur) => {
      if (cur.id === row.id) {
        acc.push({
          ...cur,
          start: cur.isOpenFor24Hours ? "00:00" : "09:00",
          end: cur.isOpenFor24Hours ? "00:00" : "17:00",
        });
      } else acc.push(cur);
      return acc;
    }, []);
    setHoursConfig([...rows]);
  };
  const handleSave = async () => {

    // Validation.
    // TODO: We should leverage html5 form validation states
    if (isEmptyString(rowData.hoursName)) {
      return;
    }

    let hoursConfigWithoutIds = [...hoursConfig];
    hoursConfigWithoutIds = hoursConfigWithoutIds.reduce((acc, cur) => {
      acc.push({
        start: cur.start,
        end: cur.end,
        description: cur.description,
        day: cur.day,
        isOpenFor24Hours: cur.isOpenFor24Hours,
      });
      return acc;
    }, []);
    const data = {
      ...rowData,
      hoursConfig: [...hoursConfigWithoutIds],
    };
    toggleDialogOpen();
    await dispatch(createOrUpdateMainHours(data));
    setOpenSnackBar(true);
  };

  const handleClose = () => {
    toggleDialogOpen();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{ flex: 1, position: "relative" }}>
        <SbuxEnhancedTable
          rows={hoursConfig}
          columns={detailColumns}
          defaultSortingField={""}
          defaultSortOrder={"asc"}
          RowSource={MainHoursConfigTableRow}
          showFooter={false}
          showSearchBar
          showAddNewButton
          addNewButtonTitle={`Add new`}
          handleAddNewButtonClick={handleButtonClick}
          showModalTable
          isManager
          isTranslationRequired={false}
          handleDeleteRowClick={handleDeleteRowClick}
          handle24HoursClick={handle24HoursClick}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          bottom: "0px",
          position: "absolute",
          justifyContent: "center",
          width: "96%",
          height: 40,
        }}
      >
        <SbuxButton
          type="save"
          height={28}
          disabled={isEmptyString(rowData.hoursName)}
          onClick={handleSave}
        >
          Save
        </SbuxButton>

        <SbuxButton type="cancel" height={28} onClick={handleClose}>
          Close
        </SbuxButton>
      </Box>
    </Box>
  );
};

export default MainHoursConfigTable;
