import { useCallback } from "react";
import SbuxStyledTableRow from "../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxContentCopy from "../../../../components/SbuxContentCopy";
import {
  isPhoneNumber,
  phoneNumberDetail,
} from "../../../../utils/phoneNumber";
import {
  dialByPhoneNumber,
  transferToEndpoint,
} from "../../../content/common/agentCall";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";

const QuickConnectsRow = ({ row }) => {
  const {
    ccp: { ccpValidationButtonStatus },
  } = useSelector((state) => state);

  const handleDialPhoneNumber = useCallback((phoneNumber) => {
    dialByPhoneNumber(phoneNumber, null);
  }, []);

  const handleTransfer = useCallback(
    (endpoint) => transferToEndpoint(endpoint),
    []
  );

  const linkRenderer = () => {
    return (
      <div>
        <Link
          component="button"
          disabled={ccpValidationButtonStatus}
          onClick={() =>
            row.type === "phone_number"
              ? handleDialPhoneNumber(row.phoneNumber)
              : handleTransfer(row)
          }
        >
          {row.phoneNumber ? row.phoneNumber : "Click-to-Dial"}
        </Link>
        {isPhoneNumber(row.phoneNumber) && (
          <SbuxContentCopy
            content={phoneNumberDetail(row.phoneNumber)?.phoneNumber ?? ""}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell>{row.name}</SbuxStyledTableCell>
        <SbuxStyledTableCell>{linkRenderer()}</SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default QuickConnectsRow;
