import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  transcriptWrapperDiv: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
  transcriptHeaderDiv: {
    display: "block",
    marginRight: "15px",
    marginTop: "5px",
    padding: "5px",
  },
  flexHeader: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  participantHeaderText: {
    fontSize: "12px",
  },
  durationText: {
    fontSize: "12px",
    color: "rgb(115, 115, 115)",
    marginLeft: "10px",
  },
  transcriptContentDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
  },
  transcriptDiv: {
    borderRadius: "0.375rem",
    color: "rgb(17, 17, 17)",
    fontSize: "12px",
    letterSspacing: "0px",
    lineHeight: "1.375rem",
    padding: "0.75rem 1.125rem",
    width: "100%",
    background: "rgb(239, 239, 239)",
  },
}));
export default useStyles;
