import { useState, useEffect } from "react";
import SbuxSelect from "../../../../../../components/SbuxSelect";
import SbuxCustomRow from "../../../../../../components/SbuxTable/SbuxCustomRow";
import ClosureMsgAutoCompleteTime from "./ClosureMsgAutoCompleteTime";
import { getTime } from "../../../../../../utils/timeUtil";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import useStyles from "../../../styles";
import { DATE_TIME_BASED_ON_TIMEZONE } from "../../../../../../constants";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";
import { DATE_VALIDATION_ERROR_MSG } from "../../../../../../constants";
import moment from "moment-timezone";

const ClosureMsgTimePicker = ({
  name,
  startDate,
  endDate,
  startTimeValue,
  endTimeValue,
  timeZoneValue,
  timeZoneList,
  handleDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleTimeZoneChange,
  disabled = false,
  row = null,
}) => {
  const [sDate, setSDate] = useState(startDate);
  const [eDate, setEDate] = useState(endDate);
  const classes = useStyles();
  useEffect(() => {
    setSDate(startDate);
    setEDate(endDate);
  }, [startDate, endDate]);

  return (
    <SbuxCustomRow
      name={name}
      value={
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1, marginTop: 0.1, marginRight: 1 }}>
            <DatePicker
              required
              wrapperClassName={
                name === "Start Time" && sDate === null
                  ? classes.boderColor
                  : name === "End Time" && eDate === null
                  ? classes.boderColor
                  : name === "End Time" && eDate < sDate
                  ? classes.boderColor
                  : ""
              }
              className={classes.datePicker}
              selected={name === "Start Time" ? startDate : endDate}
              dateValue={name === "Start Time" ? startDate : endDate}
              minDate={moment().toDate()}
              onChange={(date) => handleDateChange({ date, name })}
              timeFormat="YYYY-MM-DD"
              disabled={row && row.status === "Expired"}
            />
          </Box>
          <Box sx={{ flex: 1, marginTop: 0, marginRight: 1 }}>
            {((name === "Start Time" && sDate === null) ||
              (name === "End Time" && eDate === null) ||
              (name === "End Time" && moment(eDate) < moment(sDate))) && (
              <Tooltip
                title={
                  <Box
                    sx={{
                      backgroundColor: "#006241",
                      color: "white",
                      fontSize: 14,
                    }}
                  >
                    {DATE_VALIDATION_ERROR_MSG}
                  </Box>
                }
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{
                    cursor: "pointer",
                    color: "red",
                    fontSize: 16,
                    width: "1vw",
                    marginTop: 0.5,
                    height: 18,
                    "&:focus": {
                      outline: 0,
                    },
                    "&.MuiIconButton-root": {
                      padding: 0,
                      paddingLeft: "5px",
                    },
                  }}
                >
                  <ErrorOutlineOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {name === "Start Time" ? (
            <>
              <Box sx={{ flex: 1, marginTop: 0.1, marginRight: 1 }}>
                <ClosureMsgAutoCompleteTime
                  startTimeOptions={getTime(null)}
                  name={name}
                  startDate={startDate}
                  endDate={endDate}
                  startTimeValue={startTimeValue}
                  endTimeValue={endTimeValue}
                  handleStartTimeChange={(time) =>
                    handleStartTimeChange({ time, name })
                  }
                  timeZone={timeZoneValue}
                  disabled={row && row.status === "Expired"}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  opacity: disabled ? 0.65 : 1,
                  pointerEvents: disabled ? "none" : "initial",
                }}
              >
                <SbuxSelect
                  width={"16vw"}
                  menuItems={timeZoneList}
                  defaultValue={timeZoneValue}
                  value={timeZoneValue}
                  handleSelectChange={(timeZone) =>
                    handleTimeZoneChange(timeZone)
                  }
                  disabled={row && row.status === "Expired"}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ flex: 1, marginTop: 0.1, marginRight: 1 }}>
                <ClosureMsgAutoCompleteTime
                  endTimeOptions={getTime(endTimeValue)}
                  name={name}
                  startDate={startDate}
                  endDate={endDate}
                  startTimeValue={startTimeValue}
                  endTimeValue={endTimeValue}
                  timeZone={timeZoneValue}
                  handleEndTimeChange={(time) =>
                    handleEndTimeChange({ time, name })
                  }
                  disabled={row && row.status === "Expired"}
                />
              </Box>
              <Box
                sx={{
                  color: "#006241",
                  fontSize: 12,
                  fontWeight: 600,
                  width: "18vw",
                }}
              >
                {`${DATE_TIME_BASED_ON_TIMEZONE} ${moment
                  .tz(moment(), timeZoneValue)
                  .format("MM/DD/YYYY hh:mm a")}`}
              </Box>
            </>
          )}
        </Box>
      }
    />
  );
};

export default ClosureMsgTimePicker;
