import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const SbuxTranslate = ({ children }) => {
  const {
    settings: { translatedText, translatedStatus },
  } = useSelector((state) => state);

  const [translatedData, setTranslatedData] = useState([]);
  useEffect(() => {
    translatedStatus === "success" && setTranslatedData(translatedText);
  }, [translatedStatus]);
  const translate = (text) => {
    const textValue = Array.isArray(text) ? text[1] : text;
    if (textValue && translatedData.length > 0) {
      const result =
        translatedData.length > 0 &&
        translatedData.find((f) => f.sourceText === textValue);
      return result && result.hasOwnProperty("translatedText")
        ? result.translatedText
        : textValue;
    } else {
      return textValue;
    }
  };
  return translate(children);
};

export default SbuxTranslate;
