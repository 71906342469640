import { Logger } from "aws-amplify";

export const logMessage = (screenName, message, type) => {
  const logger = new Logger(screenName);
  switch (type) {
    case "error": {
      logger.error(`${message}`);
      break;
    }
    case "info": {
      logger.info(`${message}`);
      break;
    }
    case "warn": {
      logger.warn(`${message}`);
      break;
    }
    case "debug": {
      logger.debug(`${message}`);
      break;
    }
    default: {
      logger.info(`${message}`);
      break;
    }
  }
};
