import SbuxCard from "../../../components/SbuxCard";
import Box from "@mui/material/Box";
import ResourcesTable from "./table/ResourcesTable";
import SbuxLoadingIndicator from "../../../components/SbuxLoadingIndicator";
import { getResources } from "../../../services/resources";
import { useSelector, useDispatch } from "react-redux";
import SbuxFallback from "../../../components/SbuxFallback";
import { ErrorBoundary } from "react-error-boundary";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";

const Resources = () => {
  const dispatch = useDispatch();
  const {
    resources: { resourcesData, resourceStatus },
  } = useSelector((state) => state);

  const handleError = (error) => {
    clientLog({
      component: `Resources`,
      message: `Something went wrong in Resources Component => ${error}`,
    });
    logMessage(
      `Unhandle Error in Resources`,
      `Something went wrong in Resources Component => ${error}`,
      `error`
    );
  };

  const handleRefrehClick = async () => {
    await dispatch(getResources());
  };

  return (
    <ErrorBoundary
      FallbackComponent={SbuxFallback}
      onError={handleError}
      onReset={handleRefrehClick}
    >
      <SbuxCard className="insideTabContainer">
        <Box
          sx={{
            p: 0,
            pb: "5px",
            fontSize: 22,
            display: "flex",
            flexWrap: "wrap",
          }}
        />
        {resourceStatus === "rejected" ? (
          <SbuxFallback />
        ) : (
          <ResourcesTable
            resourcesData={resourcesData}
            handleRefrehClick={handleRefrehClick}
          />
        )}
      </SbuxCard>
    </ErrorBoundary>
  );
};

export default Resources;
