import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import SbuxLoader from "../../../../../components/SbuxLoader";
import { getQueue, getResetQueue } from "../../../../../services/queue";
import { columns } from "./columns";
import QueueRow from "./QueueRow";
import { Paper } from "@mui/material";

const QueueTabs = ({}) => {
  const dispatch = useDispatch();

  const { queueLoading, queueData, queueDeleteStatus, queueDeleteError } =
    useSelector((state) => state.queue);
  const { connectedInstance } = useSelector((state) => state.userInstance);

  useEffect(() => {
    fetchData(connectedInstance.instanceId); // async

    return () => {
      dispatch(getResetQueue());
    };
  }, [connectedInstance.instanceId]);

  /**
   * Fetch queue data for the current instance Id
   * @param  {string}  instanceId      instance Id
   * @return {Promise}
   */
  const fetchData = async (instanceId) => {
    await dispatch(getQueue(instanceId));
  };

  /**
   * Handle table refresh event
   * @return {Promise}
   */
  const handleRefrehClick = async () => {
    await fetchData(connectedInstance.instanceId);
  };

  return (
    <>
      {queueLoading ? (
        <SbuxLoader />
      ) : (
        <Paper>
          <SbuxEnhancedTable
            rows={queueData}
            columns={columns}
            defaultSortingField={"Name"}
            defaultSortOrder={"asc"}
            RowSource={QueueRow}
            showFooter={true}
            showSearchBar={true}
            isManager={true}
            handleRefrehClick={handleRefrehClick}
          />
        </Paper>
      )}
    </>
  );
};

export default QueueTabs;
