import React from "react";
import { StyledGridOverlay } from "./styled";
import { CURRENT_TAB_NO_ROWS_OVERLAY } from "../../constants";
import SbuxTranslate from "../SbuxTranslate";

export const CustomNoRowsOverlay = React.memo(() => {
  return (
    <StyledGridOverlay>
      <img width="120" height="100" src={"/images/instance.png"} />
      <div style={{ Top: 10, fontSize: 20, fontWeight: 600 }}>
        <SbuxTranslate>
          {"Information will populate on an active contact"}
        </SbuxTranslate>
      </div>
    </StyledGridOverlay>
  );
});
