import { useState, useEffect } from "react";
import SbuxButtonGroup from "../../../../../components/SbuxButtonGroup";
import { Box } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxMediaRecorder from "../../../../../components/SbuxMediaRecorder";
import MediaMgtFileUpload from "./MediaMgtFileUpload";
import { useSelector } from "react-redux";
import useStyles from "../../styles";
import { MEDIAMGT_FILE_NAME_ERROR } from "../../../../../constants";
import MediaTextToSpeech from "./MediaTextToSpeech";
import { getTextLengthErrorMessage } from "../../utils";

const MediaMgtEdit = ({
  row,
  mediaContent,
  handleMediaMgtRow,
  handleMediaInfo,
  buttonList,
  handleDisableSaveBtn,
}) => {
  const {
    mediaMgt: {
      mediaMgtData: { data: mediaMgtRows },
    },
  } = useSelector((state) => state);
  const [rowObj, setRowObj] = useState(null);
  const [fileName, setFileName] = useState(row.promptName);
  const [promptName, setPromptName] = useState(row.promptName);
  const [description, setDescription] = useState(row.description);
  const [toggleButton, setToggleButton] = useState(1);
  const [fileNameError, setFileNameError] = useState(false);
  const [searchRowData, setSearchRowData] = useState([]);
  const [fileNameLengthError, setFileNameLengthError] = useState(false);
  const [descriptionLengthError, setDescriptionLengthError] = useState(false);

  useEffect(() => {
    setSearchRowData(mediaMgtRows);
    setRowObj(row);
  }, [mediaMgtRows]);

  const handleToggle = (newValue) => {
    setToggleButton(newValue);
  };

  const handleFileNameChange = (event) => {
    const value = event.target.value;
    setFileName(value);
    row.fileName = value;
    handleMediaMgtRow(row);
  };

  const handlePromptChange = (event) => {
    const value = event.target.value;
    if (
      rowObj.promptName.toLowerCase() !== value.toLowerCase() &&
      searchRowData.some(
        (row) => row.promptName.toLowerCase() === value.toLowerCase()
      )
    ) {
      setFileNameError(true);
      row.promptName = "";
    } else {
      setFileNameError(false);
      row.promptName = value;
    }

    let isError = false;
    if (value.length > 50) {
      isError = true;
    }
    setFileNameLengthError(isError);

    setPromptName(value);
    handleMediaMgtRow(row, isError || descriptionLengthError);
  };

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    let isError = false;
    if (value.length > 150) {
      isError = true;
    }
    setDescriptionLengthError(isError);
    setDescription(value);
    row.description = value;
    handleMediaMgtRow(row, isError || fileNameLengthError);
  };

  const getErrorMessage = () => {
    if (fileNameError) {
      return MEDIAMGT_FILE_NAME_ERROR;
    }
    if (fileNameLengthError) {
      return getTextLengthErrorMessage(50);
    }
  };

  return (
    <Box sx={{ minHeight: "70vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 0,
          "& .MuiTextField-root": {
            width: "70ch",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ marginLeft: 0.5 }}>
          <Box
            sx={{
              fontSize: 13,
              color: "#006241",
              fontWeight: 600,
              paddingBottom: 0.5,
            }}
          />
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            disabled
            label={<SbuxTranslate>{`Language`}</SbuxTranslate>}
            value={row.language}
          />
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            disabled
            label={<SbuxTranslate>{`Current File`}</SbuxTranslate>}
            value={fileName}
            handleChange={handleFileNameChange}
          />
          <audio
            style={{
              height: 26,
              width: 375,
              marginLeft: 5,
              marginBottom: 2,
            }}
            controls
            autobuffer
            title={row.fileName}
          >
            <source src={mediaContent} type="audio/wav" />
          </audio>
        </Box>
        <Box sx={{ marginLeft: 0.5, minHeight: 210, marginTop: 1 }}>
          <Box
            sx={{
              fontSize: 13,
              color: "#006241",
              fontWeight: 600,
              paddingBottom: 0.5,
            }}
          >
            <SbuxTranslate>{`Upload or record a file`}</SbuxTranslate>
            <Box
              sx={{
                marginTop: 0.5,
                width: "100%",
                height: 300,
                border: "1px solid lightgrey",
                borderRadius: 2,
              }}
            >
              <SbuxButtonGroup
                buttonList={buttonList}
                value={toggleButton}
                handleButtonToggle={handleToggle}
                height={26}
              />
              {toggleButton === 1 && (
                <MediaMgtFileUpload
                  row={row}
                  handleMediaInfo={handleMediaInfo}
                />
              )}
              {toggleButton === 2 && (
                <SbuxMediaRecorder
                  row={row}
                  handleMediaInfo={handleMediaInfo}
                />
              )}
              {toggleButton === 3 && (
                <MediaTextToSpeech
                  selectedLanguageCode={row.languageCode}
                  handleMediaInfo={handleMediaInfo}
                  handleDisableSaveBtn={handleDisableSaveBtn}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            label={<SbuxTranslate>{`File Name`}</SbuxTranslate>}
            value={promptName}
            errorMessage={getErrorMessage()}
            maxLength="50"
            isError={fileNameError || fileNameLengthError}
            error={fileNameError || fileNameLengthError}
            displayCounter={true}
            handleChange={handlePromptChange}
          />
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            label={<SbuxTranslate>{`Description (Optional)`}</SbuxTranslate>}
            value={description}
            handleChange={handleDescriptionChange}
            errorMessage={getTextLengthErrorMessage(150)}
            isError={descriptionLengthError}
            error={descriptionLengthError}
            maxLength="150"
            displayCounter={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MediaMgtEdit;
