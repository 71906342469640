import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.sb-footer-iconMarginAdjust": {
      "margin-top": "-20px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: -2.5,
  },
  header: {
    display: "flex",
    fontSize: "1.5rem",
    fontWeight: 400,
    marginBottom: 0.5,
  },
  link: {
    minWidth: 100,
    color: "rgba(0, 0, 0, 0.87)",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  separator: {
    minWidth: 100,
    textAlign: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: -2.5,
  },
  copyright: {
    fontSize: "11px",
  },
  versionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: "11px",
    marginTop: -2.5,
  },
}));

export default useStyles;
