import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import SbuxSelect from "../../../../../../components/SbuxSelect";
import { Box, FormControlLabel, Radio } from "@mui/material";

const ClosureMsgMonthlyFrequency = ({
  monthlyOption,
  monthlyRecDayOfList,
  monthlyRecDayOfValue,
  monthlyRecWeekDaysList,
  monthlyRecWeekDays,
  monthlyRecOption1EveryDayInputValue,
  monthlyRecOption1EveryMonthsInputValue,
  monthlyRecOption2EveryMonthsInputValue,
  handleMonthlyOptionsValueChange,
  handleMonthlyRecDayOfValueChange,
  handleMonthlyRecWeekDaysChange,
  handleMonthlyRecOption1EveryDayInputValueChange,
  handleMonthlyRecOption1EveryMonthsInputValueChange,
  handleMonthlyRecOption2EveryMonthsInputValueChange,
}) => {
  return (
    <SbuxRadioGroup
      value={monthlyOption}
      handleRadioButtonChange={handleMonthlyOptionsValueChange}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"monthlyOption1Day"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  pointerEvents:
                    monthlyOption !== "monthlyOption1Day" && "none",
                  opacity: monthlyOption !== "monthlyOption1Day" && 0.6,
                }}
              >
                <Box
                  sx={{
                    marginRight: 1,
                    fontSize: 13,
                    marginTop: 0.4,
                  }}
                >
                  {"Day"}
                </Box>
                <Box sx={{ marginRight: 1, marginTop: 0.1 }}>
                  <input
                    type={"number"}
                    value={monthlyRecOption1EveryDayInputValue}
                    style={{
                      width: 40,
                      height: 24,
                      fontSize: 13,
                    }}
                    onChange={(event) =>
                      handleMonthlyRecOption1EveryDayInputValueChange(
                        event.target.value
                      )
                    }
                  />
                </Box>
                <Box sx={{ marginTop: 0.4, fontSize: 13, width: 60 }}>
                  {"of every"}
                </Box>
                <Box sx={{ marginRight: 1 }}>
                  <input
                    type={"number"}
                    value={monthlyRecOption1EveryMonthsInputValue}
                    style={{
                      width: 40,
                      height: 24,
                      fontSize: 13,
                    }}
                    onChange={(event) =>
                      handleMonthlyRecOption1EveryMonthsInputValueChange(
                        event.target.value
                      )
                    }
                  />
                </Box>
                <Box sx={{ marginTop: 0.4, fontSize: 13 }}>{"month(s)"}</Box>
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"monthlyOption2The"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  marginTop: 0.4,
                  fontSize: 13,
                  pointerEvents:
                    monthlyOption !== "monthlyOption2The" && "none",
                  opacity: monthlyOption !== "monthlyOption2The" && 0.6,
                }}
              >
                <Box
                  sx={{
                    marginRight: 1,
                    fontSize: 13,
                    marginTop: 0.4,
                  }}
                >
                  {"The"}
                </Box>

                <Box
                  sx={{
                    marginRight: 1,
                    fontSize: 13,
                    marginTop: 0,
                  }}
                >
                  <SbuxSelect
                    width={"6vw"}
                    menuItems={monthlyRecDayOfList}
                    defaultValue={monthlyRecDayOfValue}
                    value={monthlyRecDayOfValue}
                    handleSelectChange={(value) =>
                      handleMonthlyRecDayOfValueChange(value)
                    }
                  />
                </Box>

                <Box
                  sx={{
                    marginRight: 1,
                    fontSize: 13,
                    marginTop: 0,
                  }}
                >
                  <SbuxSelect
                    width={"8vw"}
                    menuItems={monthlyRecWeekDaysList}
                    defaultValue={monthlyRecWeekDays}
                    value={monthlyRecWeekDays}
                    handleSelectChange={(value) =>
                      handleMonthlyRecWeekDaysChange(value)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: 13,
                    marginTop: 0.4,
                    width: 60,
                  }}
                >
                  {"of every"}
                </Box>
                <Box sx={{ marginRight: 1 }}>
                  <input
                    type={"number"}
                    value={monthlyRecOption2EveryMonthsInputValue}
                    style={{
                      width: 40,
                      height: 24,
                      fontSize: 13,
                      marginTop: 0.4,
                    }}
                    onChange={(event) =>
                      handleMonthlyRecOption2EveryMonthsInputValueChange(
                        event.target.value
                      )
                    }
                  />
                </Box>
                <Box sx={{ marginTop: 0.4, fontSize: 13 }}>{"month(s)"}</Box>
              </Box>
            }
          />
        </Box>
      </Box>
    </SbuxRadioGroup>
  );
};

export default ClosureMsgMonthlyFrequency;
