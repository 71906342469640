import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    minWidth: 400,
    minHeight: 20,
    paddingBottom: 2,
  },
  dialogContentContainer: {
    disply: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
    width: "85%",
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 6,
  },
  mediaButton: {
    marginLeft: 6,
    fontSize: 12,
    height: 25,
    width: 90,
    paddingTop: 5.5,
    outline: "0 !important",
  },
  deleteButton: {
    marginLeft: 6,
    fontSize: 12,
    height: 25,
    width: 80,
    paddingTop: 4,
    outline: "0 !important",
  },
}));

export default useStyles;
