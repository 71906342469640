import React from "react";
import useStyles from "./styles";
import { formatMillisToMinutesAndSeconds } from "../../../../utils/dateUtil";
import IconButton from "@mui/material/IconButton";
import PositiveFaceIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import NegativeFaceIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import NeutralFaceIcon from "@mui/icons-material/SentimentNeutral";

const TranscriptContent = ({ data }) => {
  const classes = useStyles();
  const sortedTranscripts = [...(data?.transcripts ?? [])].sort(
    (t1, t2) => t1.BeginOffsetMillis - t2.BeginOffsetMillis
  );

  const IconMap = {
    NEUTRAL: <NeutralFaceIcon sx={{ fontSize: "24px" }} />,
    NEGATIVE: <NegativeFaceIcon sx={{ fontSize: "24px" }} />,
    POSITIVE: <PositiveFaceIcon sx={{ fontSize: "24px" }} />,
  };

  const SENTIMENT_BTN_COLOR = {
    NEUTRAL: "action", // mui color
    NEGATIVE: "#d62b1f",
    POSITIVE: "#00754A",
  };

  const displayTranscripts = () => {
    return sortedTranscripts.map((transcript, key) => {
      return (
        <div key={key} className={`${classes.transcriptHeaderDiv}`}>
          <div className={`${classes.flexHeader}`}>
            <h6 className={classes.participantHeaderText}>
              <b>{transcript?.["ParticipantId"]}</b>
            </h6>
            <span className={classes.durationText}>
              {formatMillisToMinutesAndSeconds(transcript?.["EndOffsetMillis"])}
            </span>
          </div>
          <div className={`${classes.transcriptContentDiv}`}>
            <IconButton
              sx={{ color: SENTIMENT_BTN_COLOR[transcript?.Sentiment] }}
              title={transcript?.Sentiment}
            >
              {IconMap[transcript.Sentiment]}
            </IconButton>
            <div className={classes.transcriptDiv}>
              <span>{transcript.Content}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classes.transcriptWrapperDiv}>{displayTranscripts()}</div>
  );
};

export default TranscriptContent;
