import ContentCrate from "@cxeweb/pattern-library/lib/components/content-crate";
import useStyles from "./styles";
const SbuxContentCrate = ({ children }) => {
  const classes = useStyles();
  return (
    <ContentCrate className={classes.root}>
      <div className="mx-auto p2">{children}</div>
    </ContentCrate>
  );
};
export default SbuxContentCrate;
