import { CONTACT_ATTRIBUTE_LABEL_TIME_STAMP } from "../../../constants";

export const getDataGridAttributes = (
  dataGridRows,
  contactData,
  ticketOrCaseNumber
) => {
  let currentData = {};

  const currentAttributes = dataGridRows.filter(
    (obj) => obj.name !== CONTACT_ATTRIBUTE_LABEL_TIME_STAMP
  );
  for (let key in currentAttributes) {
    if (currentAttributes[key].name.startsWith("Ticket")) {
      currentData[currentAttributes[key].name] = ticketOrCaseNumber;
    } else {
      currentData[currentAttributes[key].name] = currentAttributes[key].value;
    }
  }
  if (!currentData?.["Queue"] && !!contactData?.queue?.name) {
    currentData["Queue"] = contactData.queue.name;
  }
  currentData = {
    ...currentData,
    Duration:
      contactData.hasOwnProperty("contactDuration") &&
      contactData.contactDuration,
    timestamp:
      contactData.hasOwnProperty("queueTimestamp") &&
      contactData.queueTimestamp,
  };
  return currentData;
};
