import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { COLOR_GREEN_ACCENT, COLOR_NEUTURAL_WARM } from "../../constants";

export const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20%",
  padding: "2%",
}));

export const StyledDataGrid = styled(DataGrid)(() => ({
  //Datagrid outline
  "& .MuiDataGrid-root": {
    outline: "none",
    border: "0",
  },

  "& .MuiDataGrid-autoHeight": {
    border: "0",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 21,
  },

  //Datagrid background color
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: COLOR_GREEN_ACCENT,
    backgroundImage: "linear-gradient(#006241, #1a7254)",
    color: COLOR_NEUTURAL_WARM,
    fontSize: 15,
    fontWeight: 500,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    minHeight: "21px !important",
    maxHeight: "31px !important",
    lineHeight: "15px !important",
    paddingTop: "6px",
    paddingBottom: "6px",
  },

  //Datagrid overlay fontsize (all cell fontsize)
  "& .MuiDataGrid-overlay": {
    fontSize: 12,
  },

  "& .MuiDataGrid-virtualScrollerContent": {
    height: "70vh !important",
  },

  //Datagrid row hover
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "rgb(204, 204, 204, 0.5)",
    backgroundImage: "linear-gradient(#e0e0e0, #e6e6e6)",
  },

  "& .MuiDataGrid-row": {
    minHeight: "31px !important",
    maxHeight: "31px !important",
    lineHeight: "15px !important",
    borderTop: "1px solid",
    borderTopColor: "rgba(0, 0, 0, 0.12)",
  },

  //Rows Perpage alignment
  "& .MuiTablePagination-selectLabel": {
    paddingTop: 3,
    fontSize: 13,
  },
  //Rows selection dropdown box alignment
  "& .MuiInputBase-root": {
    paddingTop: 2,
    fontSize: 13,
  },

  //display rows like 1-8 of 8
  "& .MuiTablePagination-displayedRows": {
    paddingTop: 3,
    fontSize: 13,
  },

  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "rgba(0,98,68,.05)",
    backgroundImage: "linear-gradient(#e6f0ed, #ebf3f0)",
  },
  "& .MuiDataGrid-row:nth-of-type(even):hover": {
    backgroundColor: "rgb(204, 204, 204, 0.5)",
    backgroundImage: "linear-gradient(#e0e0e0, #e6e6e6)",
  },

  //Grid cell fontsize
  "& .MuiDataGrid-cell": {
    fontSize: 12,
    minHeight: "31px !important",
    maxHeight: "31px !important",
    lineHeight: "15px !important",
    borderBottom: "0px",
    padding: "0 6px",
  },

  //Grid cell selection outline
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },

  "& .MuiDataGrid-row.Mui-selected": {
    backgroundColor: "inherit",
  },

  "& .MuiDataGrid-virtualScroller": {
    marginTop: "30px !important",
  },

  //Sort Button hover and margin alginment
  "& .MuiIconButton-root": {
    backgroundColor: COLOR_NEUTURAL_WARM,
  },

  "& .MuiIconButton-root:hover": {
    backgroundColor: COLOR_NEUTURAL_WARM,
  },

  "& .MuiIconButton-root:focus": {
    outline: "none",
  },

  "& .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    // fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    padding: "1px 0 0 0",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: "0 6px",
  },

  "& .MuiDataGrid-columnHeader:first-of-type": {
    padding: "0 6px",
    borderRight: "1px solid white",
    lineHeight: 56,
  },
}));
