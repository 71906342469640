import SbuxHeading from "../SbuxHeading";
import SubxImageLoader from "../SbuxImageLoader";
import SbuxButton from "../SbuxButton";
import SbuxTranslate from "../SbuxTranslate";
import useStyles from "./styles";
import { Link, Box } from "@mui/material";

const SbuxFallback = ({
  error = null,
  resetErrorBoundary = null,
  isServicePortalMessage = false,
}) => {
  const classes = useStyles();

  const servicePortalLinkRenderer = () => {
    return (
      <div>
        <div>
          <span>
            <SbuxTranslate>{`You may not be provisioned in Amazon Connect, or an error occurred.  To gain access, please speak with your manager or supervisor.`}</SbuxTranslate>
            <SbuxTranslate>{` If you believe there is a system error, please open a ticket with the Service Desk at 888-728-9411, option 2.`}</SbuxTranslate>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.img}>
        <SubxImageLoader source={"/images/instance.png"} width={"25%"} />
      </div>

      <SbuxHeading tagName="h6" size="xs" className={classes.headingPrimary}>
        <SbuxTranslate>{`Oops, an error occurred.`}</SbuxTranslate>
      </SbuxHeading>
      <SbuxHeading tagName="h6" size="xs" className={classes.headingSecondary}>
        {servicePortalLinkRenderer()}
      </SbuxHeading>
      <SbuxHeading tagName="h6" size="xs" className={classes.headingSecondary}>
        <Box sx={{ paddingTop: 10, fontSize: 15, fontWeight: 600 }}>
          <SbuxButton onClick={resetErrorBoundary}>
            <SbuxTranslate>{`Refresh`}</SbuxTranslate>
          </SbuxButton>
        </Box>
      </SbuxHeading>
    </div>
  );
};

export default SbuxFallback;
