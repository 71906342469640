import { createSlice } from "@reduxjs/toolkit";
import {
  retrieveApiData,
  deleteApiData,
  resetApiData,
} from "../services/connect";

const initialState = {
  status: "idle",
  showLoadingIndicator: false,
  datasets: {},
  errorMessage: "",
};

const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetApiData, (state) => {
        state.status = "idle";
        state.errorMessage = "";
      })
      .addCase(retrieveApiData.pending, (state, action) => {
        state.status = !state.status.startsWith("delete")
          ? "get:pending"
          : "get:followup:pending";
        state.showLoadingIndicator = true;
      })
      .addCase(retrieveApiData.fulfilled, (state, action) => {
        state.status = "get:success";
        state.showLoadingIndicator = false;
        const {
          meta: {
            arg: { apiName },
          },
          payload: { data },
        } = action;

        state.datasets[apiName] = data;
      })
      .addCase(retrieveApiData.rejected, (state) => {
        state.status = "get:rejected";
        state.showLoadingIndicator = true;
        const {
          error: { message },
        } = action;
        state.errorMessage = message;
      })
      .addCase(deleteApiData.pending, (state, action) => {
        state.status = "delete:pending";
        state.showLoadingIndicator = true;
      })
      .addCase(deleteApiData.fulfilled, (state, action) => {
        state.status = "delete:success";
        state.showLoadingIndicator = true;
        const {
          meta: {
            arg: { apiName },
          },
          payload: { data },
        } = action;

        state.datasets[apiName] = data;
      })
      .addCase(deleteApiData.rejected, (state, action) => {
        state.status = "delete:rejected";
        state.showLoadingIndicator = true;
        const {
          error: { message },
        } = action;
        state.errorMessage = message;
      });
  },
});

export default connectSlice.reducer;
