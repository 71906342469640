import React from "react";
import { Divider } from "@mui/material";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SbuxTranslate from "../../SbuxTranslate";
import { getLocale } from "../../../services/settings";
import languageConfig from "../config/language.json";
import useStyles from "../styles";

const LanguageList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    settings: { locale },
  } = useSelector((state) => state);

  const handleChange = async (e) => {
    await dispatch(getLocale(e.target.value));
  };

  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 1,
      }}
    >
      <Box className={classes.dragTitle}>
        <SbuxTranslate>{"Language"}</SbuxTranslate>
      </Box>
      <select
        style={{ width: 250, height: 30, fontSize: 14, marginTop: 2 }}
        value={locale}
        onChange={handleChange}
      >
        {languageConfig.lanaguages.map((item, index) => {
          return (
            <option
              style={{ width: 250, fontSize: 14 }}
              key={index}
              value={item.code}
            >
              {item.language}
            </option>
          );
        })}
      </select>
      <Divider sx={{ marginTop: 1 }} />
    </Box>
  );
};

export default LanguageList;
