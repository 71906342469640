import { useState } from "react";
import { Box } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import MainHoursConfigTable from "../mainHours/MainHoursConfigTable";
import useStyles from "../../styles";

const MainHoursAdd = ({ toggleMainHoursAddDialogOpen }) => {
  const initTable = {
    hoursName: "",
    description: "",
  };
  const [table, setTable] = useState(initTable);
  const [hoursNameError, sethoursNameError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTable({ ...table, [name]: value });
    name === "hoursName" && sethoursNameError(value.length < 1 ? true : false);
  };

  return (
    <Box sx={{ height: "70vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 2,
          "& .MuiTextField-root": {
            width: "70ch",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Box>
          <SbuxTextField
            variant={"outlined"}
            label={"Hours Name *"}
            isError={hoursNameError}
            placeholder={`Hours Name`}
            handleChange={handleChange}
            value={table.hoursName}
            name={"hoursName"}
            errorMessage={"Hours name required at least 1 character"}
            error={hoursNameError}
            required
          />
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            placeholder={`Description`}
            label={"Description (Optional) "}
            handleChange={handleChange}
            value={table.description}
            name={"description"}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <MainHoursConfigTable
          rowData={{
            hoursName: table.hoursName,
            description: table.description,
          }}
          toggleDialogOpen={toggleMainHoursAddDialogOpen}
        />
      </Box>
    </Box>
  );
};

export default MainHoursAdd;
