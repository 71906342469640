import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import useStyles from "../styles";

const ResourcesRow = ({ row }) => {
  const classes = useStyles();
  const linkRenderer = (resource) => {
    const newTab = window.open(resource.url, "_blank", "noopener,noreferrer");
    newTab.focus();
  };
  return (
    <>
      <TableRow>
        <TableCell className={classes.headerCellRoot}>{row.name}</TableCell>
      </TableRow>
      {row.resources &&
        row.resources.map((resource, index) => {
          return (
            <TableRow key={index} className={classes.detailRowRoot}>
              <TableCell className={classes.detailCellRoot}>
                <Link
                  component={"button"}
                  href={resource.url}
                  onClick={() => linkRenderer(resource)}
                >
                  {resource.name}
                </Link>
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
};

export default ResourcesRow;
