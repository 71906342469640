import { useState, useEffect } from "react";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxCustomRow from "../../../../../components/SbuxTable/SbuxCustomRow";
import SbuxCustomTable from "../../../../../components/SbuxTable/SbuxCustomTable";
import SbuxSubTabs from "../../../../../components/SbuxTabs/SbuxSubTabs";
import ClosureMsgTimePicker from "./components/ClosureMsgTimePicker";
import ClosureMsgDailyFrequency from "./components/ClosureMsgDailyFrequency";
import ClosureMsgWeeklyFrequency from "./components/ClosureMessageWeeklyFrequency";
import ClosureMsgMonthlyFrequency from "./components/ClosureMsgMonthlyFrequency";
import ClosureMsgRangeRecurrence from "./components/ClosureMsgRangeRecurrence";
import ClosureMsgYearlyFrequency from "./components/ClosureMsgYearlyFrequency";
import ClosureAudioConfig from "../../common/ClosureAudioConfig";
import SbuxRadioGroup from "../../../../../components/SbuxRadioGroup";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import useTextTranslate from "../../../hooks/useTextTranslate";
import { closureColumns } from "../closureMsg/columns";
import { Box, Divider } from "@mui/material";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { CM_RECURRENCE_FREQUENCY_LIST } from "../../../../../constants";

const ClosureMsgEdit = ({ row, handleClosureMsgRow, isSingleInstance }) => {
  const {
    userInstance: {
      connectedInstance: {
        appConfig: { languages },
      },
    },
    globalConfig: { globalConfigUi },
    mediaMgt: { mediaMgtData },
  } = useSelector((state) => state);

  let rowbj = Object.assign({}, row);
  const [description, setDescription] = useState(rowbj.description);
  const [descriptionError, setDescriptionError] = useState(false);
  const [startDate, setStartDate] = useState(moment(rowbj.startDate).toDate());
  const [endDate, setEndDate] = useState(moment(rowbj.endDate).toDate());
  const [startTime, setStartTime] = useState(rowbj.startTime);
  const [endTime, setEndTime] = useState(rowbj.endTime);
  const [startTimeZone, setStartTimeZone] = useState(rowbj.startTimeZone);
  const [endTimeZone, setEndTimeZone] = useState(rowbj.endTimeZone);

  const [languageTabId, setLanguageTabId] = useState("en");
  const [languageMessage, setLanguageMessage] = useState({});
  const [recPatternOption, setRecPatternOption] = useState(
    rowbj?.hasOwnProperty("repeatPattern") && rowbj.repeatPattern
      ? rowbj.repeatPattern?.frequency
      : null
  );

  const descriptionPlaceHolder = useTextTranslate("Description");
  const [timeLable, setTimeLabel] = useState("");

  useEffect(() => {
    if (timeLable === "Start Time") {
      const endTimeUpdate = moment(startTime, "hh:mm A")
        .add("minutes", 30)
        .format("hh:mm A");
      setEndTime(endTimeUpdate);
      const editRow = {
        ...rowbj,
        startTime: startTime,
        endTime: endTimeUpdate,
      };
      handleClosureMsgRow(editRow);
    }
  }, [startTime, endTime]);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    setDescriptionError(value.length < 1 ? true : false);
    const updateRow = {
      ...rowbj,
      description: value,
    };
    handleClosureMsgRow(updateRow);
  };

  const handleDateChange = (value) => {
    let updateRow = null;
    if (value.name === "Start Time") {
      setStartDate(value.date);
      updateRow = {
        ...rowbj,
        startDate: value.date,
        endDate: value.date,
      };
    } else {
      setEndDate(value.date);
      updateRow = {
        ...rowbj,
        endDate: value.date,
      };
    }
    handleClosureMsgRow(updateRow);
  };
  const handleStartTimeChange = (value) => {
    let updateRow = null;
    setStartTime(value.time);
    setTimeLabel("Start Time");
    updateRow = {
      ...rowbj,
      startTime: value.time,
    };
    handleClosureMsgRow(updateRow);
  };

  const handleEndTimeChange = (value) => {
    let updateRow = null;
    setEndTime(value.time);
    setTimeLabel("End Time");
    updateRow = {
      ...rowbj,
      endTime: value.time,
    };
    handleClosureMsgRow(updateRow);
  };
  const handleTimeZoneChange = (timeZone) => {
    let updateRow = null;
    setStartTimeZone(timeZone);
    setEndTimeZone(timeZone);
    updateRow = {
      ...rowbj,
      startTimeZone: timeZone,
      endTimeZone: timeZone,
    };
    handleClosureMsgRow(updateRow);
  };

  const handleRecPatternOptioneChange = (event) => {
    setRecPatternOption(event.target.value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        frequency: event.target.value,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  //Daily
  const selectDailyOption =
    rowbj?.hasOwnProperty("repeatPattern") &&
    rowbj.repeatPattern?.frequency === "DAILY" &&
    Number(rowbj.repeatPattern.interval)
      ? "dailyOption1EveryDays"
      : "dailyOption2EveryWeekDay";
  const [dailyOption, setDailyOption] = useState(selectDailyOption);
  const [dailyOptoin1InputValue, setDailyOptoin1InputValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "DAILY" &&
      rowbj.repeatPattern.interval
      ? Number(rowbj.repeatPattern.interval)
      : 1
  );

  const handleDailyOptionsValueChange = (event) => {
    if (event.target.value === "dailyOption2EveryWeekDay") {
      rowbj.isDailyOption2Selected = true;
      handleClosureMsgRow(rowbj);
    } else {
      rowbj.isDailyOption2Selected = false;
      handleClosureMsgRow(rowbj);
    }

    setDailyOption(event.target.value);
  };

  const handleDailyOptoin1InputValue = (value) => {
    setDailyOptoin1InputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        interval: Number(value),
      },
    };
    handleClosureMsgRow(updateRow);
  };

  //Weelky

  const [weeklyRecEveryInputValue, setWeeklyRecEveryInputValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "WEEKLY" &&
      rowbj.repeatPattern.interval
      ? Number(rowbj.repeatPattern.interval)
      : 1
  );
  const [weeklyRecDaysChecked, setWeeklyRecDaysChecked] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "WEEKLY" &&
      rowbj.repeatPattern.byWeekDay
      ? rowbj.repeatPattern.byWeekDay
      : ["MO"]
  );

  const handleWeeklyRecEveryInputValueChange = (value) => {
    setWeeklyRecEveryInputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        interval: Number(value),
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const handleCheckboxChange = (event) => {
    let data = [];
    if (event.target.checked) {
      const arr = [...weeklyRecDaysChecked];
      arr.push(event.target.name);
      data = [...arr];
      setWeeklyRecDaysChecked(data);
    } else {
      data = weeklyRecDaysChecked.filter((f) => f !== event.target.name);
      setWeeklyRecDaysChecked(data);
    }
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byWeekDay: data,
      },
    };
    handleClosureMsgRow(updateRow);
  };
  //Monthly

  const selectMonhtlyOption =
    rowbj?.hasOwnProperty("repeatPattern") &&
    rowbj.repeatPattern?.frequency === "MONTHLY" &&
    rowbj.repeatPattern.byWeekDay &&
    rowbj.repeatPattern.byWeekDayN
      ? "monthlyOption2The"
      : "monthlyOption1Day";
  const [monthlyOption, setMonthlyOption] = useState(selectMonhtlyOption);
  const [monthlyRecDayOfValue, setMonthlyRecDayOfValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "MONTHLY" &&
      rowbj.repeatPattern?.hasOwnProperty("byWeekDayN")
      ? Number(rowbj.repeatPattern.byWeekDayN)
      : 1
  );
  const [monthlyRecWeekDays, setMonthlyRecWeekDays] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "MONTHLY" &&
      rowbj.repeatPattern?.hasOwnProperty("byWeekDay")
      ? rowbj.repeatPattern.byWeekDay
      : ["MO"]
  );

  const [
    monthlyRecOption1EveryDayInputValue,
    setMonthlyRecOption1EveryDayInputValue,
  ] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "MONTHLY" &&
      rowbj.repeatPattern?.hasOwnProperty("byDay")
      ? Number(rowbj.repeatPattern.byDay)
      : 1
  );

  const [
    monthlyRecOption1EveryMonthsInputValue,
    setMonthlyRecOption1EveryMonthsInputValue,
  ] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "MONTHLY" &&
      rowbj.repeatPattern?.hasOwnProperty("interval")
      ? Number(rowbj.repeatPattern.interval)
      : 1
  );

  const [
    monthlyRecOption2EveryMonthsInputValue,
    setMonthlyMonthlyRecOption2EveryMonthsInputValue,
  ] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "MONTHLY" &&
      rowbj.repeatPattern.byWeekDayN &&
      rowbj.repeatPattern?.hasOwnProperty("interval")
      ? Number(rowbj.repeatPattern.interval)
      : 1
  );

  const handleMonthlyOptionsValueChange = (event) => {
    setMonthlyOption(event.target.value);
    if (event.target.value === "monthlyOption2The") {
      rowbj.isMonthlyOption2Selected = true;
      handleClosureMsgRow(rowbj);
    } else {
      rowbj.isMonthlyOption2Selected = false;
      handleClosureMsgRow(rowbj);
    }
  };

  const handleMonthlyRecDayOfValueChange = (value) => {
    setMonthlyRecDayOfValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byWeekDayN: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const handleMonthlyRecWeekDaysChange = (value) => {
    setMonthlyRecWeekDays(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byWeekDay: [value],
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const handleMonthlyRecOption1EveryDayInputValueChange = (value) => {
    setMonthlyRecOption1EveryDayInputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byDay: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };
  const handleMonthlyRecOption1EveryMonthsInputValueChange = (value) => {
    setMonthlyRecOption1EveryMonthsInputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        interval: Number(value),
      },
    };
    handleClosureMsgRow(updateRow);
  };
  const handleMonthlyRecOption2EveryMonthsInputValueChange = (value) => {
    setMonthlyMonthlyRecOption2EveryMonthsInputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        interval: Number(value),
      },
    };
    handleClosureMsgRow(updateRow);
  };

  //Yearly
  const selectYearlyOption =
    rowbj?.hasOwnProperty("repeatPattern") &&
    rowbj.repeatPattern?.frequency === "YEARLY" &&
    rowbj.repeatPattern.byDay
      ? "yearlyOption1On"
      : "yearlyOption2OnThe";
  const [yearlyOption, setYearlyOption] = useState(selectYearlyOption);

  const handleYearlyOptionsValueChange = (event) => {
    setYearlyOption(event.target.value);
    if (event.target.value === "yearlyOption2OnThe") {
      rowbj.isYearlyOption2Selected = true;
      handleClosureMsgRow(rowbj);
    } else {
      rowbj.isYearlyOption2Selected = false;
      handleClosureMsgRow(rowbj);
    }
  };

  const [yearlyRecEveryInputValue, setYearlyRecEveryInputValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "YEARLY"
      ? Number(rowbj.repeatPattern.interval)
      : 1
  );

  const handleYearlyRecEveryInputValueChange = (value) => {
    setYearlyRecEveryInputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        interval: Number(value),
      },
    };
    handleClosureMsgRow(updateRow);
  };

  //Option 1 -----Start
  const [yearlyOption1MonthValue, setYearlyOption1MonthValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "YEARLY" &&
      rowbj.repeatPattern.byMonth
      ? rowbj.repeatPattern.byMonth
      : 1
  );
  const handleYearlyOption1MonthValueChange = (value) => {
    setYearlyOption1MonthValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byMonth: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const [yearlyOption1OnInputValue, setYearlyOption1OnInputValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "YEARLY" &&
      rowbj.repeatPattern.byDay
      ? rowbj.repeatPattern.byDay
      : 1
  );
  const handleYearlyOption1OnInputValueChange = (value) => {
    setYearlyOption1OnInputValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byDay: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const [yearlyRecDayOfValue, setYearlyRecDayOfValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "YEARLY" &&
      rowbj.repeatPattern.byWeekDayN
      ? rowbj.repeatPattern.byWeekDayN
      : 1
  );
  const [yearlyRecWeekDays, setYearlyRecWeekDays] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "YEARLY" &&
      rowbj.repeatPattern.byWeekDay
      ? rowbj.repeatPattern.byWeekDay
      : ["MO"]
  );
  const handleYearlyRecDayOfValueChange = (value) => {
    setYearlyRecDayOfValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byWeekDayN: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const handleYearlyRecWeekDaysChange = (value) => {
    setYearlyRecWeekDays(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byWeekDay: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };
  const [yearlyOption2MonthValue, setYearlyOption2MonthValue] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.frequency === "YEARLY" &&
      rowbj.repeatPattern.byMonth
      ? rowbj.repeatPattern.byMonth
      : 1
  );
  const handleYearlyOption2MonthValueChange = (value) => {
    setYearlyOption2MonthValue(value);
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        byMonth: value,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  //Recurrence Date

  const selectEndByOption =
    rowbj?.hasOwnProperty("repeatPattern") &&
    rowbj.repeatPattern?.hasOwnProperty("until") &&
    rowbj.repeatPattern.until
      ? "endByEndDate"
      : "endByNoDate";

  const [endByOption, setEndByOption] = useState(selectEndByOption);

  const handleEndByOptionsValueChange = (event) => {
    setEndByOption(event.target.value);
    handleClosureMsgRow(rowbj);
  };
  const [endByEndDate, setEndByEndDate] = useState(
    rowbj?.hasOwnProperty("repeatPattern") &&
      rowbj.repeatPattern?.hasOwnProperty("until")
      ? moment(rowbj.repeatPattern.until, "YYYY-MM-DD").toDate()
      : moment().toDate()
  );

  const handleEndByEndDateChange = (value) => {
    setEndByEndDate(value);
    const endByEndDate = moment
      .tz(
        `${moment(value).format("YYYY-MM-DD")} ${endTime}`,
        "YYYY-MM-DD hh:mm a",
        endTimeZone
      )
      .toISOString();
    const updateRow = {
      ...rowbj,
      repeatPattern: {
        ...rowbj.repeatPattern,
        until: endByEndDate,
      },
    };
    handleClosureMsgRow(updateRow);
  };

  const handleSubTabChange = (newValue) => {
    setLanguageTabId(newValue);
  };
  const handleLanguageMediaFile = (value, isAudioFileChanged = false) => {
    let messageLanguageTabId = `message_${languageTabId}`;
    const existingLanguages =
      Object.keys(rowbj).length > 0 &&
      Object.keys(rowbj)
        .filter((a) => a.includes("message"))
        .reduce((acc, cur) => ((acc[cur] = rowbj[cur]), acc), {});
    let updateLanguageMessage = {
      ...existingLanguages,
    };
    let updateRow = {
      ...rowbj,
    };
    if (value) {
      const mediaInfo = mediaMgtData.data.find((m) => m.filePath === value);

      if (mediaInfo && mediaInfo.filePath) {
        const newLanguageMessage = {
          [`${messageLanguageTabId}`]: {
            type: "AUDIO",
            value: mediaInfo.filePath,
            accessType: mediaInfo.type,
          },
        };

        updateLanguageMessage = {
          ...existingLanguages,
          ...newLanguageMessage,
        };
        updateRow = {
          ...rowbj,
          ...updateLanguageMessage,
          languageMessage: { ...updateLanguageMessage },
          isAudioFileChanged: isAudioFileChanged,
        };
      }
    } else {
      if (updateLanguageMessage[messageLanguageTabId]) {
        delete updateLanguageMessage[messageLanguageTabId];
      }
      updateRow = {
        ...rowbj,
        ...updateLanguageMessage,
        languageMessage: { ...updateLanguageMessage },
        isAudioFileChanged: isAudioFileChanged,
      };
      if (updateRow[messageLanguageTabId]) {
        delete updateRow[messageLanguageTabId];
      }
    }

    handleClosureMsgRow(updateRow);
  };

  const SubTabsComponent = () => {
    const updatedLanguages = languages.reduce((acc, cur) => {
      acc.push({
        name: cur.name,
        value: cur.code,
      });
      return acc;
    }, []);

    //Initial
    rowbj.startDate = startDate;
    rowbj.endDate = endDate;
    rowbj.startTime = startTime;
    rowbj.endTime = endTime;
    rowbj.startTimeZone = startTimeZone;
    rowbj.endTimeZone = endTimeZone;
    const handleSubTabClick = (tab) => {
      handleSubTabChange(tab.value);
    };
    return (
      <SbuxSubTabs
        tabList={updatedLanguages}
        tabId={languageTabId}
        handleTabClick={handleSubTabClick}
        width={"33vw"}
        maxWidth={"530px"}
      />
    );
  };
  return (
    <Box sx={{ height: "76vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          paddingTop: 0.5,
        }}
        noValidate
        autoComplete="off"
      >
        <SbuxCustomTable
          width="100%"
          height="78vh"
          columns={closureColumns}
          isTranslationRequired
        >
          <SbuxCustomRow
            name="Business"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  disabled
                  value={rowbj.groupName}
                />
              </Box>
            }
          />

          <SbuxCustomRow
            name="Category"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  disabled
                  value={rowbj.category}
                />
              </Box>
            }
          />

          <SbuxCustomRow
            name="Description"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  placeholder={descriptionPlaceHolder}
                  value={description}
                  handleChange={handleDescriptionChange}
                  errorMessage={"Description required at least 1 character"}
                  isError={descriptionError}
                  error={descriptionError}
                  disabled={rowbj.status === "Expired" || isSingleInstance}
                  required={!isSingleInstance}
                />
              </Box>
            }
            isRequired
          />

          <SbuxCustomRow
            name="Series Settings"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  disabled
                  value={rowbj.type}
                />
              </Box>
            }
          />

          <SbuxCustomRow
            name={
              <Box sx={{ fontSize: 16, fontWeight: 600 }}>
                {`Date and Time`}
              </Box>
            }
          />

          <ClosureMsgTimePicker
            name="Start Time"
            startDate={startDate}
            endDate={endDate}
            startTimeValue={startTime}
            endTimeValue={endTime}
            timeZoneValue={startTimeZone}
            timeZoneList={globalConfigUi.timezones}
            handleDateChange={handleDateChange}
            handleStartTimeChange={handleStartTimeChange}
            handleTimeZoneChange={handleTimeZoneChange}
            row={rowbj}
            disabled={isSingleInstance}
          />

          <ClosureMsgTimePicker
            name="End Time"
            startDate={startDate}
            endDate={endDate}
            startTimeValue={startTime}
            endTimeValue={endTime}
            timeZoneValue={endTimeZone}
            timeZoneList={globalConfigUi.timezones}
            handleDateChange={handleDateChange}
            handleEndTimeChange={handleEndTimeChange}
            handleTimeZoneChange={handleTimeZoneChange}
            row={rowbj}
            disabled={isSingleInstance}
          />
          {rowbj.type === "Repeat" && (
            <>
              <SbuxCustomRow
                name="Repeat Pattern"
                disabled={isSingleInstance}
                value={
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: 130,
                    }}
                  >
                    <Box sx={{ width: 100 }}>
                      <SbuxRadioGroup
                        radioButtonList={CM_RECURRENCE_FREQUENCY_LIST}
                        value={recPatternOption}
                        handleRadioButtonChange={handleRecPatternOptioneChange}
                      />
                    </Box>

                    <Box sx={{ width: "1px" }}>
                      <Divider
                        orientation="vertical"
                        sx={{ backgroundColor: "#006241" }}
                      />
                    </Box>
                    <Box sx={{ flex: 3 }}>
                      {recPatternOption === "DAILY" && (
                        <ClosureMsgDailyFrequency
                          dailyOption={dailyOption} //Raido button value for Daily
                          handleDailyOptionsValueChange={
                            handleDailyOptionsValueChange
                          }
                          dailyOptoin1InputValue={dailyOptoin1InputValue}
                          handleDailyOptoin1InputValue={
                            handleDailyOptoin1InputValue
                          }
                        />
                      )}

                      {recPatternOption === "WEEKLY" && (
                        <ClosureMsgWeeklyFrequency
                          isEdit={true}
                          weeklyRecDays={weeklyRecDaysChecked}
                          weeklyRecEveryInputValue={weeklyRecEveryInputValue}
                          handleCheckboxChange={handleCheckboxChange}
                          handleWeeklyRecEveryInputValueChange={
                            handleWeeklyRecEveryInputValueChange
                          }
                        />
                      )}

                      {recPatternOption === "MONTHLY" && (
                        <ClosureMsgMonthlyFrequency
                          monthlyOption={monthlyOption} //Raido button value for Monthly
                          handleMonthlyOptionsValueChange={
                            handleMonthlyOptionsValueChange
                          }
                          monthlyRecOption1EveryDayInputValue={
                            monthlyRecOption1EveryDayInputValue
                          }
                          monthlyRecOption1EveryMonthsInputValue={
                            monthlyRecOption1EveryMonthsInputValue
                          }
                          monthlyRecOption2EveryMonthsInputValue={
                            monthlyRecOption2EveryMonthsInputValue
                          }
                          monthlyRecDayOfList={globalConfigUi.closure.monthly}
                          monthlyRecDayOfValue={monthlyRecDayOfValue} //First, Last and etc
                          handleMonthlyRecDayOfValueChange={
                            handleMonthlyRecDayOfValueChange
                          }
                          monthlyRecWeekDaysList={globalConfigUi.weekdays}
                          monthlyRecWeekDays={monthlyRecWeekDays} //dropdown value (Sunday, Monday)
                          handleMonthlyRecWeekDaysChange={
                            handleMonthlyRecWeekDaysChange
                          }
                          handleMonthlyRecOption1EveryDayInputValueChange={
                            handleMonthlyRecOption1EveryDayInputValueChange
                          }
                          handleMonthlyRecOption1EveryMonthsInputValueChange={
                            handleMonthlyRecOption1EveryMonthsInputValueChange
                          }
                          handleMonthlyRecOption2EveryMonthsInputValueChange={
                            handleMonthlyRecOption2EveryMonthsInputValueChange
                          }
                        />
                      )}
                      {recPatternOption === "YEARLY" && (
                        <ClosureMsgYearlyFrequency
                          yearlyOption={yearlyOption}
                          yearlyMonthList={globalConfigUi.months}
                          yearlyRecWeekDaysList={globalConfigUi.closure.monthly}
                          yearlyWeekDaysList={globalConfigUi.weekdays}
                          handleYearlyOptionsValueChange={
                            handleYearlyOptionsValueChange
                          }
                          yearlyRecEveryInputValue={yearlyRecEveryInputValue}
                          handleYearlyRecEveryInputValueChange={
                            handleYearlyRecEveryInputValueChange
                          }
                          yearlyOption1MonthValue={yearlyOption1MonthValue}
                          handleYearlyOption1MonthValueChange={
                            handleYearlyOption1MonthValueChange
                          }
                          yearlyOption1OnInputValue={yearlyOption1OnInputValue}
                          handleYearlyOption1OnInputValueChange={
                            handleYearlyOption1OnInputValueChange
                          }
                          yearlyOption2MonthValue={yearlyOption2MonthValue}
                          handleYearlyOption2MonthValueChange={
                            handleYearlyOption2MonthValueChange
                          }
                          yearlyRecDayOfValue={yearlyRecDayOfValue}
                          handleYearlyRecDayOfValueChange={
                            handleYearlyRecDayOfValueChange
                          }
                          yearlyRecWeekDays={yearlyRecWeekDays}
                          handleYearlyRecWeekDaysChange={
                            handleYearlyRecWeekDaysChange
                          }
                        />
                      )}
                    </Box>
                  </Box>
                }
              />
              <SbuxCustomRow
                name={"End by"}
                disabled={isSingleInstance}
                value={
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginRight: 0.5 }}>
                      <ClosureMsgRangeRecurrence
                        endByOption={endByOption}
                        endByEndDate={endByEndDate}
                        handleEndByEndDateChange={handleEndByEndDateChange}
                        handleEndByOptionsValueChange={
                          handleEndByOptionsValueChange
                        }
                      />
                    </Box>
                  </Box>
                }
              />
            </>
          )}

          <SbuxCustomRow
            name={<Box sx={{ fontSize: 13 }}>{`Audio Configuration`}</Box>}
            value={
              <Box
                sx={{
                  display: "flex",
                  height: 165,
                  flexDirection: "column",
                }}
              >
                <SbuxTranslate>
                  {rowbj.type === "Repeat" && isSingleInstance && (
                    <Box
                      sx={{
                        color: "#006241",
                        fontSize: 12,
                        fontWeight: 600,
                        width: "50vw",
                      }}
                    >
                      Your audio file will be updated for the entire series
                    </Box>
                  )}
                </SbuxTranslate>
                <Box sx={{ height: 165, border: "2px solid lightgrey" }}>
                  <SubTabsComponent />
                  <Box sx={{ width: "100%", marginLeft: 2 }}>
                    <ClosureAudioConfig
                      languageId={languageTabId}
                      handleLanguageMediaFile={handleLanguageMediaFile}
                      row={rowbj}
                      disabled={rowbj.status === "Expired"}
                      maxWidth={"400px"}
                    />
                  </Box>
                </Box>
              </Box>
            }
          />
        </SbuxCustomTable>
      </Box>
    </Box>
  );
};

export default ClosureMsgEdit;
