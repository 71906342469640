import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import SbuxButton from "../../../../../components/SbuxButton";
import SbuxSnackbars from "../../../../../components/SbuxSnackbars";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateIvrTable,
  setStatusUpdate,
} from "../../../../../services/ivrTable";

import useStyles from "../../styles";

const AdminIvrTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initTable = { tableName: "", partitionKey: "", sortKey: "" };
  const [table, setTabe] = useState(initTable);
  const [tableNameError, setTableNameError] = React.useState(false);
  const [partitionKeyError, setPartitionKeyError] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const {
    ivrTable: { statusCode, message },
  } = useSelector((state) => state);

  useEffect(() => {
    (statusCode === 200 || statusCode === 400) &&
      message.length > 0 &&
      setOpenSnackbar(true);
  }, [statusCode, message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTabe({ ...table, [name]: value });
    name === "tableName"
      ? setTableNameError(value.length < 1 ? true : false)
      : name === "partitionKey" &&
        setPartitionKeyError(value.length < 1 ? true : false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (table.tableName && table.partitionKey) {
      dispatch(createOrUpdateIvrTable(table));
    }
  };
  const handleClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    dispatch(setStatusUpdate());
  };

  const handleClear = () => {
    setTabe({ tableName: "", partitionKey: "", sortKey: "" });
    setTableNameError(false);
    setPartitionKeyError(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "30px",
          backgroundColor: "#006241",
          borderTopLeftRadius: 5,
          display: "flex",
          alignItems: "center",
          color: "#f2f0eb",
          fontSize: 15,
          paddingLeft: 1,
          position: "relative",
        }}
      >
        {`Create Table`}
      </Box>

      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 2,
          "& .MuiTextField-root": {
            width: "70ch",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ marginBottom: 2 }}>
          <Box sx={{ fontSize: 16 }}>
            <span>{`Table Name`}</span>
            <span style={{ fontSize: 18, color: "red" }}>{`*`}</span>
          </Box>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: tableNameError ? "red" : "#006241",
                },
              },
            }}
            inputProps={{
              style: { fontSize: 16, flex: 1 },
            }}
            placeholder={`Table Name`}
            onChange={handleChange}
            value={table.tableName}
            name={"tableName"}
            helperText={
              tableNameError ? (
                <span style={{ fontSize: 14, marginLeft: 0 }}>
                  {"Table Name required at least 1 character"}
                </span>
              ) : (
                ""
              )
            }
            error={tableNameError}
            size="small"
            focused
            required
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Box sx={{ fontSize: 16 }}>
            <span>{`Partition Key`}</span>
            <span style={{ fontSize: 18, color: "red" }}>{`*`}</span>
          </Box>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: partitionKeyError ? "red" : "#006241",
                },
              },
            }}
            inputProps={{
              style: { fontSize: 16, flex: 1 },
            }}
            placeholder={`Partition Key`}
            onChange={handleChange}
            value={table.partitionKey}
            name={"partitionKey"}
            helperText={
              partitionKeyError ? (
                <span style={{ fontSize: 14 }}>
                  {"Partition Key required at least 1 character"}
                </span>
              ) : (
                ""
              )
            }
            error={partitionKeyError}
            size="small"
            required
          />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Box sx={{ fontSize: 16 }}>{`Sort Key (Optional)`}</Box>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#006241",
                },
              },
            }}
            inputProps={{
              style: { fontSize: 16, flex: 1 },
            }}
            placeholder={`Sort Key`}
            onChange={handleChange}
            value={table.sortKey}
            name={"sortKey"}
            size="small"
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
        <SbuxButton
          disabled={
            table.tableName.length === 0 || table.partitionKey.length === 0
          }
          type="save"
          height={28}
          onClick={handleSubmit}
        >
          Create
        </SbuxButton>
        <SbuxButton type="cancel" height={28} onClick={handleClear}>
          Clear
        </SbuxButton>
      </Box>
      <Box sx={{ display: "flex", marginTop: 2, marginLeft: 40 }}>
        {openSnackbar && (
          <SbuxSnackbars
            open={openSnackbar}
            anchorOriginVertical="bottom"
            anchorOriginHorizontal="left"
            message={
              statusCode === 200 ? "Success! Your table was created" : message
            }
            width={400}
            severity={statusCode === 200 ? "success" : "error"}
            autoHide={false}
            position="absolute"
            top={400}
            left={8}
            height={10}
            handleClose={handleClose}
          />
        )}
      </Box>
    </>
  );
};

export default AdminIvrTable;
