import React, { useState } from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import QueueDeleDialog from "./components/QueueDeleteDialog";
import useStyles from "../../styles";

const QueueRow = ({ row }) => {
  const classes = useStyles();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleDelete = () => {
    setIsDeleteOpen((prevState) => !prevState);
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["Name"]}
        </SbuxStyledTableCell>

        <SbuxStyledTableCell padding={"0px"}>
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={handleDelete}
            sx={{
              cursor: "pointer",
              color: "red",
              fontSize: 16,
              width: "2vw",
              marginTop: -0.5,
              height: 18,
              "&:focus": {
                outline: 0,
              },
              "&.MuiIconButton-root": {
                padding: 0,
                paddingLeft: "5px",
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>

      {isDeleteOpen && (
        <QueueDeleDialog
          row={row}
          title={<SbuxTranslate>{`Delete Queue`}</SbuxTranslate>}
          name={row.Name}
          open={isDeleteOpen}
          toggleOpen={handleDelete}
        />
      )}
    </>
  );
};

export default QueueRow;
