import { useCallback, useState, useEffect } from "react";
import SbuxGlobalNav from "../components/SbuxGlobalNav";
import SbuxButton from "../components/SbuxButton";
import SbuxHeading from "../components/SbuxHeading";
import SbuxSettings from "../components/SbuxSettings";
import IconButton from "@mui/material/IconButton";
import SbuxSelect from "../components/SbuxSelect";
import SettingsIcon from "@mui/icons-material/Settings";
import { signOut } from "../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedInstance } from "../services/instance";
import { getCcpInitializedStatus, getCcpContactStatus } from "../services/ccp";
import { updateCotactRecordingStateAction } from "../services/screenRecording";
import classNames from "classnames";
import useStyles from "./styles";
import { Paper, Box } from "@mui/material";
import useTextTranslate from "../features/content/hooks/useTextTranslate";
import ScreenRecordingButtons from "../features/content/screenRecording";
import {
  CONTACT_CONNECTED_STATUS,
  VAR_IS_RECORDING,
  CONTACT_RECORDING_START,
  VAR_RECORDING_CONTROLS,
  NAVBAR_AGENT_SET_OFFLINE,
  NAVBAR_SIGNOUT_BTN_CLICKED,
} from "../constants";
import { setAgentStateOffline } from "../utils/agentStateChangeUtil";
import { screenRecordingActions } from "../slices/screenRecordingSlice";

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    auth: { isLoggedIn, cognitoUserId },
    userInstance: { connectedInstance, instances },
    ccp: {
      ccpContactStatus,
      ccpAgent: { queues },
      ccpContact: { contactAttributes, contactData },
    },
    tabs: { tabsStabus },
    screenRecording: {
      contactRecordingState,
      loading: contactRecordingLoadingState,
      error: contactRecordingStateError,
    },
  } = useSelector((state) => state);

  const [openSettings, setOpenSettings] = useState(false);
  const [defaultInstance, setDefaultInstance] = useState(null);
  const [changeInstance, setChangeInstance] = useState(null);
  const instanceUrl = connectedInstance ? connectedInstance.instanceUrl : null;
  const signOutTranslatedText = useTextTranslate("Sign Out");
  const isRecordingControlsEnabled =
    connectedInstance?.appConfig?.isRecordingControlsEnabled ?? false;

  const isStopRecordingFeatureImplementedInAWS =
    connectedInstance?.appConfig?.isStopRecordingFeatureImplementedInAWS ??
    false;

  const areScreenRecordingControlsEnabledForCallType =
    (contactAttributes?.[VAR_RECORDING_CONTROLS]?.value ?? "true") === "true" ??
    true;

  const updateInitialContactRecordingState = (isRecording) => {
    dispatch(
      screenRecordingActions.resetContactRecordingStateAction(
        isRecording ? CONTACT_RECORDING_START : null
      )
    );
  };

  useEffect(() => {
    const isRecording =
      contactAttributes?.[VAR_IS_RECORDING]?.value === "true" ?? false;
    updateInitialContactRecordingState(isRecording);
  }, [contactAttributes]);

  useEffect(() => {
    connectedInstance &&
      connectedInstance.instanceId &&
      setDefaultInstance({
        name: connectedInstance.friendlyName,
        value: connectedInstance.instanceId,
      });
  }, [connectedInstance]);

  const handleSignOut = async (evt) => {
    evt?.preventDefault();
    await setAgentStateOffline(NAVBAR_SIGNOUT_BTN_CLICKED);
    dispatch(
      signOut({
        instanceUrl,
        component: "NavBar",
        userEmail: cognitoUserId,
      })
    );
  };

  const handleSettings = () => {
    !openSettings && setOpenSettings(true);
  };

  const toggleHandleSettings = () => {
    setOpenSettings(!openSettings);
  };

  const menuItems = () => {
    if (!instances || instances.length === 0) {
      return [];
    }
    const sortedInstances = [...instances].sort((a, b) =>
      a.friendlyName.localeCompare(b.friendlyName)
    );
    return sortedInstances.reduce((acc, cur) => {
      acc.push({
        name: cur.friendlyName,
        value: cur.instanceId,
      });
      return acc;
    }, []);
  };

  const auxiliaryContent = () => (
    <>
      <div
        className={classNames("ml-auto flex-shrink-none", classes.navHeading)}
      >
        <SbuxHeading
          tagName="h6"
          className={classNames("mr3", classes.navTitle)}
        >
          {cognitoUserId}
        </SbuxHeading>
        <SbuxButton onClick={handleSignOut} type={"nav"} width={88} height={34}>
          {signOutTranslatedText}
        </SbuxButton>
      </div>
      <div>
        <IconButton
          disableFocusRipple
          disableRipple
          sx={{
            marginLeft: -3.5,
            ":focus": {
              outline: "none !important",
            },
          }}
          disabled={tabsStabus === "disabled" ? true : false}
          onClick={handleSettings}
        >
          <SettingsIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </div>
    </>
  );

  const handleRecordingButtonTrigger = async ({
    action,
    contactId,
    initialContactId,
  }) => {
    await dispatch(
      updateCotactRecordingStateAction({ action, contactId, initialContactId })
    );
  };

  const hideErrorDialog = async () => {
    await new Promise((r) => setTimeout(r, 5000));
    dispatch(screenRecordingActions.resetError());
  };

  return (
    <>
      {defaultInstance && (
        <Paper sx={{ boxShadow: "unset !important" }} elevation={1}>
          <SbuxGlobalNav
            navItems={[
              {
                children: (
                  <Box
                    sx={{
                      display: "flex",
                      minWidth: "20vw",
                      marginTop: -1.4,
                      flexDirection: "row",
                      "@media (min-width: 1025px) and (max-width: 1465px)": {
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "15px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: 1,
                        "@media (max-width: 1024px)": {
                          minWidth: "15vw",
                          marginLeft: 2,
                          fontSize: 14,
                        },
                        "@media (min-width: 1025px) and (max-width: 1465px)": {
                          margin: "4px",
                        },
                        "@media (max-width: 1574px)": {
                          fontSize: 13,
                          marginRight: 0.5,
                        },
                        "@media (min-width: 1245px) and (max-width: 1408px)": {
                          fontSize: 13,
                          marginLeft: 1.5,
                          marginRight: 0.5,
                        },
                        "@media (min-width: 1025px) and (max-width: 1245px)": {
                          fontSize: 13,
                          marginLeft: 0.5,
                          marginRight: 0.5,
                        },
                      }}
                    >{`Connect Desktop`}</Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: -0.4,
                        "@media (max-width: 900px)": {
                          marginRight: 10,
                          flexDirection: "column",
                        },
                        "@media (min-width: 901px) and (max-width: 1408px)": {
                          width: "13vw",
                        },
                      }}
                    >
                      <SbuxSelect
                        width={`${
                          ccpContactStatus === CONTACT_CONNECTED_STATUS &&
                          isRecordingControlsEnabled &&
                          areScreenRecordingControlsEnabledForCallType
                            ? "7vw"
                            : null
                        }`}
                        disabled={
                          tabsStabus === "disabled" ||
                          instances.length === 1 ||
                          (ccpContactStatus === null ||
                          ccpContactStatus === "closed"
                            ? false
                            : true)
                        }
                        menuItems={menuItems()}
                        defaultValue={defaultInstance.value}
                        value={
                          changeInstance
                            ? changeInstance.value
                            : defaultInstance.value
                        }
                        handleSelectChange={async (value) => {
                          await setAgentStateOffline(NAVBAR_AGENT_SET_OFFLINE);
                          const changeInstance = instances.find(
                            (f) => f.instanceId === value
                          );
                          if (changeInstance) {
                            setChangeInstance({
                              name: changeInstance.friendlyName,
                              value: changeInstance.instanceId,
                            });
                            window.connect && window.connect.core.terminate();
                            await dispatch(
                              getConnectedInstance(changeInstance)
                            );
                            await dispatch(getCcpInitializedStatus(false));
                            await dispatch(getCcpContactStatus(null));
                          }
                        }}
                      />
                      {areScreenRecordingControlsEnabledForCallType &&
                        isRecordingControlsEnabled && (
                          <ScreenRecordingButtons
                            handleRecordingButtonTrigger={
                              handleRecordingButtonTrigger
                            }
                            isStopRecordingFeatureImplementedInAWS={
                              isStopRecordingFeatureImplementedInAWS
                            }
                            contactAttributes={contactAttributes}
                            contactData={contactData}
                            ccpContactStatus={ccpContactStatus}
                            contactRecordingState={contactRecordingState}
                            loading={contactRecordingLoadingState}
                            error={contactRecordingStateError}
                            hideErrorDialog={hideErrorDialog}
                          />
                        )}
                    </Box>
                  </Box>
                ),
              },
            ]}
            isUserAuthenticated={isLoggedIn}
            cognitoUserId={cognitoUserId}
            auxiliaryContent={auxiliaryContent}
          />
          {openSettings && (
            <SbuxSettings
              openSettings={openSettings}
              toggleHandleSettings={toggleHandleSettings}
            />
          )}
        </Paper>
      )}
    </>
  );
};

export default Navbar;
