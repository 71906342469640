import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
  },
  headerCellRoot: {
    backgroundImage: "linear-gradient(#006241, #1a7254)",
    color: "#f2f0eb !important",
    fontSize: "15px !important",
    fontWeight: "500 !important",
    lineHeight: "15px !important",
    padding: "6px !important",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  detailRowRoot: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(0,98,68,.05)",
      backgroundImage: "linear-gradient(#e6f0ed, #ebf3f0)",
    },
    "&:nth-of-type(even):hover, &:nth-of-type(odd):hover": {
      backgroundColor: "rgb(204, 204, 204, 0.5)",
      backgroundImage: "linear-gradient(#e0e0e0, #e6e6e6)",
    },
  },
  detailCellRoot: {
    fontSize: "12px !important",
    padding: "6px 0px 6px 6px !important",
  },
}));

export default useStyles;
