import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";

const SbuxStyledTablePagination = styled(TablePagination)(() => ({
  "& .MuiTablePagination-selectLabel": {
    fontSize: 13,
    paddingTop: 1.5,
  },
  "& .MuiTablePagination-select": {
    fontSize: 13,
    paddingTop: 13,
  },
  "& .MuiTablePagination-displayedRows": {
    fontSize: 13,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 21,
  },
  "& .MuiTablePagination-toolbar": {
    padding: 0,
    paddingLeft: 8,
    minHeight: 54,
    paddingTop: 4,
  },
  "&.MuiTablePagination-root": {
    borderBottom: "1px solid #fff !important",
  },
}));

export default SbuxStyledTablePagination;
