import { useState } from "react";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import MainHoursMasterRow from "./MainHoursMasterRow";
import SbuxHeading from "../../../../../components/SbuxHeading";
import SbuxLayer from "../../../../../components/SbuxLayer";
import SbuxDialog from "../../../../../components/SbuxDialog";
import MainHoursAdd from "./MainHoursAdd";
import { masterColumns } from "../mainHours/columns";
import useStyles from "../../styles";

const MainHoursMasterTable = ({ mainHoursData, handleRefrehClick }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleAddHoursDialog = () => {
    setOpen((p) => !p);
  };
  return (
    <>
      <SbuxEnhancedTable
        rows={mainHoursData}
        columns={masterColumns}
        defaultSortOrder={"asc"}
        RowSource={MainHoursMasterRow}
        showFooter
        showSearchBar
        showAddNewButton
        addNewButtonTitle={`Add new hours`}
        handleAddNewButtonClick={handleAddHoursDialog}
        isTranslationRequired={false}
        handleRefreshClick={handleRefrehClick}
        isManager
      />

      {open && (
        <SbuxLayer isOpen={open}>
          <SbuxDialog
            toggleDialogOpen={handleAddHoursDialog}
            isrenderCloseButton={true}
            isClickAwayEnabled={true}
            toggleModalOnModalDialog={() => {
              // handle fn invoked by `pattern-lib->Dialog->onCancel event`
              setOpen(false);
            }}
          >
            <div>
              <SbuxHeading
                tagName="h6"
                size="xs"
                className={classes.dialogTitle}
              >
                {`Details`}
              </SbuxHeading>
              <MainHoursAdd
                toggleMainHoursAddDialogOpen={handleAddHoursDialog}
              />
            </div>
          </SbuxDialog>
        </SbuxLayer>
      )}
    </>
  );
};

export default MainHoursMasterTable;
