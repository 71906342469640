import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (min-width: 1024px)": {
      "&.sb-headerCrate": {
        width: "27.4vw !important",
        overflow: "hidden",
        height: "88vh",
        paddingTop: 0,
        position: "absolute",
      },
    },

    "@media only screen and (max-width: 1023px) and (min-width: 501px)": {
      "&.sb-headerCrate": {
        marginTop: "-6%",
        position: "relative",
      },
    },

    "@media (max-width: 500px)": {
      "&.sb-headerCrate": {
        marginTop: "-10%",
        position: "relative",
      },
    },
    zIndex: "0 !important",
  },
  innerHeader: {
    paddingLeft: "25px !important",
    paddingTop: "4rem",
    paddingRight: "1rem !important",
  },
}));
export default useStyles;
