import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const ClosureMsgWeeklyCheckbox = ({
  handleCheckboxChange,
  weeklyRecDays,
  isEdit = false,
}) => {
  return (
    <>
      {isEdit ? (
        <FormControl>
          <RadioGroup row>
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("MO")}
                  onChange={handleCheckboxChange}
                  name={"MO"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Monday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("TU")}
                  onChange={handleCheckboxChange}
                  name={"TU"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Tuesday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("WE")}
                  onChange={handleCheckboxChange}
                  name={"WE"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Wednesday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("TH")}
                  onChange={handleCheckboxChange}
                  name={"TH"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Thursday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("FR")}
                  onChange={handleCheckboxChange}
                  name={"FR"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Friday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("SA")}
                  onChange={handleCheckboxChange}
                  name={"SA"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Saturday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={
                <Checkbox
                  checked={weeklyRecDays.includes("SU")}
                  onChange={handleCheckboxChange}
                  name={"SU"}
                />
              }
              label={<Box sx={{ fontSize: 14 }}>Sunday</Box>}
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <FormControl>
          <RadioGroup row>
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"MO"} />}
              label={<Box sx={{ fontSize: 14 }}>Monday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"TU"} />}
              label={<Box sx={{ fontSize: 14 }}>Tuesday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"WE"} />}
              label={<Box sx={{ fontSize: 14 }}>Wednesday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"TH"} />}
              label={<Box sx={{ fontSize: 14 }}>Thursday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"FR"} />}
              label={<Box sx={{ fontSize: 14 }}>Friday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"SA"} />}
              label={<Box sx={{ fontSize: 14 }}>Saturday</Box>}
            />
            <FormControlLabel
              sx={{ fontSize: 14 }}
              control={<Checkbox onChange={handleCheckboxChange} name={"SU"} />}
              label={<Box sx={{ fontSize: 14 }}>Sunday</Box>}
            />
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

export default ClosureMsgWeeklyCheckbox;
