import { API } from "aws-amplify";
import * as subscriptions from "./schema/subscriptions";

export const executeQuery = async (query, variables) => {
  return await API.graphql({
    query,
    variables: { ...variables },
  });
};

export const executeMutation = async (mutation, variables) => {
  return await API.graphql({
    query: mutation,
    variables: { ...variables },
  });
};

export const subOnUpdateClosureMessages = (instanceId) => {
  return API.graphql({
    query: subscriptions.onProcessedClosureMessage,
    variables: { instanceId },
  });
};
export const subOnUpdateTemporaryMessages = (instanceId) => {
  return API.graphql({
    query: subscriptions.onProcessedTemporaryMessage,
    variables: { instanceId },
  });
};

export const subOnProcessedMedia = (instanceId) => {
  return API.graphql({
    query: subscriptions.onProcessedMedia,
    variables: { instanceId },
  });
};
