import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SbuxCard from "../../../../../../components/SbuxCard";
import { StyledDataGrid } from "./styled";
import useStyles from "../../../../../../components/SbuxDataGrid/styles";
import { useSelector, useDispatch } from "react-redux";
import { GenesysRecordingsOverlay } from "./GenesysRecordingsOverlay";
import { dateWithFormat } from "../../../../../../utils/dateUtil";
import {
  getGenesysRecordings,
  getResetGenesysRecordings,
} from "../../../../../../services/genesysRecordings";
import SbuxDataFilters from "../../../../../../components/SbuxDataFilters";

const GenesysRecordingsDataGrid = ({ columns, filterList }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = useState({
    isLoading: false,
    page: 0,
    pageSize: 25,
    total: 0,
  });
  const [rows, setRows] = useState([]);
  const [dataFilters, setDataFilters] = useState([]);

  const selectGenesysRecordings = (state) => state.genesysRecordings;
  const { genesysRecordingsData, pageKeyTrail } = useSelector((state) =>
    selectGenesysRecordings(state)
  );

  const {
    userInstance: {
      connectedInstance: {
        appConfig: { genesysConfig },
      },
    },
  } = useSelector((state) => state);

  const transformDataFn = (data) => {
    const dateFormat = "YYYY-MM-DD hh:mm A";
    return (
      data?.map((record, index) => {
        return {
          ...record,
          id: index,
          videoURL: record.videoURL,
          audioURL: record.audioURL,
          startTime: dateWithFormat(record.startTime, dateFormat),
          endTime: dateWithFormat(record.endTime, dateFormat),
        };
      }) || []
    );
  };

  const fetchData = async (pageKey, pageNumber, filters = void 0) => {
    if (!!genesysConfig?.callType) {
      const bodyArgs = {
        callType: genesysConfig?.callType,
        limit: paginationModel.pageSize,
        pageNumber: `${pageNumber}`,
        ...pageKey,
      };

      if (Array.isArray(filters) && filters.length > 0) {
        bodyArgs.filter = [...filters];
      }

      await dispatch(getGenesysRecordings(bodyArgs));
    }
  };

  useEffect(() => {
    const data = transformDataFn(genesysRecordingsData.data);
    setRows(data);

    // Calc to update the grid to whether enable "Next" button
    const { pageSize } = paginationModel;
    const { pageNumber = 0 } = genesysRecordingsData;
    const page = Number(pageNumber);

    const total = calcTotal(
      data.length,
      pageSize,
      page,
      !!genesysRecordingsData.currentEvaluatedKey
    );

    setPaginationModel((oldPage) => ({
      ...oldPage,
      isLoading: false,
      page,
      total,
    }));
  }, [genesysRecordingsData.data]);

  useEffect(() => {
    (async () => {
      // Clear up state storage
      await dispatch(getResetGenesysRecordings());

      if (Array.isArray(dataFilters) && dataFilters.length > 0) {
        // call the backend
        await fetchData({}, 0, dataFilters);
      }
    })();
  }, [genesysConfig?.callType]);

  /**
   * Calculate the total number of rows at the moment. Since pagination behavior depends on the
   * number of rows, an offset value is included in the total number of rows to let know the
   * component that we haven't reached the end of the dataset and there is more to fetch.
   * @param  {number}  dataLength                size of the last dataset
   * @param  {number}  pageSize                  size of the page
   * @param  {number}  page                      current page number
   * @param  {Boolean} hasNextPage               whether there is a next page to fetch
   * @return {number}              total number of rows
   */
  const calcTotal = (dataLength, pageSize, page, hasNextPage) => {
    // Calc to update the grid to whether enable "Next" button

    const offSet = hasNextPage ? 1 : 0;

    let total = 0;
    if (dataLength === pageSize) {
      total = pageSize * (page + 1);
    } else {
      total = pageSize * page + dataLength;
    }

    return offSet * pageSize + total;
  };

  /**
   * Handle page change event
   * @param  {number}  pageNumber     based on direction, it is the next or previous page number
   */
  const handleOnPageChange = async (pageNumber) => {
    const { page: currentPage } = paginationModel;

    const pageKey = pageKeyTrail[pageNumber];

    // Prevent move fwd when no nextKey available
    if (pageNumber > currentPage && !pageKey) {
      return;
    }

    setPaginationModel((oldPage) => ({ ...oldPage, isLoading: true }));

    await fetchData(pageKey, pageNumber, dataFilters);
  };

  /**
   * Handle click event to apply filter selection
   * @param  {Array}  filterSpec       list of filter descriptions
   */
  const handleApplyFilters = async (filterSpec) => {
    // Prep Date values in ISO format
    const filters = filterSpec.map((filter) => {
      const { value } = filter;
      if (typeof value.toISOString === "function") {
        return { ...filter, value: value.toISOString() };
      }

      return filter;
    });

    setDataFilters(filters);
    setPaginationModel((oldPage) => ({
      ...oldPage,
      isLoading: true,
    }));
    // Clear up state storage
    await dispatch(getResetGenesysRecordings());

    // call the backend
    await fetchData({}, 0, filters);
  };

  return (
    <SbuxCard className="insideTabContainer">
      <SbuxDataFilters
        config={{ items: filterList }}
        onApplyFilters={handleApplyFilters}
      />

      <StyledDataGrid
        autoHeight
        rows={rows}
        columns={columns}
        componentsProps={{
          pagination: {
            labelDisplayedRows: () =>
              `${paginationModel.pageSize} rows per page`,
          },
        }}
        components={{
          NoRowsOverlay: GenesysRecordingsOverlay,
        }}
        pagination
        rowCount={paginationModel.total}
        loading={paginationModel.isLoading}
        rowsPerPageOptions={[25]}
        page={paginationModel.page}
        pageSize={paginationModel.pageSize}
        paginationMode="server"
        isRowSelectable={false}
        onPageChange={handleOnPageChange}
        onPageSizeChange={(newPageSize) =>
          setPaginationModel((oldPageSize) => ({
            ...oldPageSize,
            pageSize: newPageSize,
          }))
        }
        disableSelectionOnClick
      />
    </SbuxCard>
  );
};

export default GenesysRecordingsDataGrid;
