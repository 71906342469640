export const AUTH_HEADER_NAME = "Authorization";
export const MISSED_CALL_STATUS = "MISSED";
export const REJECTED_CALL_STATUS = "DECLINED";
export const ACCEPTED_CALL_STATUS = "INBOUND";
export const OUTBOUND_CALL_STATUS = "OUTBOUND";
export const CALLBACK_CALL_STATUS = "CALLBACK";
export const DURATION_FOR_MISSED_CALL = 20; // static and cannot be changed in Amazon Connect
export const DURATION_ELEMENT_NAME = "contactDuration";

export const TICKET_OR_CASE_NUMBER = "Ticket or Case #";

export const TAB_LABEL_CURRENT = "Current";
export const TAB_LABEL_HISTORY = "History";
export const TAB_LABEL_QUICK_CONNECT = "Quick Connects";
export const TAB_LABEL_METRICS = "Metrics";
export const TAB_LABEL_RESOURCES = "Resources";

export const TABLE_DEFAULT_PAGE_SIZE = 15;
export const DATAGRID_DEFAULT_PAGE_SIZE = 15;
export const DATAGRID_ROWS_PER_PAGE = [25, 50, 100];

export const CONTACT_ATTRIBUTE_LABEL_TIME_STAMP = "Time Stamp";

export const CONTACT_CONNECTING_STATUS = "connecting";
export const CONTACT_INCOMING_STATUS = "incoming";
export const CONTACT_CONNECTED_STATUS = "connected";
export const CONTACT_MISSED_STATUS = "missed";
export const CONTACT_ENDED_STATUS = "ended";
export const CONTACT_CLOSED_STATUS = "closed";

export const CALL_METHOD_CALLBACK = "CALLBACK";
export const CALL_METHOD_INBOUND = "INBOUND";
export const CALL_METHOD_OUTBOUND = "OUTBOUND";
export const CALL_METHOD_MISSED = "MISSED";
export const CALL_METHOD_DECLINED = "DECLINED";
export const CALL_METHOD_ENDED = "ENDED";
export const CALL_METHOD_NONE = "NONE";

export const OVERLAY_BUTTON_REQUIRED_MESSAGE = "Ticket # required to end call";
export const INVALID_TICKET_OR_CASE_NUMBER = "Invalid Ticket Or Case Number";

export const CURRENT_TICKET_OR_CASE_NUMBER = "Ticket or Case #";
export const TICKET_OR_CASE_NUMBER_NEW = "New";
export const CURRENT_VAR_TICKET_OR_CASE_NUMBER = "var_ticketorcase";
export const VAR_IS_RECORDING = "var_recordingenabled";
export const VAR_INITIAL_CONTACT_ID = "var_initialcontactID";
export const VAR_CONTACT_ID = "var_contactID";
export const VAR_RECORDING_CONTROLS = "var_recordingcontrols";

export const COLOR_GREEN_ACCENT = "#006241"; //--colorGreenStarbucks
export const COLOR_NEUTURAL_WARM = "#f2f0eb"; //--colorNeutralWarm
export const COLOR_LIGHT_GREEN = "#d4e9e2"; //--colorGreenLight
export const COLOR_LIGHT_GREEN_SECONDARY = "#00a862"; //--colorGreenLightSecon
export const DARK_GRAY = "rgb(74, 74, 74)";
export const DARK_GRAY_HOVER = "#cccccc80";
export const COLOR_RED = "#d23e3e";
export const COLOR_DARK_RED = "#d62b1f";
export const COLOR_LIGHT_RED = "#fff0f0";
export const COLOR_GOLD = "#cba357";
export const COLOR_DARK_BLACK = "#0000001a";
export const COLOR_LIGHT_BLACK = "#000000de";
export const SYSTEM_IDLE_WARNING_TIME = 120;

export const RESOURCES_SORT_FIELD_UIPOSITION = "uiPosition";

export const CONTACT_NEW_ATTRIBUTE_ORDER_ID = 7;
export const CONTACT_HIDE_ATTRIBUTES = [
  "id",
  "userid",
  "timestamp",
  "AllowAuxTransfer",
  "Time Stamp (PST)",
];
export const CONTACT_ATTRIBUTES_ORDER_TEMPLATE = [
  { id: 0, name: "Time", value: null },
  { id: 1, name: "Method", value: null },
  { id: 2, name: "Queue", value: null },
  { id: 3, name: "Caller ID", value: null },
  { id: 4, name: "Dialed #", value: null },
  { id: 5, name: "Dialed From", value: null },
  { id: 6, name: "Duration", value: null },
  { id: 50, name: "Callback #", value: null },
  { id: 51, name: "Another attr", value: null },
  { id: 52, name: "Coffee", value: null },
  { id: 53, name: "Doodle", value: null },
  { id: 54, name: "Test", value: null },
  { id: 97, name: "Ticket or Case #", value: null },
  { id: 98, name: "ContactID", value: null }, //PR: This property for current tab
  { id: 99, name: "Contact ID", value: null }, //PR: This property for history detail table
];
export const SNACK_BARS_SEVERITY_WARNING = "warning";
export const SNACK_BARS_SEVERITY_ERROR = "error";
export const SNACK_BARS_SEVERITY_INFO = "info";
export const SNACK_BARS_SEVERITY_SUCCESS = "success";
export const HISTORY_NOT_A_VALID_PHONE_NUMBER =
  "We are unable to complete the call as dialed. Try again, or contact your administrator.";

export const FILTER_DEFAULT_VALUE = "all";

export const DOCUMENT_TITLE_ORIGINAL = `Connect Desktop ${process.env.REACT_APP_ENV_NAME}`;
export const DOCUMENT_TITLE_IDLE_TIME = "SESSION IDLE TIMEOUT";

export const COPIED_TO_CLIPBOARD = "Copied to clibboard";

export const QUEUE_METRICS_CALL_WAITING_WARNING_COUNT = 1;
export const QUEUE_METRICS_CALL_WAITING_THRSHOLD_COUNT = 2;

export const SETTINGS_TITLE = "Settings";
export const SETTINGS_TAB_ORDER_TITLE = "Tab Orders";
export const SETTINGS_TAB_ORDER_SUB_TITLE =
  "(Re-arrange the tab name by dragging and dropping)";
export const SETTINGS_DEFAULT_TAB_ORDER_LIST = [
  { tabId: "tab1", label: TAB_LABEL_CURRENT },
  { tabId: "tab2", label: TAB_LABEL_QUICK_CONNECT },
  { tabId: "tab3", label: TAB_LABEL_METRICS },
  { tabId: "tab4", label: TAB_LABEL_HISTORY },
  { tabId: "tab5", label: TAB_LABEL_RESOURCES },
];

export const CURRENT_TAB_NO_ROWS_OVERLAY = `Information will populate on an active contact`;

export const IDLE_TIME_MODAL_TITLE = "Are you still there?";
export const WINDOW_NOTIFICATION_TITLE = "Connect Desktop - Session Timeout";
export const WINDOW_NOTIFICATION_BODY =
  "The system will attempt to sign you out in";
export const IDLE_TIME_MODAL_SUB_TITLE =
  "Press any key or move your mouse in the browser window to stay signed in. Otherwise, the system will attempt to sign you out in";

export const PLEASE_WAIT = "Please wait...";

export const ADMIN_HOURS_SAVED_SUCCESSFULLY =
  "Admin hours data saved successfully!";

export const ADMIN_HOURS_ERROR_MESSAGE =
  "Oops! Something went wrong. Please try again later";

export const ADMIN_QUEUE_DELETED_SUCCESSFULLY =
  "Admin queue deleted successfully!";

export const MM_FILE_UPLOAD_OPTIONS_LIST = [
  { name: "Upload", value: 1, disabled: false, toolTipMsg: "" },
  { name: "Record", value: 2, disabled: false, toolTipMsg: "" },
  {
    name: "Text-to-Speech",
    value: 3,
    disabled: false,
    toolTipMsg: "",
    minWidth: 120,
  },
];

export const MM_DEFAULT_LANGUAGES = [{ name: "English", value: "en" }];

export const MEDIAMGT_SAVED_SUCCESSFULLY =
  "Media management data saved successfully!";

export const MEDIAMGT_DELETED_SUCCESSFULLY =
  "Media management data deleted successfully!";

export const MEDIAMGT_REFRESH_USER_MESSAGE =
  "Media management data has been updated by another user!";

export const OOPS_ERROR_MESSAGE =
  "Oops! Something went wrong. Please try again later";

export const MEDIAMGT_FILE_TYPE_ERROR = "You can only upload a WAV file!";
export const MEDIAMGT_INVALID_FILE_ERROR = "Uploaded .wav file is corrupt";

export const MEDIAMGT_FILE_NAME_ERROR =
  "File name already exist. Please try different file name";

export const CLOSUREMSG_SAVED_SUCCESSFULLY =
  "Closure message data saved successfully!";

export const CLOSUREMSG_DELETED_SUCCESSFULLY =
  "Closure message data deleted successfully!";

export const TEMPORARYMSG_SAVED_SUCCESSFULLY =
  "Temporary message data saved successfully!";

export const DATA_MANAGEMENT_SAVED_SUCCESSFULLY =
  "Data management data saved successfully!";

export const MEDIAMGT_ERROR_MESSAGE =
  "Oops! Something went wrong. Please try again later";

export const DATA_MANAGEMENT_DELETED_SUCCESSFULLY =
  "Data management data deleted successfully!";

export const AUDIO_RECORDING_INPROGRESS_TEXT = " in progress:";

export const CM_SERIES_OPTIONS_LIST = [
  { name: "Single", value: "Single" },
  { name: "Repeat", value: "Repeat" },
];

export const CM_CATEGORY_LIST = [
  { name: "Holiday", value: "Holiday" },
  { name: "Meeting", value: "Meeting" },
];

export const CM_RECURRENCE_FREQUENCY_LIST = [
  { name: "Daily", value: "DAILY" },
  { name: "Weekly", value: "WEEKLY" },
  { name: "Monthly", value: "MONTHLY" },
  { name: "Yearly", value: "YEARLY" },
];

export const CM_RECURRENCE_WEEKDAY_LIST = [
  { name: "Sunday", value: "SU" },
  { name: "Monday", value: "MO" },
  { name: "Tuesday", value: "TU" },
  { name: "Wednesday", value: "WE" },
  { name: "Thursday", value: "TH" },
  { name: "Friday", value: "FR" },
  { name: "Saturday", value: "SA" },
];

export const CM_RECURRENCE_YEARLY_LIST = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "Otober", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

export const CM_RECURRENCE_MONTHLY_LIST = [
  { name: "First", value: 1 },
  { name: "Second", value: 2 },
  { name: "Third", value: 3 },
  { name: "Fourth", value: 4 },
  { name: "Last", value: -1 },
];

export const CM_TIMEZONE_LIST = [
  { name: "Pacific Time (US & Canada)", value: "America/Los_Angeles" },
  { name: "Eastern Time (US & Canada)", value: "America/New_York" },
];

export const CONTACT_RECORDING_START = "start";
export const CONTACT_RECORDING_PAUSE = "pause";
export const CONTACT_RECORDING_RESUME = "resume";
export const CONTACT_RECORDING_STOP = "stop";

export const DATE_TIME_BASED_ON_TIMEZONE =
  "Estimated current date and time based on selected timezone:";

export const QUICKCONNTECTS_DEFAULT_FILTER_ITEMS = [
  "all",
  "phone_number",
  "agent",
  "queue",
];

export const MEDIAMSG_DEFAULT_FILTER_ITEMS = ["all", "Custom", "System"];

export const CLOSUREMSG_DEFAULT_FILTER_ITEMS = [
  "Emergency",
  "Holiday",
  "Maintenance",
  "Meeting",
  "Other",
  "Single",
  "Repeat",
  "Active",
  "Scheduled",
];

export const TEMPEMSG_DEFAULT_FILTER_ITEMS = [
  "all",
  "Draft",
  "Active",
  "Scheduled",
  "Disabled",
  "Expired",
];

export const DEFAULT_SCREEN_RECORDING_API_ERROR_MESSAGE =
  "Screen recording controls failed";

export const METRICS_SUB_TABS_LIST = [
  {
    name: "Queue Metrics",
    value: "queueMetrics",
  },
  {
    name: "My Metrics",
    value: "myMetrics",
  },
];

export const BUTTON_TYPE_SAVE = "save";
export const BUTTON_TYPE_EDIT = "edit";
export const BUTTON_TYPE_DELETE = "delete";
export const BUTTON_TYPE_CANCEL = "cancel";
export const BUTTON_TYPE_NAV = "nav";

export const DATE_VALIDATION_ERROR_MSG = "Please enter a valid date";

export const USER_SELECTED_TIMEZONE = "Your configured time zone is: ";
export const USER_DEFAULT_TIMEZONE = "America/Los_Angeles";
export const TRANSCRIPTS_COLUMN_NAME = "transcripts";
export const VAR_TRANSCRIPTS_ENABLED = "var_transcriptsenabled";
export const COLORS = {
  PRIMARY: "primary",
  DANGER: "danger",
};

export const TRANSCRIPT_PARTICIPANTS = {
  AGENT: "AGENT\t",
  CUSTOMER: "CUSTOMER",
};

export const MEDIA_FILE_DOES_NOT_EXISTS = "Media file doesn't exist";
export const TRANSCRIPTS_FEATURE_FLAG_NAME = "Enable Transcripts";
export const DAYS_TO_ADD = 1;

export const LOADING_DATA = "Loading data";
export const ROUTING_PROFILE_DELETION_SUCCESSFUL =
  "Routing profile deleted successfully";
export const NAVBAR_AGENT_SET_OFFLINE = "Navbar - set agent state offline";
export const NAVBAR_SIGNOUT_BTN_CLICKED = "Navbar signout button clicked";

export const GENESYS_RECORDING_FLAG = "Enable/Disable Genesys Recordings";

export const STOPCONTACTCALLBACK_RECORDING_FLAG =
  "Enable/Disable Contact Callbacks";

export const AUDIO_FILE = {
  CALL_ENDED: "/CallEnded.wav",
  BEEP: "/Beep.wav",
};

export const COLUMN_ID_SNO = "sno";

export const CALLBACK_DELETED_SUCCESSFULLY =
  "Callback has been deleted successfully!";
