import React, { useState } from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxTooltip from "../../../../../components/SbuxTooltip";
import { ellipse, getTitleForEllipse } from "../../../../../utils/stringUtil";
import { getLastUpdated } from "../../../utils";
import moment from "moment";
import useStyles from "../../styles";

const TemporaryMsgRow = ({ row, handleEditRowClick }) => {
  const classes = useStyles({ isHighlighted: row.highLight });

  const handleEditClick = () => {
    handleEditRowClick(row);
  };
  return (
    <>
      <SbuxStyledTableRow
        className={classes.expandablerow}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["sequenceNumber"]}
        </SbuxStyledTableCell>
        <SbuxTooltip
          title={getTitleForEllipse(row.description)}
          placement="right"
        >
          <SbuxStyledTableCell
            className={classes.cellFocus}
            onClick={handleEditClick}
          >
            {ellipse(
              row["description"],
              process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS
            )}
          </SbuxStyledTableCell>
        </SbuxTooltip>

        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["startDate"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["startTime"] &&
            moment(row["startTime"], "h:mm aa").format("h:mm a")}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["endDate"]
            ? row["endDate"]
            : row.status !== "Draft" && "No end date"}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["endTime"]
            ? moment(row["endTime"], "h:mm aa").format("h:mm a")
            : row.status !== "Draft" && "No end time"}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["status"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {getLastUpdated(row)}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default TemporaryMsgRow;
