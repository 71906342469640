import { useState } from "react";
import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxSnackbars from "../../../../components/SbuxSnackbars";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import TemporaryMsgAdd from "../table/temporaryMsg/TemporaryMsgAdd";
import TemporaryMsgEdit from "../table/temporaryMsg/TemporaryMsgEdit";
import TemporaryMsgDisableDialog from "./TemporaryMsgDisableDialog";
import { createOrUpdateTemporaryMsg } from "../../../../services/temporaryMsg";
import { Divider, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { SNACK_BARS_SEVERITY_ERROR } from "../../../../constants";
import moment from "moment";
import { isStartAndEndDateTimeValid } from "../utils";
import useStyles from "../styles";

const TemporaryMsgDialog = ({
  title,
  open,
  row,
  toggleDialogOpen,
  business,
  languages,
  sequenceNumber,
  isAddNew,
  globalConfigUi,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [temporaryMsgRow, setTemporaryMsgRow] = useState(row);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [updatedRow, setUpdatedRow] = useState(row);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [isAudioChanged, setIsAudioChanged] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleDisableOnClick = () => {
    setIsDisable((prevState) => !prevState);
  };

  const handleSave = async () => {
    let result = null;
    if (isAddNew) {
      result = {
        description: updatedRow.description,
        info: updatedRow.info,
      };
    } else {
      const endDateTimeZone =
        updatedRow.endTimeOption !== "immediately"
          ? {
              endDate: moment(updatedRow.endDate).format("YYYY-MM-DD"),
              endTime: updatedRow.endTime,
              endTimeZone: updatedRow.endTimeZone,
            }
          : {};
      const existingLanguages =
        Object.keys(updatedRow).length > 0 &&
        Object.keys(updatedRow)
          .filter((a) => a.includes("message"))
          .reduce((acc, cur) => ((acc[cur] = updatedRow[cur]), acc), {});
      result = {
        sequenceNumber: updatedRow.sequenceNumber,
        description: updatedRow.description,
        draft: false,
        disabled: false,
        info: updatedRow.info,
        startDate:
          updatedRow.startTimeOption === "immediately"
            ? moment().format("YYYY-MM-DD")
            : moment(updatedRow.startDate).format("YYYY-MM-DD"),

        startTime:
          updatedRow.startTimeOption === "immediately"
            ? moment().format("hh:mm A")
            : updatedRow.startTime,
        startTimeZone:
          updatedRow.startTimeOption === "immediately"
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : updatedRow.startTimeZone,
        ...endDateTimeZone,
        type: updatedRow.type,
        ...existingLanguages,
        ...updatedRow.languageMessage,
      };
    }
    dispatch(createOrUpdateTemporaryMsg(result));
    toggleDialogOpen();
  };

  const handleTemporaryMsgRow = (
    updatedRow,
    isAudioChange = false,
    dateChanged = false
  ) => {
    if (dateChanged) {
      setIsDateChanged(true);
    }
    if (isAudioChange) {
      setIsAudioChanged(true);
    }
    if (updatedRow) {
      setUpdatedRow(updatedRow);
      const languages =
        Object.keys(updatedRow).length > 0 &&
        Object.keys(updatedRow).find((a) => a.includes("message"));

      let hasLanguageMessage = languages ? true : false;

      const isDateTimeValid = isStartAndEndDateTimeValid(
        updatedRow.startDate,
        updatedRow.endDate,
        updatedRow.startTime,
        updatedRow.endTime,
        updatedRow.startTimeOption,
        updatedRow.endTimeOption,
        updatedRow.startTimeZone,
        true
      );

      const hasDescription = Boolean(updatedRow.description);
      const hasChangedDate = isDateChanged || dateChanged;
      const hasChangedAudio = isAudioChanged || isAudioChange;
      const isFormInvalid =
        !isAddNew &&
        (!hasLanguageMessage ||
          !hasDescription ||
          (hasChangedDate && !isDateTimeValid));
      const shouldEnableButton =
        !isAddNew &&
        hasDescription &&
        ((hasChangedDate && isDateTimeValid) || !hasChangedDate) &&
        hasLanguageMessage &&
        (hasChangedAudio || (hasChangedDate && !hasChangedAudio));
      let disableBtn = !shouldEnableButton;

      switch (updatedRow.status) {
        case "Draft":
          setButtonDisabled(disableBtn);
          break;
        case "Active":
          setButtonDisabled(disableBtn);
          break;
        case "Expired":
          setButtonDisabled(disableBtn);
          break;
        case "Scheduled":
        case "Disabled":
          if (!isLoadingFirstTime) {
            setButtonDisabled(disableBtn);
          }
          setIsLoadingFirstTime(false);
          break;
        default:
          if (isAddNew && !updatedRow.description) {
            setButtonDisabled(true);
          } else {
            setButtonDisabled(false);
          }
          break;
      }
    }
  };

  return (
    <SbuxLayer isOpen={open}>
      <SbuxDialog
        toggleDialogOpen={toggleDialogOpen}
        isrenderCloseButton={true}
        isClickAwayEnabled={true}
        renderButtons={false}
      >
        <div className={classes.dialogContentContainer}>
          <div className={classes.dialogContent}>
            <div className={classes.rowContainer}>
              <SbuxHeading
                tagName="h6"
                size="xs"
                className={classes.dialogTitle}
              >
                {title}
              </SbuxHeading>
              <span className="text-xs text-bold mb3">
                {" "}
                {temporaryMsgRow.status}
              </span>
            </div>

            <Divider sx={{ paddingTop: 0.5 }} />
            {isAddNew ? (
              <TemporaryMsgAdd
                business={business}
                languages={languages}
                row={temporaryMsgRow}
                sequenceNumber={sequenceNumber}
                handleTemporaryMsgRow={handleTemporaryMsgRow}
                globalConfigUi={globalConfigUi}
              />
            ) : (
              <TemporaryMsgEdit
                row={temporaryMsgRow}
                handleTemporaryMsgRow={handleTemporaryMsgRow}
                globalConfigUi={globalConfigUi}
              />
            )}
          </div>
          <Box className={classes.dialogButtons} sx={{ pt: 2, mr: 1 }}>
            {!isAddNew && (
              <SbuxButton
                onClick={handleDisableOnClick}
                type="cancel"
                disabled={
                  temporaryMsgRow.status === "Disabled" ||
                  temporaryMsgRow.status === "Draft" ||
                  temporaryMsgRow.status === "Expired"
                }
              >
                <SbuxTranslate>{"Disable"}</SbuxTranslate>
              </SbuxButton>
            )}
            <SbuxButton
              onClick={handleSave}
              type="save"
              disabled={buttonDisabled}
            >
              <SbuxTranslate>{"Save"}</SbuxTranslate>
            </SbuxButton>
            <SbuxButton onClick={toggleDialogOpen} type="cancel">
              <SbuxTranslate>{"Cancel"}</SbuxTranslate>
            </SbuxButton>
          </Box>
        </div>
      </SbuxDialog>
      {openSnackBar && (
        <SbuxSnackbars
          width={380}
          open={openSnackBar}
          anchorOriginHorizontal={`center`}
          message={""}
          severity={SNACK_BARS_SEVERITY_ERROR}
          handleClose={handleClose}
        />
      )}

      {isDisable && (
        <TemporaryMsgDisableDialog
          title={`Disable Temporary Message`}
          name={row.description}
          open={isDisable}
          toggleDialogOpen={toggleDialogOpen}
          toggleCancelDialog={handleDisableOnClick}
          row={row}
        />
      )}
    </SbuxLayer>
  );
};

export default TemporaryMsgDialog;
