import * as React from "react";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const SbuxRadioGroup = ({
  row = false,
  value = null,
  isCheckBox = false,
  radioButtonList = null,
  handleRadioButtonChange,
  children = null,
}) => {
  return (
    <FormControl>
      <RadioGroup row value={value} onChange={handleRadioButtonChange}>
        {children
          ? children
          : radioButtonList &&
            radioButtonList.length > 0 &&
            radioButtonList.map((item, index) => (
              <FormControlLabel
                sx={{ fontSize: 14 }}
                key={index}
                value={item.value}
                control={isCheckBox ? <Checkbox /> : <Radio />}
                label={<Box sx={{ fontSize: 14 }}>{item.name}</Box>}
              />
            ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SbuxRadioGroup;
