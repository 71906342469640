import DatePicker from "react-datepicker";
import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import { Box, FormControlLabel, Radio } from "@mui/material";
import moment from "moment";
import useStyles from "../../../styles";
import { DAYS_TO_ADD } from "../../../../../../constants";

const ClosureMsgRangeRecurrence = ({
  endByOption,
  endByEndDate,
  handleEndByEndDateChange,
  handleEndByOptionsValueChange,
  isAdd = false,
}) => {
  const classes = useStyles();
  return (
    <SbuxRadioGroup
      value={endByOption}
      handleRadioButtonChange={handleEndByOptionsValueChange}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 2 }}>
          <FormControlLabel
            value={"endByEndDate"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  pointerEvents: endByOption !== "endByEndDate" && "none",
                  opacity: endByOption !== "endByEndDate" && 0.6,
                }}
              >
                <Box
                  sx={{
                    marginRight: 1,
                    fontSize: 13,
                    marginTop: 0.5,
                  }}
                >
                  {"Date"}
                </Box>
                <Box
                  sx={{
                    marginRight: 1,
                    fontSize: 13,
                    flex: 1,
                  }}
                >
                  <DatePicker
                    className={classes.recDatePicker}
                    selected={endByEndDate}
                    minDate={moment().add(DAYS_TO_ADD, "days").toDate()}
                    onChange={(date) => handleEndByEndDateChange(date)}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 3 }}>
          <FormControlLabel
            value={"endByNoDate"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  fontSize: 13,
                  flex: 1,
                  pointerEvents: endByOption !== "endByNoDate" && "none",
                  opacity: endByOption !== "endByNoDate" && 0.6,
                }}
              >
                <Box
                  sx={{
                    fontSize: 13,
                    marginTop: 0.5,
                    flex: 1,
                  }}
                >
                  {"No end date"}
                </Box>
              </Box>
            }
          />
        </Box>
        {isAdd === true && (
          <Box
            sx={{
              color: "#006241",
              fontSize: 12,
              fontWeight: 600,
              marginLeft: -1,
              marginTop: 1.3,
              width: "18vw",
            }}
          >
            {"Date should be in the future"}
          </Box>
        )}
      </Box>
    </SbuxRadioGroup>
  );
};

export default ClosureMsgRangeRecurrence;
