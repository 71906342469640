import React, { useState } from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxTooltip from "../../../../../components/SbuxTooltip";
import { Box, IconButton } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Tooltip from "@mui/material/Tooltip";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { ellipse, getTitleForEllipse } from "../../../../../utils/stringUtil";
import { getLastUpdated } from "../../../utils";
import moment from "moment";
import useStyles from "../../styles";

const ClosureMsgRow = ({ row, handleEditRowClick }) => {
  const classes = useStyles({ isHighlighted: row.highLight });

  const getSingleInstance = () => {
    return row.type !== "Single" ? (
      <IconButton
        disableFocusRipple
        disableRipple
        sx={{
          cursor: "pointer",
          fontSize: 13,
          height: 12,
          color: "inherit",
          "&:focus": {
            outline: 0,
          },
          "&.MuiIconButton-root": {
            padding: 0,
          },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1, marginRight: 1 }}>{row.category}</Box>
          <Box sx={{ flex: 1 }}>
            <CalendarTodayIcon />
          </Box>
        </Box>
      </IconButton>
    ) : (
      <Box sx={{ fontSize: 13 }}>{row.category}</Box>
    );
  };

  const handleEditClick = () => {
    handleEditRowClick(row);
  };
  return (
    <>
      <SbuxStyledTableRow
        className={classes.expandablerow}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["groupName"]}
        </SbuxStyledTableCell>
        <SbuxTooltip
          title={getTitleForEllipse(row.description)}
          offset={[-10, 0]}
          placement="right-end"
        >
          <SbuxStyledTableCell
            className={classes.cellFocus}
            onClick={handleEditClick}
          >
            {ellipse(
              row["description"],
              process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS
            )}
          </SbuxStyledTableCell>
        </SbuxTooltip>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {getSingleInstance()}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["startDate"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {moment(row["startTime"], "h:mm aa").format("h:mm a")}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["endDate"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {moment(row["endTime"], "h:mm aa").format("h:mm a")}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {row["status"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell
          className={classes.cellFocus}
          onClick={handleEditClick}
        >
          {getLastUpdated(row)}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default ClosureMsgRow;
