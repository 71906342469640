import { createSlice, current } from "@reduxjs/toolkit";
import {
  getSettings,
  createOrUpdateSettings,
  getReOrderedTablist,
  getLocale,
  createOrUpdateLocaleSettings,
  getCCPLocaleStatus,
  getSelectedTimezone,
  getUpdatedTimezone,
} from "../services/settings";
import defaultSettingsConfig from "../components/SbuxSettings/config/default.json";

const initialState = {
  settingsStatus: "idle",
  user: {},
  reOrderedTablist: [],
  translatedText: [],
  translatedStatus: "idle",
  ccpLocaleStatus: "idle",
  locale: "en",
  selectedTimezone: "",
  timezone: "",
};

const getTabs = (payload) => {
  const tabsOrderApi = payload.config.hasOwnProperty("tabOrder")
    ? payload.config.tabOrder
    : null;
  const defaultTabs = defaultSettingsConfig.config.tabOrder.reduce(
    (acc, cur, index) => {
      acc.push({
        tabId:
          tabsOrderApi && tabsOrderApi[index]
            ? tabsOrderApi[index].tabId
            : cur.tabId,
        label:
          tabsOrderApi && tabsOrderApi[index]
            ? tabsOrderApi[index].label
            : cur.label,
        visible:
          tabsOrderApi && tabsOrderApi[index]
            ? tabsOrderApi[index].visible
            : cur.visible,
      });
      return acc;
    },
    []
  );
  const result = defaultTabs.reduce((acc, cur) => {
    acc.push({
      ...cur,
      visible:
        cur.label === "Admin"
          ? payload.admin
          : cur.label === "Manager"
          ? payload.admin
            ? true
            : payload.managerOrSupervisor
          : true,
    });
    return acc;
  }, []);
  return result;
};
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.settingsStatus = "pending";
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        state.settingsStatus = "success";
        state.user = data;
        state.locale = data.config.languageCode;
        state.timezone = data?.config?.timezone
          ? data.config.timezone
          : defaultSettingsConfig.config.timezone;
        state.reOrderedTablist = getTabs(data);
      })
      .addCase(getSettings.rejected, (state) => {
        state.settingsStatus = "rejected";
        state.user = {};
        state.reOrderedTablist = [];
      })
      .addCase(createOrUpdateSettings.pending, (state) => {
        state.settingsStatus = "pending";
      })
      .addCase(createOrUpdateSettings.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        state.settingsStatus = "success";
        state.user = data;
        state.reOrderedTablist = getTabs(data);
      })
      .addCase(createOrUpdateSettings.rejected, (state) => {
        state.settingsStatus = "rejected";
        state.user = null;
      })
      .addCase(getReOrderedTablist, (state, action) => {
        const { payload } = action;
        const { user } = current(state);
        state.settingsStatus = "reordered";
        const data = payload === "cancel" ? getTabs(user) : payload;
        state.reOrderedTablist = data;
      })
      .addCase(getLocale, (state, action) => {
        const { payload } = action;
        state.locale = payload;
      })
      //To avoid immediate update from timezone dropdown to the footer
      .addCase(getSelectedTimezone, (state, action) => {
        const { payload } = action;
        state.selectedTimezone = payload;
      })
      .addCase(getUpdatedTimezone, (state, action) => {
        const { payload } = action;
        state.timezone = payload;
      })
      .addCase(createOrUpdateLocaleSettings.pending, (state) => {
        state.translatedStatus = "pending";
      })
      .addCase(createOrUpdateLocaleSettings.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        const { locale } = current(state);
        state.translatedStatus = "success";
        state.translatedText = data.translations;
        state.locale = data.hasOwnProperty("languageCode")
          ? data.languageCode
          : locale;
        state.ccpLocaleStatus = "updated";
      })
      .addCase(createOrUpdateLocaleSettings.rejected, (state) => {
        state.translatedStatus = "rejected";
        state.user = {};
        state.translatedText = [];
        state.reOrderedTablist = [];
        state.ccpLocaleStatus = "idle";
      })
      .addCase(getCCPLocaleStatus, (state) => {
        state.ccpLocaleStatus = "idle";
      });
  },
});

export default settingsSlice.reducer;
