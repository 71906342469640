import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "12%",
    padding: "2%",
  },
  img: {
    textAlign: "center",
  },

  headingPrimary: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: "24px !important",
    color: "red",
    fontWeight: 600,
  },
  headingSecondary: {
    paddingBottom: 5,
    fontSize: "20px !important",
  },
}));

export default useStyles;
