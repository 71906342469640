import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "@cxeweb/pattern-library/lib/styles/index.css";
import "react-datepicker/dist/react-datepicker.css";
ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <App />
  </Provider>,
  document.querySelector("#root")
);
