import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputButton: {
    height: 28,
    marginLeft: 15,
    width: 80,
    "&:focus": {
      outline: 0,
    },
  },
  input: {
    "& .MuiInput-input": {
      fontSize: 13,
    },
  },
  iconButton: {
    padding: "5px 2px",
    "&:focus": {
      outline: 0,
      borderRadius: 5,
    },
    "&:hover": {
      borderRadius: 5,
    },
  },
  iconsButton: {
    "&:focus": {
      outline: 0,
    },
    "&:hover": {
      outline: 0,
    },
  },
}));

export default useStyles;
