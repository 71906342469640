import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import MediafileAutoComplete from "./MediafileAutoComplete";
import Skeleton from "@mui/material/Skeleton";
import { getClosureMsgMediaFile } from "../../../../services/closureMsg";
import { sortingCaseInSensitive } from "../utils";
import { useEffect, useState } from "react";

const ClosureAudioConfig = ({
  languageId,
  handleLanguageMediaFile,
  row = null,
  disabled = false,
  maxWidth,
}) => {
  const dispatch = useDispatch();
  const {
    mediaMgt: { mediaMgtData },
    closureMsg: { closureMsgMediaFileInfo, closureMsgStatus },
  } = useSelector((state) => state);

  const [mediaFilesOnLanguageId, setMediaFilesOnLanguageId] = useState([]);
  const [mediaFileInfo, setMediaFileInfo] = useState(null);
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);
  const [audioFileName, setAudioFileName] = useState("");

  useEffect(async () => {
    let filteredData = null;
    if (languageId === null) {
      filteredData =
        languageId &&
        mediaMgtData.data.filter((item) => item.languageCode === "en");
    } else {
      filteredData =
        languageId &&
        mediaMgtData.data.filter((item) => item.languageCode === languageId);
    }

    const result = filteredData.reduce((acc, cur) => {
      acc.push({
        value: cur.filePath,
        name: cur.fileName,
      });
      return acc;
    }, []);

    if (row) {
      const languages =
        Object.keys(row).length > 0 &&
        Object.keys(row).filter((a) => a.includes("message"));
      const mediaFileObj =
        row[languages.find((a) => a.split("_")[1] === languageId)];
      setSelectedMediaFile(
        mediaFileObj && mediaFileObj.value ? mediaFileObj.value : ""
      );
      mediaFileObj && handleMediaFileChange(mediaFileObj.value);
    }
    setMediaFilesOnLanguageId(result);
  }, [languageId]);

  useEffect(() => {
    if (
      closureMsgMediaFileInfo &&
      closureMsgMediaFileInfo.hasOwnProperty("mediaContent") &&
      closureMsgMediaFileInfo.mediaContent
    ) {
      setMediaFileInfo(closureMsgMediaFileInfo);
    }
  }, [closureMsgMediaFileInfo]);

  const playAudioFile = (file) => {
    return (
      <Box
        sx={{
          marginTop: 0,
          marginLeft: 10,
        }}
      >
        {closureMsgMediaFileInfo === null && closureMsgStatus !== "success" ? (
          <Box sx={{ width: "18vw", height: 26, maxWidth }}>
            <Skeleton animation="wave" height={26} />
          </Box>
        ) : (
          <audio
            key={Math.random()}
            style={{
              height: 26,
              width: "20vw",
              maxWidth,
            }}
            controls
            title={audioFileName}
          >
            {selectedMediaFile &&
            closureMsgMediaFileInfo &&
            closureMsgMediaFileInfo.hasOwnProperty("languageCode") &&
            closureMsgMediaFileInfo.languageCode === languageId ? (
              <source src={file} type="audio/wav" />
            ) : (
              <source src={null} type="audio/wav" />
            )}
          </audio>
        )}
      </Box>
    );
  };

  const handleMediaFileChange = async (value, isAudioFileChanged = false) => {
    const filerData = mediaMgtData.data.find((item) => item.filePath === value);
    const data = {
      instanceId: filerData?.instanceId,
      languageCode: filerData?.languageCode,
      language: filerData?.language,
      fileName: encodeURIComponent(filerData?.filePath),
      mediaId: filerData?.mediaId,
      type: filerData?.type,
    };

    handleLanguageMediaFile(value, isAudioFileChanged); // this method used to update row info
    setSelectedMediaFile(value);
    setAudioFileName(filerData?.fileName);

    if (value) {
      await dispatch(getClosureMsgMediaFile(data));
    }
  };

  const handleAudioFileValueChange = (value) => {
    handleMediaFileChange(value, true);
    //handleLanguageMediaFile(value, true); //this method used to update row info
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", marginTop: 2 }}>
        <Box sx={{ fontSize: 13, minWidth: 80, marginTop: 0.4 }}>
          Audio File :
        </Box>
        <Box sx={{ fontSize: 13 }}>
          <MediafileAutoComplete
            width={"22vw"}
            maxWidth={maxWidth}
            data={sortingCaseInSensitive(mediaFilesOnLanguageId)}
            handleSelectChange={handleAudioFileValueChange}
            defaultValue={selectedMediaFile}
            value={selectedMediaFile}
            disabled={disabled}
          />
        </Box>
        <span style={{ color: "red", fontSize: 22 }}>*</span>
      </Box>

      <Box sx={{ fontSize: 13, flex: 1, marginTop: 3 }}>
        {mediaFileInfo &&
          mediaFileInfo.hasOwnProperty("mediaContent") &&
          playAudioFile(mediaFileInfo.mediaContent)}
      </Box>
    </Box>
  );
};

export default ClosureAudioConfig;
