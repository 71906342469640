import { createSlice } from "@reduxjs/toolkit";
import { signIn, getResetTimers } from "../services/auth";

const initialState = {
  status: "idle",
  isLoggedIn: false,
  isResetTimers: false,
  cognitoUserId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.status = "pending";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const {
          signInUserSession: {
            idToken: { payload: { email = {} } = {} } = {},
          } = {},
        } = action.payload;
        state.status = "success";
        state.isLoggedIn = true;
        state.cognitoUserId = email;
      })
      .addCase(signIn.rejected, (state) => {
        state.status = "rejected";
        state.isLoggedIn = false;
        state.cognitoUserId = "";
      })
      .addCase(getResetTimers, (state, action) => {
        state.isResetTimers = action.payload;
      });
  },
});

export default authSlice.reducer;
