import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mask: {
    maxWidth: "3vw",
    height: 21,
    flex: 1,
    border: ".2px solid lightGrey",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderTopRightRadius: "none",
    borderBottomRightRadius: "none",
    outline: "none",
  },
  dialogRoot: {
    minWidth: 400,
    minHeight: 20,
    paddingBottom: 2,
  },
  dialogTitle: {
    marginBottom: 0,
    fontWeight: "bold",
    marginTop: -32,
  },
  dialogContent: {
    padding: 20,
    fontSize: 16,
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 6,
  },
  dialogButton: {
    marginRight: 10,
    fontSize: 14,
  },
  cellFocus: {
    cursor: "pointer",
  },
  resize: {
    fontSize: 20,
  },
  button: {
    fontSize: 14,
    height: 33,
    marginRight: 10,
    outline: "none",
  },
  timerSelect: {
    flex: 1,
    width: 50,
    textAlign: "center",
    borderRadius: 6,
    background: "#ffffff",
  },
}));

export default useStyles;
