import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import EditFeatureFlagsRow from "./EditFeatureFlagsRow";
import { columns } from "./columns";

const FeatureFlagsTable = ({ featureFlags, handleRefrehClick }) => {
  return (
    <SbuxEnhancedTable
      rows={featureFlags}
      columns={columns}
      defaultSortingField={"name"}
      defaultSortOrder={"asc"}
      RowSource={EditFeatureFlagsRow}
      showFooter={true}
      showSearchBar={false}
      isManager={true}
      handleRefreshClick={handleRefrehClick}
    />
  );
};

export default FeatureFlagsTable;
