import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  ccpRoot: {
    height: "79.2vh",
    position: "relative",
    overflow: "hidden",
  },
  overlayContainer: {
    display: "flex",
  },
  overlayButton: {
    flex: 1,
    textAlign: "center",
    position: "absolute",
    fontSize: "1.5rem",
    fontWeight: 600,
    padding: 15,
    minHeight: 57,
    bottom: "0",
    cursor: "pointer",
    background: "#666666",
    color: "#fff",
    width: "100%",
    height: "75px" /* default height */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen (max-width: 1023px)": {
      height: "75px",
    },
    "@media only screen and (min-width: 1024px) and (max-width: 1297px)": {
      height: "180px",
    },
    "@media only screen and (min-width: 1298px)": {
      height: "75px",
    },
    "@media only screen and (max-width: 353px)": {
      height: "180px",
    },
  },
  overlayDropdownRoot: {
    display: "flex",
    background: "rgb(74, 74, 74) !important",
    color: "#fff",
    paddingTop: 4,
    width: "100%",
  },
  overlayDropdownLabel: {
    fontSize: 14,
    fontWeight: 600,
    paddingTop: 4,
    paddingLeft: 22,
    paddingRight: 4,
    width: "30%",
    "@media only screen and (min-width: 1024px) and (max-width: 1174px)": {
      width: "90%",
      paddingLeft: 30,
    },
    "@media only screen and (min-width: 1175px) and (max-width: 1279px)": {
      width: "68%",
      paddingLeft: 32,
    },
    "@media only screen and (min-width: 1280px) and (max-width: 1439px)": {
      width: "59%",
      paddingLeft: 32,
    },
    "@media only screen and (min-width: 1440px)": {
      width: "48%",
    },
    "@media only screen and (min-width: 1500px)": {
      width: "42%",
    },
    "@media only screen and (min-width: 1680px)": {
      width: "35%",
    },
    "@media only screen and (min-width: 1920px)": {
      width: "30%",
    },
    "@media only screen and (min-width: 2140px)": {
      width: "25%",
    },
    "@media only screen and (min-width: 2540px)": {
      width: "20%",
    },
    "@media only screen and (min-width: 3440px)": {
      width: "14%",
    },
  },
  overlayDropdown: {
    position: "relative",
    fontSize: "1.5rem",
    fontWeight: 600,
    cursor: "pointer",
    width: "100%",
    height: 40,
    padding: 2,
    marginRight: 4,
  },

  iframeDisplay: {
    display: "none",
  },
  iframeLockOverlay: {
    width: "100%",
    height: 640,
    position: "absolute",
    top: "0",
    left: "0.5%",
    cursor: "not-allowed",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    border: "1px solid lightgrey",
    height: "88vh",
  },
  heading: {
    flex: 1,
    marginTop: "40%",
  },
}));

export default useStyles;
