import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const SbuxInputSearch = ({
  value,
  handleChange,
  handleClear,
  translatedSearchPlaceHolder = "Search",
}) => {
  return (
    <Box
      sx={{
        pr: "2px",
        fontSize: 16,
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        height: "45px",
        width: "auto",
      }}
    >
      <TextField
        variant="standard"
        value={value}
        onChange={handleChange}
        autoComplete="off"
        placeholder={`${translatedSearchPlaceHolder}...`}
        InputProps={{
          startAdornment: <SearchIcon fontSize="medium" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="medium"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={handleClear}
            >
              <ClearIcon fontSize="medium" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
            fontSize: 20,
          },
          "& .MuiInput-root": {
            fontSize: 16,
            height: 30,
          },
          "& .MuiInput-underline": {
            border: 1,
            borderColor: "divider",
            borderRadius: "5px",
          },
          "& .MuiInput-underline:focus-within": {
            border: 1,
            borderColor: "#006241",
            borderRadius: "5px",
          },
          "& .MuiInput-root:before": {
            borderBottom: "none",
          },
          "& .MuiInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "& .MuiInputBase-input": {
            fontSize: 14,
            padding: 0,
          },
          "& .MuiInput-underline:after": {
            borderBottom: 0,
          },
        }}
      />
    </Box>
  );
};

export default SbuxInputSearch;
