import { useState, useEffect } from "react";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxCustomRow from "../../../../../components/SbuxTable/SbuxCustomRow";
import SbuxCustomTable from "../../../../../components/SbuxTable/SbuxCustomTable";
import SbuxSubTabs from "../../../../../components/SbuxTabs/SbuxSubTabs";
import TemporaryMsgTimePicker from "./components/TemporaryMsgTimePicker";
import TemporaryAudioConfig from "../../common/TemporaryAudioConfig";
import TemporaryMsgMode from "./components/TemporaryMsgMode";
import TemporaryMsgDateTimeOption from "./components/TemporaryMsgTimeOption";
import useTextTranslate from "../../../hooks/useTextTranslate";
import { temporaryColumns } from "../temporaryMsg/columns";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { DATE_TIME_BASED_ON_TIMEZONE } from "../../../../../constants";

const TemporaryMsgEdit = ({ row, handleTemporaryMsgRow }) => {
  const disabledInputStyle = {
    opacity: 0,
    WebkitTextFillColor: "#006241",
    // Add any other styles you want to apply to disabled inputs
  };
  const {
    userInstance: {
      connectedInstance: {
        appConfig: { languages },
      },
    },
    globalConfig: { globalConfigUi },
    mediaMgt: { mediaMgtData },
  } = useSelector((state) => state);
  const [rowObj, setRowObj] = useState(row);

  useEffect(() => {
    const remainder = 30 - (moment().minute() % 30);

    const startTimeonLoad = moment()
      .add(remainder, "minutes")
      .format("hh:mm A");
    const endTimeonLoad = moment(startTimeonLoad, "hh:mm A")
      .add("minutes", 30)
      .format("hh:mm A");
    const initialObj = {
      mode: row.status === "Draft" ? "draftMode" : "durationMode",
      startTimeOption: row["startTime"] ? "userDefined" : "immediately",
      endTimeOption: row["endTime"] ? "userDefined" : "immediately",
      startTime: rowObj["startTime"] ? rowObj.startTime : startTimeonLoad,
      endTime: rowObj["endTime"] ? rowObj.endTime : endTimeonLoad,
      endDate: rowObj["endDate"] ? rowObj.endDate : new Date(),
      startTimeZone: rowObj.startTimeZone,
      endTimeZone: globalConfigUi.timezones[3].value,
    };
    setRowObj(Object.assign({}, initialObj, row));
  }, [row]);

  const [description, setDescription] = useState(rowObj.description);
  const [info, setInfo] = useState(rowObj.info);
  const [descriptionError, setDescriptionError] = useState(false);
  const [mode, setMode] = useState(
    rowObj.status === "Draft" ? "draftMode" : "durationMode"
  );
  const [startTimeOption, setStartTimeOption] = useState(
    rowObj["startTime"] ? "userDefined" : "immediately"
  );
  const [endTimeOption, setEndTimeOption] = useState(
    rowObj["endTime"] ? "userDefined" : "immediately"
  );
  const [startDate, setStartDate] = useState(
    rowObj["startDate"] ? moment(rowObj.startDate).toDate() : new Date()
  );
  const [endDate, setEndDate] = useState(
    rowObj["endDate"] ? moment(rowObj.endDate).toDate() : new Date()
  );
  const remainder = 30 - (moment().minute() % 30);

  const startTimeonLoad = moment().add(remainder, "minutes").format("hh:mm A");

  const [startTime, setStartTime] = useState(
    rowObj["startTime"] ? rowObj.startTime : startTimeonLoad
  );
  const endTimeonLoad = moment(startTimeonLoad, "hh:mm A")
    .add("minutes", 30)
    .format("hh:mm A");
  const [endTime, setEndTime] = useState(
    rowObj["endTime"] ? rowObj.endTime : endTimeonLoad
  );
  const [startTimeZone, setStartTimeZone] = useState(
    rowObj["startTimeZone"]
      ? rowObj.startTimeZone
      : globalConfigUi.timezones[3].value
  );
  const [endTimeZone, setEndTimeZone] = useState(
    globalConfigUi.timezones[3].value
  );

  const [languageTabId, setLanguageTabId] = useState(languages[0].code);
  const [languageMessage, setLanguageMessage] = useState({});

  const descriptionPlaceHolder = useTextTranslate("Description");
  const [timeLable, setTimeLabel] = useState("");

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    setDescriptionError(value.length < 1 ? true : false);
    const updateRow = {
      ...rowObj,
      description: value,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow);
  };

  const handleInfoChange = (event) => {
    const value = event.target.value;
    setInfo(value);
    const updateRow = {
      ...rowObj,
      info: value,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow);
  };

  const handleDateChange = (value) => {
    let updateRow = null;
    if (value.name === "Start Time") {
      setStartDate(value.date);
      updateRow = {
        ...rowObj,
        startDate: value.date,
      };
    } else if (value.name === "End Time") {
      setEndDate(value.date);
      updateRow = {
        ...rowObj,
        endDate: value.date,
      };
    }
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow, false, true);
  };
  const handleStartTimeChange = (value) => {
    setStartTime(value.time);
    setTimeLabel("Start Time");
    let addRow = {
      ...rowObj,
      startTime: value.time,
      ...languageMessage,
    };
    const timeString = value.time;
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    if (modifier === "PM" && hours < 12) {
      hours += 12;
    }
    const sDate = moment(startDate).format("MM/DD/YYYY");
    const eDate = moment(endDate).format("MM/DD/YYYY");
    const dateDiff = sDate === eDate ? 0 : 1;

    if (hours >= 23 && minutes >= 30 && !dateDiff) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setEndDate(tomorrow);
      setEndTime("12:00 AM");
      addRow = {
        ...addRow,
        endDate: tomorrow,
        endTime: "12:00 AM",
      };
    }
    setRowObj(addRow);
    handleTemporaryMsgRow(addRow, false, true);
  };
  const handleEndTimeChange = (value) => {
    let updateRow = null;
    setEndTime(value.time);
    setTimeLabel("End Time");
    updateRow = {
      ...rowObj,
      startTime: rowObj.startTime,
      endTime: value.time,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow);
  };
  const handleTimeZoneChange = (timeZone) => {
    let updateRow = null;
    setStartTimeZone(timeZone);
    setEndTimeZone(timeZone);
    updateRow = {
      ...rowObj,
      startTimeZone: timeZone,
      endTimeZone: timeZone,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow, false, true);
  };

  const handleModeValueChange = (event) => {
    setMode(event.target.value);
    if (event.target.value === "draftMode") {
      setStartTimeOption("immediately");
      setEndTimeOption("immediately");
    }
    const updateRow = {
      ...rowObj,
      mode: event.target.value,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow, false, true);
  };

  const handleStartTimeOptionChange = (event) => {
    setStartTimeOption(event.target.value);
    const startDateVal = rowObj["startDate"]
      ? moment(rowObj.startDate).toDate()
      : new Date();
    const updateRow = {
      ...rowObj,
      startDate: startDateVal,
      startTimeOption: event.target.value,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow, false, true);
  };

  const handleEndTimeOptionChange = (event) => {
    const endDateVal = rowObj["endDate"]
      ? moment(rowObj.endDate).toDate()
      : new Date();
    setEndDate(endDateVal);
    setEndTimeOption(event.target.value);
    const updateRow = {
      ...rowObj,
      endDate: endDateVal,
      endTimeOption: event.target.value,
    };
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow, false, true);
  };

  const handleSubTabChange = (newValue) => {
    setLanguageTabId(newValue);
  };

  const handleLanguageMediaFile = (value, isAudioFileChanged = false) => {
    let messageLanguageTabId = `message_${languageTabId}`;
    const existingLanguages =
      Object.keys(rowObj).length > 0 &&
      Object.keys(rowObj)
        .filter((a) => a.includes("message"))
        .reduce((acc, cur) => ((acc[cur] = rowObj[cur]), acc), {});
    let updateLanguageMessage = Object.assign(languageMessage);
    setLanguageMessage(updateLanguageMessage);
    let updateRow = {
      ...rowObj,
    };
    if (value) {
      const mediaInfo = mediaMgtData.data.find((m) => m.filePath === value);
      const newLanguageMessage = {
        [`${messageLanguageTabId}`]: {
          type: "AUDIO",
          value: mediaInfo.filePath,
          accessType: mediaInfo.type,
        },
      };

      updateLanguageMessage = {
        ...existingLanguages,
        ...languageMessage,
        ...newLanguageMessage,
      };
      updateRow = {
        ...rowObj,
        ...updateLanguageMessage,
        languageMessage: { ...updateLanguageMessage },
        isAudioFileChanged: isAudioFileChanged,
      };
    } else {
      if (updateLanguageMessage[messageLanguageTabId]) {
        delete updateLanguageMessage[messageLanguageTabId];
      }
      updateRow = {
        ...rowObj,
        ...updateLanguageMessage, //This is required for button enabled or disabled validation
        languageMessage: { ...updateLanguageMessage }, // This is required adding new closure
        isAudioFileChanged: isAudioFileChanged,
      };
      if (updateRow[messageLanguageTabId]) {
        delete updateRow[messageLanguageTabId];
      }
    }
    setRowObj(updateRow);
    handleTemporaryMsgRow(updateRow, isAudioFileChanged);
  };
  const SubTabsComponent = () => {
    const updatedLanguages = languages.reduce((acc, cur) => {
      acc.push({
        name: cur.name,
        value: cur.code,
      });
      return acc;
    }, []);
    const handleSubTabClick = (tab) => {
      handleSubTabChange(tab.value);
    };
    return (
      <SbuxSubTabs
        tabList={updatedLanguages}
        tabId={languageTabId}
        handleTabClick={handleSubTabClick}
        width={"33vw"}
        maxWidth={"530px"}
      />
    );
  };

  return (
    <Box sx={{ height: "76vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          paddingTop: 0.5,
        }}
        noValidate
        autoComplete="off"
      >
        <SbuxCustomTable
          width="100%"
          height="78vh"
          tableLayout="fixed"
          columns={temporaryColumns}
          isTranslationRequired
        >
          <SbuxCustomRow
            name="Code"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  disabled
                  value={rowObj.sequenceNumber}
                />
              </Box>
            }
          />

          <SbuxCustomRow
            name="Description"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  placeholder={descriptionPlaceHolder}
                  value={description}
                  handleChange={handleDescriptionChange}
                  errorMessage={"Description required at least 1 character"}
                  isError={descriptionError}
                  error={descriptionError}
                  inputProps={{
                    style: {
                      ...disabledInputStyle,
                    },
                  }}
                  disabled={
                    (row && row.status === "Expired") ||
                    "Disabled" ||
                    "Active" ||
                    "Scheduled"
                  }
                />
              </Box>
            }
            isRequired
          />

          <SbuxCustomRow
            name="Info"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  value={rowObj.info}
                  multiline={true}
                  handleChange={handleInfoChange}
                  disabled={
                    (row && row.status === "Expired") ||
                    "Disabled" ||
                    "Active" ||
                    "Scheduled"
                  }
                />
              </Box>
            }
          />

          <SbuxCustomRow
            name={"Date Time"}
            value={
              <Box sx={{ display: "flex" }}>
                <Box sx={{ marginRight: 0.5 }}>
                  <TemporaryMsgMode
                    row={row}
                    mode={mode}
                    handleModeValueChange={handleModeValueChange}
                  />
                </Box>
              </Box>
            }
          />

          {mode === "durationMode" && (
            <>
              <SbuxCustomRow
                name={"Start Time"}
                value={
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginRight: 0.5 }}>
                      <TemporaryMsgDateTimeOption
                        name="Start Time"
                        row={row}
                        timeOption={startTimeOption}
                        handleTimeOptionValueChange={
                          handleStartTimeOptionChange
                        }
                      />
                    </Box>
                  </Box>
                }
              />
              {startTimeOption !== "immediately" && (
                <TemporaryMsgTimePicker
                  name="Start Time"
                  startDate={startDate}
                  endDate={endDate}
                  startTimeValue={startTime}
                  endTimeValue={endTime}
                  timeZoneValue={startTimeZone}
                  timeZoneList={globalConfigUi.timezones}
                  startTimeOption={startTimeOption}
                  endTimeOption={endTimeOption}
                  handleDateChange={handleDateChange}
                  handleStartTimeChange={handleStartTimeChange}
                  handleTimeZoneChange={handleTimeZoneChange}
                  row={row}
                />
              )}

              <SbuxCustomRow
                name={"End Time"}
                value={
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginRight: 0.5 }}>
                      <TemporaryMsgDateTimeOption
                        name="End Time"
                        row={row}
                        timeOption={endTimeOption}
                        handleTimeOptionValueChange={handleEndTimeOptionChange}
                      />
                    </Box>
                    <Box
                      sx={{
                        color: "#006241",
                        fontSize: 12,
                        fontWeight: 600,
                        width: "18vw",
                      }}
                    >
                      {`${DATE_TIME_BASED_ON_TIMEZONE} ${moment
                        .tz(moment(), startTimeZone)
                        .format("MM/DD/YYYY hh:mm a")}`}
                    </Box>
                  </Box>
                }
              />
              {endTimeOption !== "immediately" && (
                <TemporaryMsgTimePicker
                  name="End Time"
                  startDate={startDate}
                  endDate={endDate}
                  startTimeValue={startTime}
                  endTimeValue={endTime}
                  timeZoneValue={endTimeZone}
                  timeZoneList={globalConfigUi.timezones}
                  startTimeOption={startTimeOption}
                  endTimeOption={endTimeOption}
                  handleDateChange={handleDateChange}
                  handleEndTimeChange={handleEndTimeChange}
                  handleTimeZoneChange={handleTimeZoneChange}
                  row={row}
                />
              )}

              <SbuxCustomRow
                name={<Box sx={{ fontSize: 13 }}>{`Audio Configuration`}</Box>}
                value={
                  <Box
                    sx={{
                      display: "flex",
                      height: 165,
                    }}
                  >
                    <Box sx={{ height: 165, border: "2px solid lightgrey" }}>
                      <SubTabsComponent />
                      <Box sx={{ width: "100%", marginLeft: 2 }}>
                        <TemporaryAudioConfig
                          languageId={languageTabId}
                          handleLanguageMediaFile={handleLanguageMediaFile}
                          row={rowObj}
                          maxWidth={"400px"}
                        />
                      </Box>
                    </Box>
                  </Box>
                }
              />
            </>
          )}
        </SbuxCustomTable>
      </Box>
    </Box>
  );
};

export default TemporaryMsgEdit;
