import StyledSbuxButton from "./styled";

const SbuxButton = ({
  id,
  disabled = false,
  height = 28,
  width = 80,
  type = "",
  padding = 4,
  onClick,
  children,
}) => {
  return (
    <StyledSbuxButton
      id={id}
      disabled={disabled}
      onClick={onClick}
      height={height}
      width={width}
      type={type}
    >
      {children}
    </StyledSbuxButton>
  );
};

export default SbuxButton;
