import CellEditTextField from "./CellEditTextField";
import { CURRENT_TICKET_OR_CASE_NUMBER } from "../../../constants";
export const getDataGridColumns = (
  ccpContactStatus,
  ccpContactMethod,
  ticketOrCaseFeatureFlagEnabled
) => {
  const config = [
    {
      headerName: "Name",
      field: "name",
      width: 480,
      sortable: false,
    },
    {
      headerName: "Value",
      field: "value",
      width: 480,
      type: "string",
      sortable: false,
      renderCell: (cellValues) => {
        return cellValues.row.name === CURRENT_TICKET_OR_CASE_NUMBER ? (
          <CellEditTextField
            defaultValue={cellValues.value}
            ccpContactStatus={ccpContactStatus}
            ccpContactMethod={ccpContactMethod}
            ticketOrCaseFeatureFlagEnabled={ticketOrCaseFeatureFlagEnabled}
          />
        ) : (
          <span>{cellValues.value}</span>
        );
      },
    },
  ];

  return [...config];
};
