import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import StyledSelect from "./styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormControl } from "@mui/material";
import useStyles from "./styles";

const SbuxSelect = ({
  isSelectOnCCP = null,
  menuItems,
  value,
  defaultValue,
  handleSelectChange,
  height = null,
  width = null,
  disabled = false,
  maxWidth = null,
  placeHolderText = "",
}) => {
  const classes = useStyles();

  const getMenuItems = () => {
    return (
      menuItems &&
      menuItems.length > 0 &&
      menuItems.map((menuItem, index) => (
        <MenuItem
          sx={{
            fontSize: 14,
          }}
          key={index}
          className={!isSelectOnCCP ? classes.menuItem : ""}
          value={menuItem.value}
        >
          {menuItem.name}
        </MenuItem>
      ))
    );
  };
  const handleOnChange = (event) => {
    handleSelectChange(event.target.value);
  };
  return (
    <>
      {isSelectOnCCP ? (
        <FormControl sx={{ width: "100%", position: "relative" }}>
          <StyledSelect
            IconComponent={KeyboardArrowDownIcon}
            value={value}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            MenuProps={{
              sx: {
                fontWeight: 400,
                "&& .Mui-selected": {
                  backgroundColor: "rgb(71, 89, 110)",
                  color: "#fff",
                },
                "&&:hover .Mui-selected": {
                  backgroundColor: "rgb(71, 89, 110)",
                  color: "#fff",
                },
              },
            }}
          >
            {getMenuItems()}
          </StyledSelect>
        </FormControl>
      ) : (
        <FormControl>
          <Select
            sx={{
              width: width,
              height: height ?? 27,
              maxWidth: maxWidth ?? "100%",
            }}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            className={classes.select}
            onChange={handleOnChange}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {getMenuItems()}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SbuxSelect;
