import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Box, Paper } from "@mui/material";
import useStyles from "../../styles";
import SbuxTranslate from "../../../SbuxTranslate";

const DragElement = ({ element, index }) => {
  const classes = useStyles();
  return (
    <Box className={classes.elementRoot}>
      {element.visible && (
        <Draggable draggableId={element.tabId} index={index}>
          {(provided) => (
            <Paper
              sx={{
                background: "rgb(242, 242, 242)",
                border: "1px solid rgba(0, 0, 0, 0.05)",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                fontSize: 16,
                minWidth: "14.25vw",
                minHeight: "3.5vh",
                marginBottom: 1,
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className={classes.elementTabName}>
                <SbuxTranslate>{element.label}</SbuxTranslate>
              </div>
            </Paper>
          )}
        </Draggable>
      )}
    </Box>
  );
};

export default React.memo(DragElement);
