import { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SbuxTranslate from "../SbuxTranslate";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const SbuxButtonGroup = ({ buttonList, value, handleButtonToggle, height }) => {
  const [buttonValue, setButtonValue] = useState(value);

  const handleToggle = (event, newValue, isDisabled) => {
    if (isDisabled === undefined || isDisabled === false) {
      handleButtonToggle(newValue);
      setButtonValue(newValue);
    }
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
    },
  });

  return (
    <ToggleButtonGroup
      sx={{ display: "flex", width: "40%" }}
      value={buttonValue}
      exclusive
    >
      {buttonList &&
        buttonList.length > 0 &&
        buttonList.map((item, index) => {
          return (
            <CustomWidthTooltip
              title={
                item.disabled ? (
                  <span style={{ fontSize: 14 }}>{item.toolTipMsg}</span>
                ) : (
                  ""
                )
              }
              placement="right"
              key={index}
            >
              <ToggleButton
                onChange={(event, newValue) =>
                  handleToggle(event, newValue, item.disabled)
                }
                value={item.value}
                size="small"
                key={index}
                sx={{
                  fontSize: 14,
                  minWidth: item.minWidth ? item.minWidth : 80,
                  outline: "0 !important",
                  textTransform: "capitalize",
                  height: height ? height : 30,
                  color: buttonValue === item.value && "#fff",
                  backgroundColor:
                    buttonValue === item.value && "rgb(74, 74, 74) !important",
                  boxShadow:
                    buttonValue === item.value && "-7px 0 15px -4px #616161",
                  ":hover": {
                    color: "#fff",
                    backgroundColor: "rgb(74, 74, 74) !important",
                    boxShadow: "-7px 0 15px -4px #616161",
                  },
                  flex: 1,
                  cursor: item.disabled ? "not-allowed" : "pointer",
                }}
              >
                <SbuxTranslate>{item.name}</SbuxTranslate>
              </ToggleButton>
            </CustomWidthTooltip>
          );
        })}
    </ToggleButtonGroup>
  );
};

export default SbuxButtonGroup;
