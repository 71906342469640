import { useState, useEffect } from "react";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import SbuxSubTabs from "../../../../../components/SbuxTabs/SbuxSubTabs";
import ClosureMsgEditDialog from "../../common/ClosureMsgEditDialog";
import ClosureMsgDialog from "../../common/ClosureMsgDialog";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { columns, filterColumns } from "../closureMsg/columns";
import ClosureMsgRow from "./ClosureMsgRow";
import ClosureMsgCustomFilter from "../../common/ClosureMsgCustomFilter";
import { closureMsgFilterItem } from "../../utils";
import { Paper, Box } from "@mui/material";
import { getClosureMsgSubTabsOrderList } from "../../utils";
import { CLOSURE_MSG_SUB_TABS_ORDER } from "../../../../../constants";
import { subOnUpdateClosureMessages } from "../../../../../graphql/api";
import SbuxSnackbars from "../../../../../components/SbuxSnackbars";
import { MEDIAMGT_REFRESH_USER_MESSAGE } from "../../../../../constants/index";
import {
  updateCMData,
  createCMData,
  deleteCMData,
  resetHighlight,
} from "../../../../../slices/closureMsgSlice";

import { useDispatch, useSelector } from "react-redux";

const ClosureMsgTabs = ({
  closureTabs,
  languages,
  business,
  closureMsgData,
  handleRefrehClick,
  filteredItem,
  setFilteredItem,
  closureMsgReset,
  globalConfigUi,
}) => {
  const updatedBusiness = business.map((cur) => ({
    ...cur,
    value: cur.id,
  }));
  const {
    auth: { cognitoUserId },
  } = useSelector((state) => state);
  const { connectedInstance } = useSelector((state) => state.userInstance);

  const [closureSubTabId, setClosureSubTabId] = useState(closureTabs[0].value);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [closureMsgFilterData, setClosureMsgFilterData] = useState([]);
  const [isCustomFilter, setIsCustomFilter] = useState(false);
  const [row, setRow] = useState(null);
  const [radioButtonValue, setRadioButtonValue] = useState(null);
  const [customFromDate, setCustomFromDate] = useState(null);
  const [customToDate, setCustomToDate] = useState(null);
  const [updatedClosureId, setUpdatedClosureId] = useState(null);
  const [openCMUpdateSnackbar, setOpenCMUpdateSnackbar] = useState(false);

  const parseMessage = (message) => {
    return {
      ...message,
      occurrences: JSON.parse(message?.occurrences),
      repeatPattern: JSON.parse(message?.repeatPattern),
    };
  };

  useEffect(() => {
    setClosureMsgFilterData(closureMsgData);
    handleSubTabChange(closureSubTabId);
    setIsCustomFilter(false);
  }, [closureMsgData, filteredItem]);

  const OPERATION = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
  };

  const subs = [];
  const dispatch = useDispatch();

  const instanceId = connectedInstance.instanceId;
  const handleClosureSubscription = (data) => {
    const closureData = data?.value?.data?.onProcessedClosureMessage;

    const operation = closureData.__operation;
    if (closureData?.lastUpdatedBy !== cognitoUserId) {
      setUpdatedClosureId(closureData?.id);
      const parsedMessage = parseMessage(closureData);

      switch (operation) {
        case OPERATION.CREATE:
          dispatch(createCMData(parsedMessage));
          break;
        case OPERATION.UPDATE:
          dispatch(updateCMData(parsedMessage));
          break;
        case OPERATION.DELETE:
          dispatch(deleteCMData(parsedMessage));
          break;
      }

      setOpenCMUpdateSnackbar(true);
    }
  };

  useEffect(() => {
    subs.forEach((sub) => sub.unsubscribe());
    const onProcessSub = subOnUpdateClosureMessages(instanceId).subscribe(
      handleClosureSubscription,
      (err) =>
        logMessage(
          `Closure Messages `,
          `Something went wrong in Subscription API => ${err}`,
          `error`
        )
    );

    subs.push(onProcessSub);

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  const handleAddNew = () => {
    setOpenAddNew((prevState) => !prevState);
  };

  const handleEditRowClick = (row) => {
    setRow(row);
    setIsEditOpen((prevState) => !prevState);
    setRadioButtonValue(null);
  };

  const handleRadioOkClick = (value) => {
    setRadioButtonValue(value);
  };

  const handleSubTabChange = (newValue) => {
    setClosureSubTabId(newValue);

    if (newValue === "custom") {
      customDateData(customFromDate, customToDate);
      setIsCustomFilter((prevState) => {
        return !prevState;
      });
    } else {
      const data = closureMsgFilterItem(newValue, closureMsgData, filteredItem);
      setClosureMsgFilterData(data);
    }
  };
  const handleCloseCustomFilter = () => {
    setIsCustomFilter(false);
  };

  const handleClose = () => {
    setOpenCMUpdateSnackbar(false);
    const updatedData = closureMsgData.map((item) => {
      return { ...item, highLight: false };
    });
    setClosureMsgFilterData(updatedData);
    dispatch(resetHighlight(updatedData));
  };

  const customDateData = (fromDate, toDate) => {
    const data = closureMsgFilterItem(
      closureSubTabId,
      closureMsgData,
      filteredItem,
      fromDate,
      toDate
    );
    setCustomFromDate(fromDate);
    setCustomToDate(toDate);
    setClosureMsgFilterData(data);
    setIsCustomFilter(false);
  };
  const handleCustomOkClick = (fromDate, toDate) => {
    customDateData(fromDate, toDate);
  };
  const handleSubTabClick = (tab) => {
    handleSubTabChange(tab.value);
  };
  const SubTabsComponent = () => {
    return (
      <SbuxSubTabs
        tabList={closureTabs}
        tabId={closureSubTabId}
        handleTabClick={handleSubTabClick}
      />
    );
  };

  return (
    <Paper>
      <SbuxEnhancedTable
        rows={
          closureMsgReset && filteredItem.length === 0
            ? []
            : closureMsgFilterData
        }
        columns={columns}
        defaultSortingField={"startDate"}
        defaultSortOrder={"asc"}
        RowSource={ClosureMsgRow}
        showFooter={true}
        showSearchBar={true}
        isManager={true}
        filterColumns={filterColumns}
        defaultFilterColumns={filteredItem}
        setFilteredItem={setFilteredItem}
        filterTitle={<SbuxTranslate>{"Closure Messages"}</SbuxTranslate>}
        showSubTabs
        showAddNewButton
        handleAddNewButtonClick={handleAddNew}
        addNewButtonTitle={<SbuxTranslate>{"Add New"}</SbuxTranslate>}
        SubTabs={SubTabsComponent}
        handleEditRowClick={handleEditRowClick}
        handleRefrehClick={handleRefrehClick}
        updatedRowId={updatedClosureId}
      />
      {openAddNew && (
        <ClosureMsgDialog
          title={
            <SbuxTranslate>{`New Closure Message Configuration`}</SbuxTranslate>
          }
          open={openAddNew}
          toggleDialogOpen={handleAddNew}
          business={updatedBusiness}
          languages={languages}
          globalConfigUi={globalConfigUi}
          row={{}}
          isAddNew
        />
      )}
      {isCustomFilter && (
        <ClosureMsgCustomFilter
          title={`Custom Filter`}
          open={isCustomFilter}
          toggleOpen={handleCloseCustomFilter}
          handleCustomFilterOkClick={handleCustomOkClick}
        />
      )}
      {isEditOpen && row && row.type === "Single" ? (
        <ClosureMsgDialog
          title={
            <SbuxTranslate>{`Closure Message Configuration`}</SbuxTranslate>
          }
          open={isEditOpen}
          toggleDialogOpen={handleEditRowClick}
          handleRadioOkClick={handleRadioOkClick}
          row={row}
        />
      ) : (
        isEditOpen &&
        row &&
        row.type !== "Single" && (
          <ClosureMsgEditDialog
            title={
              <SbuxTranslate>{`Closure Message Configuration`}</SbuxTranslate>
            }
            open={isEditOpen}
            toggleDialogOpen={handleEditRowClick}
            handleRadioOkClick={handleRadioOkClick}
            row={row}
          />
        )
      )}
      {radioButtonValue && (
        <ClosureMsgDialog
          title={
            <SbuxTranslate>{`Closure Message Configuration`}</SbuxTranslate>
          }
          open={isEditOpen}
          toggleDialogOpen={handleEditRowClick}
          row={row}
          isAddNew={false}
          isSingleInstance={
            radioButtonValue === "singleInstance" ? true : false
          }
        />
      )}
      {openCMUpdateSnackbar && (
        <SbuxSnackbars
          width={380}
          open={openCMUpdateSnackbar}
          message={MEDIAMGT_REFRESH_USER_MESSAGE}
          autoHide={false}
          handleClose={handleClose}
        />
      )}
    </Paper>
  );
};

export default ClosureMsgTabs;
