import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import { Box, FormControlLabel, Radio } from "@mui/material";

const ClosureMsgDailyFrequency = ({
  dailyOption,
  dailyOptoin1InputValue,
  handleDailyOptionsValueChange,
  handleDailyOptoin1InputValue,
}) => {
  return (
    <SbuxRadioGroup
      value={dailyOption}
      handleRadioButtonChange={handleDailyOptionsValueChange}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"dailyOption1EveryDays"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  pointerEvents:
                    dailyOption !== "dailyOption1EveryDays" && "none",
                  opacity: dailyOption !== "dailyOption1EveryDays" && 0.6,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    marginRight: 1,
                    marginTop: 0.4,
                    fontSize: 12,
                  }}
                >
                  {"Every"}
                </Box>
                <Box sx={{ flex: 1, marginRight: 1 }}>
                  <input
                    type={"number"}
                    value={dailyOptoin1InputValue}
                    style={{
                      width: 40,
                      height: 24,
                      fontSize: 12,
                    }}
                    onChange={(event) =>
                      handleDailyOptoin1InputValue(event.target.value)
                    }
                  />
                </Box>
                <Box sx={{ flex: 1, marginTop: 0.4, fontSize: 12 }}>
                  {"day(s)"}
                </Box>
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"dailyOption2EveryWeekDay"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  flex: 1,
                  marginRight: 1,
                  marginTop: 0.4,
                  fontSize: 12,
                  pointerEvents:
                    dailyOption !== "dailyOption2EveryWeekDay" && "none",
                  opacity: dailyOption !== "dailyOption2EveryWeekDay" && 0.6,
                }}
              >
                {"Every Weekday"}
              </Box>
            }
          />
        </Box>
      </Box>
    </SbuxRadioGroup>
  );
};

export default ClosureMsgDailyFrequency;
