import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { COLOR_GREEN_ACCENT } from "../../constants/index";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0,98,68,.05)",
    backgroundImage: "linear-gradient(#e6f0ed, #ebf3f0)",
  },
  "&:nth-of-type(even):hover, &:nth-of-type(odd):hover": {
    backgroundColor: "rgb(204, 204, 204, 0.5)",
    backgroundImage: "linear-gradient(#e0e0e0, #e6e6e6)",
  },
  "&:last-of-type td, &:last-of-type th": {
    borderBottom: 0,
  },
}));

export default StyledTableRow;
