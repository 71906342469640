import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "2px !important",
    zIndex: 1,
    "@media (max-width:768px)": {
      zIndex: 2,
    },
    "@media (min-width:1023px)": {
      "& .sb-globalNav__logo": {
        marginLeft: "-1.5rem  !important",
        marginRight: "3rem !important",
      },
    },
  },
}));

export default useStyles;
