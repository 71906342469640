import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";

import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxHeading from "../../../../../components/SbuxHeading";
import SbuxLayer from "../../../../../components/SbuxLayer";
import SbuxDialog from "../../../../../components/SbuxDialog";
import SbuxButton from "../../../../../components/SbuxButton";

import useStyles from "../../styles";
import { iconButton } from "./styles";

const RowSource = ({ row, handleDeleteRowClick }) => {
  const {
    connect: { status },
  } = useSelector((state) => state);

  const [openConfirmDialog, setOpenConfigDialog] = useState(false);
  const classes = useStyles();

  const toggleDialogOpen = () => {
    setOpenConfigDialog(!openConfirmDialog);
  };

  const handleOnClickDeleteEvent = () => {
    if (!status.endsWith("pending")) {
      setOpenConfigDialog(true);
    }
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["Name"]}
        </SbuxStyledTableCell>

        <SbuxStyledTableCell>
          <IconButton
            sx={iconButton}
            onClick={handleOnClickDeleteEvent}
            disableFocusRipple
            disableRipple
          >
            <DeleteIcon />
          </IconButton>
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>

      {openConfirmDialog && (
        <SbuxLayer isOpen={openConfirmDialog}>
          <SbuxDialog
            toggleDialogOpen={toggleDialogOpen}
            isrenderCloseButton={true}
            isClickAwayEnabled={true}
            toggleModalOnModalDialog={() => {
              // handle fn invoked by `pattern-lib->Dialog->onCancel event`
              setOpenConfigDialog(false);
            }}
          >
            <div className={classes.dialogRoot}>
              <SbuxHeading
                tagName="h6"
                size="xs"
                className={classes.dialogTitle}
              >
                Delete
              </SbuxHeading>

              <Divider />

              <div className={classes.dialogContent}>
                <SbuxTranslate>{`Are you sure you want to delete `}</SbuxTranslate>
                <span>{`${row["Name"]}?`}</span>
              </div>
              <div className={classes.dialogFooter}>
                <SbuxButton
                  type="save"
                  height={28}
                  onClick={() => {
                    handleDeleteRowClick(row);
                    setOpenConfigDialog(false);
                  }}
                >
                  Okay
                </SbuxButton>
                <SbuxButton
                  type="cancel"
                  height={28}
                  onClick={() => {
                    setOpenConfigDialog(false);
                  }}
                >
                  Cancel
                </SbuxButton>
              </div>
            </div>
          </SbuxDialog>
        </SbuxLayer>
      )}
    </>
  );
};

export default RowSource;
