import GlobalNav from "@cxeweb/pattern-library/lib/components/global-nav";
import SbuxIdleTimer from "../SbuxIdleTimer";
import useStyles from "./styles";

const SbuxGlobalNav = ({ navItems, isUserAuthenticated, auxiliaryContent }) => {
  const classes = useStyles();
  return (
    <>
    <GlobalNav
      className={classes.root}
      navAriaLabel="Global"
      navItems={navItems}
      isUserAuthenticated={isUserAuthenticated}
      fixedWhenHamburgerNavOpen={true}
      logoProps={{
        onClick: (e) => e.preventDefault(),
      }}
      menuButtonProps={{
        labelWhenClosed: "Open navigation menu",
        labelWhenOpen: "Close navigation menu",
        "data-e2e": "sbux",
      }}
      auxiliaryContent={auxiliaryContent}
    />
    <SbuxIdleTimer />
    </>
  );
};

export default SbuxGlobalNav;
