import React from "react";
import { StyledGridOverlay } from "./styled";

export const GenesysRecordingsOverlay = React.memo(() => {
  return (
    <StyledGridOverlay>
      <img width="120" height="100" src={"/images/instance.png"} />
      <div style={{ Top: 10, fontSize: 20, fontWeight: 600 }}>
        {"No data found"}
      </div>
    </StyledGridOverlay>
  );
});
