import SbuxHeading from "../../../../../../components/SbuxHeading";
import SbuxLayer from "../../../../../../components/SbuxLayer";
import SbuxDialog from "../../../../../../components/SbuxDialog";
import SbuxButton from "../../../../../../components/SbuxButton";
import SbuxTranslate from "../../../../../../components/SbuxTranslate";
import { deleteQueue } from "../../../../../../services/queue";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";

import useStyles from "../../../styles";

const QueueDeleteDialog = ({ row, title, name, open, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOkayClick = async () => {
    await dispatch(deleteQueue(row?.Id));
  };

  return (
    <>
      <SbuxLayer isOpen={open}>
        <SbuxDialog
          toggleDialogOpen={toggleOpen}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              {title}
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <SbuxTranslate>{`Are you sure you want to delete `}</SbuxTranslate>
              <span>{`${name}?`}</span>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton type="save" onClick={handleOkayClick}>
                <SbuxTranslate>{`Okay`}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="cancel" onClick={toggleOpen}>
                <SbuxTranslate>{`Cancel`}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default QueueDeleteDialog;
