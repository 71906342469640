import React from "react";
import SbuxHeading from "../SbuxHeading";
import SbuxLayer from "../SbuxLayer";
import SbuxDialog from "../SbuxDialog";
import SbuxButton from "../SbuxButton";
import SbuxTranslate from "../SbuxTranslate";
import { Divider, Box } from "@mui/material";
import useStyles from "./styles";

const SbuxConfirmationDialog = ({
  open,
  toggleDialogOpen,
  handleOkClick,
  heading = "",
  content,
  okBtnText = "Okay",
  cancelBtnText = "Cancel",
}) => {
  const classes = useStyles();
  return (
    <SbuxLayer isOpen={open}>
      <SbuxDialog
        toggleDialogOpen={toggleDialogOpen}
        isrenderCloseButton={true}
        isClickAwayEnabled={false}
      >
        <div className={classes.dialogRoot}>
          <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
            <SbuxTranslate>{heading}</SbuxTranslate>
          </SbuxHeading>
          <Divider />
          <div className={classes.dialogContent}>
            <Box sx={{ display: "flex", width: 300, flexDirection: "column" }}>
              <Box sx={{ flex: 1 }}>
                <SbuxTranslate>{content}</SbuxTranslate>
              </Box>
            </Box>
          </div>
          <div className={classes.dialogFooter}>
            <SbuxButton type="save" onClick={handleOkClick}>
              <SbuxTranslate>{okBtnText}</SbuxTranslate>
            </SbuxButton>
            <SbuxButton type="cancel" onClick={toggleDialogOpen}>
              <SbuxTranslate>{cancelBtnText}</SbuxTranslate>
            </SbuxButton>
          </div>
        </div>
      </SbuxDialog>
    </SbuxLayer>
  );
};

export default SbuxConfirmationDialog;
