import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SbuxSelect from "../../../../../components/SbuxSelect";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxCustomRow from "../../../../../components/SbuxTable/SbuxCustomRow";
import SbuxCustomTable from "../../../../../components/SbuxTable/SbuxCustomTable";
import SbuxSubTabs from "../../../../../components/SbuxTabs/SbuxSubTabs";
import ClosureMsgTimePicker from "./components/ClosureMsgTimePicker";
import ClosureMsgDailyFrequency from "./components/ClosureMsgDailyFrequency";
import ClosureMsgWeeklyFrequency from "./components/ClosureMessageWeeklyFrequency";
import ClosureMsgMonthlyFrequency from "./components/ClosureMsgMonthlyFrequency";
import ClosureMsgRangeRecurrence from "./components/ClosureMsgRangeRecurrence";
import ClosureMsgYearlyFrequency from "./components/ClosureMsgYearlyFrequency";
import ClosureAudioConfig from "../../common/ClosureAudioConfig";
import SbuxRadioGroup from "../../../../../components/SbuxRadioGroup";
import useTextTranslate from "../../../hooks/useTextTranslate";
import { closureColumns } from "../closureMsg/columns";
import { Box, Divider } from "@mui/material";
import moment from "moment-timezone";
import { closureRepeatEndDate, momentToISOString } from "../../utils";
import { CM_RECURRENCE_FREQUENCY_LIST } from "../../../../../constants";

const ClosureMsgAdd = ({
  row,
  business,
  languages,
  globalConfigUi,
  handleClosureMsgRow,
}) => {
  const {
    mediaMgt: { mediaMgtData },
  } = useSelector((state) => state);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimeZone, setStartTimeZone] = useState(
    globalConfigUi.timezones[3].value
  );
  const [endTimeZone, setEndTimeZone] = useState(
    globalConfigUi.timezones[3].value
  );

  const [businessId, setBusinessId] = useState(business[0].value);

  const [languageTabId, setLanguageTabId] = useState(languages[0].code);
  const [languageMessage, setLanguageMessage] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const [seriesSettingsType, setSeriesSettingsType] = useState(
    globalConfigUi.closure.series[0].value
  );
  const [recPatternOption, setRecPatternOption] = useState(
    CM_RECURRENCE_FREQUENCY_LIST[0].value
  );
  const descriptionPlaceHolder = useTextTranslate("Description");
  const [endByOption, setEndByOption] = useState("endByEndDate");
  const [endByEndDate, setEndByEndDate] = useState(closureRepeatEndDate());

  const [timeLable, setTimeLabel] = useState("");
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    const remainder = 30 - (moment().minute() % 30);
    const startTime = moment().add(remainder, "minutes").format("hh:mm A");
    setStartTime(startTime);
    const endTime = moment(startTime, "hh:mm A")
      .add("minutes", 30)
      .format("hh:mm A");
    setEndTime(endTime);
  }, []);

  useEffect(() => {
    if (timeLable === "Start Time") {
      const endTimeUpdate = moment(startTime, "hh:mm A")
        .add("minutes", 30)
        .format("hh:mm A");
      setEndTime(endTimeUpdate);
      const repeatPattern = {
        repeatPattern:
          rowData && rowData.hasOwnProperty("repeatPattern")
            ? { ...rowData.repeatPattern }
            : {
                frequency: CM_RECURRENCE_FREQUENCY_LIST[0].value,
                interval: 1,
              },
      };
      const addRow = {
        ...rowData,
        startTime: startTime,
        endTime: endTimeUpdate,
        ...repeatPattern,
        ...languageMessage,
      };
      setRowData(addRow);
      handleClosureMsgRow(addRow, true);
    }
  }, [startTime, endTime]);

  function createInitialObj() {
    const remainder = 30 - (moment().minute() % 30);
    const startTime = moment().add(remainder, "minutes").format("hh:mm A");
    const endTime = moment(startTime, "hh:mm A")
      .add(30, "minutes")
      .format("hh:mm A");

    return {
      hoursCode: business[0].value,
      groupName: business[0].name,
      category: "",
      type: globalConfigUi.closure.series[0].value,
      startDate: new Date(),
      endDate: new Date(),
      startTime,
      endTime,
      startTimeZone: globalConfigUi.timezones[3].value,
      endTimeZone: globalConfigUi.timezones[3].value,
      description: "",
    };
  }

  useEffect(() => {
    const initialObj = createInitialObj();
    setRowData({ ...initialObj, ...row });
  }, []);

  useEffect(() => {
    if (seriesSettingsType === "Repeat") {
      const repeatPattern = {
        ...rowData.repeatPattern,
        until: momentToISOString(endByEndDate),
      };
      setRowData({ ...rowData, repeatPattern });
    }
  }, [seriesSettingsType]);

  const handleBusinessChange = (value) => {
    setBusinessId(value);
    const addRow = {
      ...rowData,
      hoursCode: value,
      groupName: business.find((item) => item.value === value).name,
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleCategoryChange = (value) => {
    setCategoryId(value);
    const addRow = {
      ...rowData,
      category: value,
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleSeriesChange = (value) => {
    setSeriesSettingsType(value);
    if (value === "Repeat") {
      const addRow = {
        ...rowData,
        type: value,
        repeatPattern: {
          frequency: CM_RECURRENCE_FREQUENCY_LIST[0].value,
          interval: 1,
        },
      };
      setRowData(addRow);
      handleClosureMsgRow(addRow, true);
    }
  };

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    setDescriptionError(value.length < 1 ? true : false);
    const addRow = {
      ...rowData,
      description: value,
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleDateChange = (value) => {
    let addRow = null;
    if (value.name === "Start Time") {
      setStartDate(value.date);
      setEndDate(value.date);
      addRow = {
        ...rowData,
        startDate: value.date,
        endDate: value.date,
      };
    } else {
      setEndDate(value.date);
      addRow = {
        ...rowData,
        endDate: value.date,
      };
    }
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };
  const handleStartTimeChange = (value) => {
    setStartTime(value.time);
    setTimeLabel("Start Time");
    const addRow = {
      ...rowData,
      startTime: value.time,
      ...languageMessage,
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleEndTimeChange = (value) => {
    setEndTime(value.time);
    setTimeLabel("End Time");
    const addRow = {
      ...rowData,
      endTime: value.time,
      ...languageMessage,
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleTimeZoneChange = (timeZone) => {
    let addRow = null;
    setStartTimeZone(timeZone);
    setEndTimeZone(timeZone);
    addRow = {
      ...rowData,
      startTimeZone: timeZone,
      endTimeZone: timeZone,
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleRecPatternOptioneChange = (event) => {
    setRecPatternOption(event.target.value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        frequency: event.target.value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  //Daily
  const [dailyOption, setDailyOption] = useState("dailyOption1EveryDays");
  const [dailyOptoin1InputValue, setDailyOptoin1InputValue] = useState(1);

  const handleDailyOptionsValueChange = (event) => {
    let addRow = null;
    if (event.target.value === "dailyOption2EveryWeekDay") {
      addRow = {
        ...rowData,
        isDailyOption2Selected: true,
        repeatPattern: {
          ...rowData.repeatPattern,
          byWeekDay: ["MO", "TU", "WE", "TH", "FR"],
          until: momentToISOString(endByEndDate),
        },
      };
    } else {
      addRow = {
        ...rowData,
        isDailyOption2Selected: false,
        repeatPattern: {
          ...rowData.repeatPattern,
          interval: dailyOptoin1InputValue,
          until: momentToISOString(endByEndDate),
        },
      };
    }
    setDailyOption(event.target.value);
    setRowData(addRow);
    handleClosureMsgRow(addRow);
  };

  const handleDailyOptoin1InputValue = (value) => {
    setDailyOptoin1InputValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        interval: Number(value),
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  //Weelky

  const [weeklyRecEveryInputValue, setWeeklyRecEveryInputValue] = useState(1);
  const [weeklyRecDaysChecked, setWeeklyRecDaysChecked] = useState([]);

  const handleWeeklyRecEveryInputValueChange = (value) => {
    setWeeklyRecEveryInputValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        interval: Number(value),
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleCheckboxChange = (event) => {
    let data = [];
    if (event.target.checked) {
      const arr = [...weeklyRecDaysChecked];
      arr.push(event.target.name);
      data = [...arr];
      setWeeklyRecDaysChecked(data);
    } else {
      data = weeklyRecDaysChecked.filter((f) => f !== event.target.name);
      setWeeklyRecDaysChecked(data);
    }
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byWeekDay: data,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  //Monthly

  //Default value

  const [monthlyOption, setMonthlyOption] = useState("monthlyOption1Day");
  const [monthlyRecDayOfValue, setMonthlyRecDayOfValue] = useState(
    globalConfigUi.closure.monthly[0].value
  );
  const [monthlyRecWeekDays, setMonthlyRecWeekDays] = useState(
    globalConfigUi.weekdays[0].value
  );

  const [
    monthlyRecOption1EveryDayInputValue,
    setMonthlyRecOption1EveryDayInputValue,
  ] = useState(1);

  const [
    monthlyRecOption1EveryMonthsInputValue,
    setMonthlyRecOption1EveryMonthsInputValue,
  ] = useState(1);

  const [
    monthlyRecOption2EveryMonthsInputValue,
    setMonthlyMonthlyRecOption2EveryMonthsInputValue,
  ] = useState(1);

  const handleMonthlyOptionsValueChange = (event) => {
    let addRow = null;
    if (event.target.value === "monthlyOption2The") {
      addRow = {
        ...rowData,
        isMonthlyOption2Selected: true,
        repeatPattern: {
          ...rowData.repeatPattern,
          byWeekDay: [monthlyRecWeekDays],
          interval: monthlyRecOption2EveryMonthsInputValue,
          byWeekDayN: monthlyRecDayOfValue,
          until: momentToISOString(endByEndDate),
        },
      };
    } else {
      addRow = {
        ...rowData,
        isMonthlyOption2Selected: false,
        repeatPattern: {
          ...rowData.repeatPattern,
          byDay: monthlyRecOption1EveryDayInputValue,
          interval: monthlyRecOption1EveryMonthsInputValue,
          until: momentToISOString(endByEndDate),
        },
      };
    }
    setMonthlyOption(event.target.value);
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleMonthlyRecDayOfValueChange = (value) => {
    setMonthlyRecDayOfValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byWeekDayN: value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleMonthlyRecWeekDaysChange = (value) => {
    setMonthlyRecWeekDays(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byWeekDay: [value],
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleMonthlyRecOption1EveryDayInputValueChange = (value) => {
    setMonthlyRecOption1EveryDayInputValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byDay: value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };
  const handleMonthlyRecOption1EveryMonthsInputValueChange = (value) => {
    setMonthlyRecOption1EveryMonthsInputValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        interval: Number(value),
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };
  const handleMonthlyRecOption2EveryMonthsInputValueChange = (value) => {
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        interval: Number(value),
      },
    };
    setMonthlyMonthlyRecOption2EveryMonthsInputValue(value);
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  //Yearly
  const [yearlyOption, setYearlyOption] = useState("yearlyOption1On");
  const [yearlyRecEveryInputValue, setYearlyRecEveryInputValue] = useState(1);
  const handleYearlyRecEveryInputValueChange = (value) => {
    setYearlyRecEveryInputValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        interval: Number(value),
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const [yearlyOption1MonthValue, setYearlyOption1MonthValue] = useState(
    globalConfigUi.months[0].value
  );
  const handleYearlyOption1MonthValueChange = (value) => {
    setYearlyOption1MonthValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byMonth: value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const [yearlyOption1OnInputValue, setYearlyOption1OnInputValue] = useState(1);
  const handleYearlyOption1OnInputValueChange = (value) => {
    setYearlyOption1OnInputValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byDay: value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const [yearlyRecDayOfValue, setYearlyRecDayOfValue] = useState(
    globalConfigUi.closure.monthly[0].value
  );
  const [yearlyRecWeekDays, setYearlyRecWeekDays] = useState(
    globalConfigUi.weekdays[0].value
  );
  const handleYearlyRecDayOfValueChange = (value) => {
    setYearlyRecDayOfValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byWeekDayN: value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleYearlyRecWeekDaysChange = (value) => {
    setYearlyRecWeekDays(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byWeekDay: [value],
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };
  const [yearlyOption2MonthValue, setYearlyOption2MonthValue] = useState(
    globalConfigUi.months[0].value
  );
  const handleYearlyOption2MonthValueChange = (value) => {
    setYearlyOption2MonthValue(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        byMonth: value,
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  let addRow = null;
  const handleYearlyOptionsValueChange = (event) => {
    if (event.target.value === "yearlyOption2OnThe") {
      addRow = {
        ...rowData,
        isYearlyOption2Selected: true,
        repeatPattern: {
          ...rowData.repeatPattern,
          byWeekDay: [yearlyRecWeekDays],
          interval: yearlyRecEveryInputValue,
          byWeekDayN: yearlyRecDayOfValue,
          byMonth: yearlyOption2MonthValue,
        },
      };
    } else {
      addRow = {
        ...rowData,
        isYearlyOption2Selected: false,
        repeatPattern: {
          ...rowData.repeatPattern,
          byDay: yearlyOption1OnInputValue,
          byMonth: yearlyOption2MonthValue,
          interval: yearlyRecEveryInputValue,
        },
      };
    }
    setYearlyOption(event.target.value);
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  //Recurrence Date
  const handleEndByEndDateChange = (value) => {
    const endByEndDate = momentToISOString(value);
    setEndByEndDate(value);
    const addRow = {
      ...rowData,
      repeatPattern: {
        ...rowData.repeatPattern,
        until: momentToISOString(endByEndDate),
      },
    };
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const handleEndByOptionsValueChange = (event) => {
    let addRow = null;
    if (event.target.value === "endByEndDate") {
      addRow = {
        ...rowData,
        isReccuringOption2Selected: false,
        repeatPattern: {
          ...rowData.repeatPattern,
          until: momentToISOString(endByEndDate),
        },
      };
    } else {
      addRow = {
        ...rowData,
        isReccuringOption2Selected: true,
        repeatPattern: {
          ...rowData.repeatPattern,
        },
      };
    }
    setEndByOption(event.target.value);
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };
  //Media file
  const handleSubTabChange = (newValue) => {
    setLanguageTabId(newValue);
  };

  const handleLanguageMediaFile = (value) => {
    let messageLanguageTabId = `message_${languageTabId}`;
    let updateLanguageMessage = { ...languageMessage };
    let addRow = {
      ...rowData,
    };
    if (value) {
      const mediaInfo = mediaMgtData.data.find((m) => m.filePath === value);
      const newLanguageMessage = {
        [`${messageLanguageTabId}`]: {
          type: "AUDIO",
          value: value,
          accessType: mediaInfo.type,
        },
      };
      updateLanguageMessage = {
        ...updateLanguageMessage,
        ...newLanguageMessage,
      };
      addRow = {
        ...rowData,
        ...updateLanguageMessage, //This is required for button enabled or disabled validation
        languageMessage: { ...updateLanguageMessage }, // This is required adding new closure
      };
    } else {
      if (updateLanguageMessage[messageLanguageTabId]) {
        delete updateLanguageMessage[messageLanguageTabId];
      }
      addRow = {
        ...rowData,
        ...updateLanguageMessage, //This is required for button enabled or disabled validation
        languageMessage: { ...updateLanguageMessage }, // This is required adding new closure
      };
      if (addRow[messageLanguageTabId]) {
        delete addRow[messageLanguageTabId];
      }
    }
    setLanguageMessage(updateLanguageMessage);
    setRowData(addRow);
    handleClosureMsgRow(addRow, true);
  };

  const SubTabsComponent = () => {
    const updatedLanguages = languages.reduce((acc, cur) => {
      acc.push({
        name: cur.name,
        value: cur.code,
      });
      return acc;
    }, []);
    const handleSubTabClick = (tab) => {
      handleSubTabChange(tab.value);
    };
    return (
      <SbuxSubTabs
        tabList={updatedLanguages}
        tabId={languageTabId}
        handleTabClick={handleSubTabClick}
        width={"33vw"}
      />
    );
  };

  return (
    <Box sx={{ height: seriesSettingsType === "Repeat" ? "82vh" : "76vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          paddingTop: 0.5,
        }}
        noValidate
        autoComplete="off"
      >
        <SbuxCustomTable
          width="60vw"
          height={seriesSettingsType === "Repeat" ? "85vh" : "76vh"}
          columns={closureColumns}
          isTranslationRequired
          tableLayout="fixed"
        >
          <SbuxCustomRow
            name="Business"
            value={
              <SbuxSelect
                width={"16vw"}
                menuItems={business}
                defaultValue={businessId}
                value={businessId}
                handleSelectChange={(value) => handleBusinessChange(value)}
              />
            }
            isRequired
          />

          <SbuxCustomRow
            name="Category"
            value={
              <SbuxSelect
                width={"16vw"}
                menuItems={globalConfigUi.closure.category}
                defaultValue={categoryId}
                value={categoryId}
                handleSelectChange={(value) => handleCategoryChange(value)}
                required
              />
            }
            isRequired
          />

          <SbuxCustomRow
            name="Description"
            value={
              <Box
                sx={{
                  marginBottom: -1.5,
                  marginTop: -1,
                  marginLeft: -0.5,
                  "@media (min-width: 1023px)": {
                    "& .MuiTextField-root": {
                      width: "50ch",
                    },
                  },
                }}
              >
                <SbuxTextField
                  variant={"outlined"}
                  placeholder={descriptionPlaceHolder}
                  value={description}
                  handleChange={handleDescriptionChange}
                  errorMessage={"Description required at least 1 character"}
                  isError={descriptionError}
                  error={descriptionError}
                  required
                />
              </Box>
            }
            isRequired
          />

          <SbuxCustomRow
            name="Series Settings"
            value={
              <SbuxSelect
                width={"16vw"}
                menuItems={globalConfigUi.closure.series}
                defaultValue={seriesSettingsType}
                value={seriesSettingsType}
                handleSelectChange={(value) => handleSeriesChange(value)}
              />
            }
            isRequired
          />

          <SbuxCustomRow
            name={
              <Box sx={{ fontSize: 16, fontWeight: 600 }}>
                {`Date and Time`}
              </Box>
            }
          />

          <ClosureMsgTimePicker
            name="Start Time"
            startDate={startDate}
            endDate={endDate}
            startTimeValue={startTime}
            endTimeValue={endTime}
            timeZoneValue={startTimeZone}
            timeZoneList={globalConfigUi.timezones}
            handleDateChange={handleDateChange}
            handleStartTimeChange={handleStartTimeChange}
            handleTimeZoneChange={handleTimeZoneChange}
            row={rowData}
          />

          <ClosureMsgTimePicker
            name="End Time"
            startDate={startDate}
            endDate={endDate}
            startTimeValue={startTime}
            endTimeValue={endTime}
            timeZoneValue={endTimeZone}
            timeZoneList={globalConfigUi.timezones}
            handleDateChange={handleDateChange}
            handleEndTimeChange={handleEndTimeChange}
            handleTimeZoneChange={handleTimeZoneChange}
            row={rowData}
          />
          {seriesSettingsType === "Repeat" && (
            <>
              <SbuxCustomRow
                name="Repeat Pattern"
                value={
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: 130,
                    }}
                  >
                    <Box sx={{ width: 100 }}>
                      <SbuxRadioGroup
                        radioButtonList={CM_RECURRENCE_FREQUENCY_LIST}
                        value={recPatternOption}
                        handleRadioButtonChange={handleRecPatternOptioneChange}
                      />
                    </Box>

                    <Box sx={{ width: "1px" }}>
                      <Divider
                        orientation="vertical"
                        sx={{ backgroundColor: "#006241" }}
                      />
                    </Box>
                    <Box sx={{ width: "100%", flex: 3 }}>
                      {recPatternOption === "DAILY" && (
                        <ClosureMsgDailyFrequency
                          dailyOption={dailyOption} //Raido button value for Daily
                          handleDailyOptionsValueChange={
                            handleDailyOptionsValueChange
                          }
                          dailyOptoin1InputValue={dailyOptoin1InputValue}
                          handleDailyOptoin1InputValue={
                            handleDailyOptoin1InputValue
                          }
                        />
                      )}

                      {recPatternOption === "WEEKLY" && (
                        <ClosureMsgWeeklyFrequency
                          weeklyRecEveryInputValue={weeklyRecEveryInputValue}
                          handleCheckboxChange={handleCheckboxChange}
                          handleWeeklyRecEveryInputValueChange={
                            handleWeeklyRecEveryInputValueChange
                          }
                        />
                      )}

                      {recPatternOption === "MONTHLY" && (
                        <ClosureMsgMonthlyFrequency
                          monthlyOption={monthlyOption} //Raido button value for Monthly
                          handleMonthlyOptionsValueChange={
                            handleMonthlyOptionsValueChange
                          }
                          monthlyRecOption1EveryDayInputValue={
                            monthlyRecOption1EveryDayInputValue
                          }
                          monthlyRecOption1EveryMonthsInputValue={
                            monthlyRecOption1EveryMonthsInputValue
                          }
                          monthlyRecOption2EveryMonthsInputValue={
                            monthlyRecOption2EveryMonthsInputValue
                          }
                          monthlyRecDayOfList={globalConfigUi.closure.monthly}
                          monthlyRecDayOfValue={monthlyRecDayOfValue} //First, Last and etc
                          handleMonthlyRecDayOfValueChange={
                            handleMonthlyRecDayOfValueChange
                          }
                          monthlyRecWeekDaysList={globalConfigUi.weekdays}
                          monthlyRecWeekDays={monthlyRecWeekDays} //dropdown value (Sunday, Monday)
                          handleMonthlyRecWeekDaysChange={
                            handleMonthlyRecWeekDaysChange
                          }
                          handleMonthlyRecOption1EveryDayInputValueChange={
                            handleMonthlyRecOption1EveryDayInputValueChange
                          }
                          handleMonthlyRecOption1EveryMonthsInputValueChange={
                            handleMonthlyRecOption1EveryMonthsInputValueChange
                          }
                          handleMonthlyRecOption2EveryMonthsInputValueChange={
                            handleMonthlyRecOption2EveryMonthsInputValueChange
                          }
                        />
                      )}
                      {recPatternOption === "YEARLY" && (
                        <ClosureMsgYearlyFrequency
                          yearlyOption={yearlyOption}
                          yearlyMonthList={globalConfigUi.months}
                          yearlyRecWeekDaysList={globalConfigUi.closure.monthly}
                          yearlyWeekDaysList={globalConfigUi.weekdays}
                          handleYearlyOptionsValueChange={
                            handleYearlyOptionsValueChange
                          }
                          yearlyRecEveryInputValue={yearlyRecEveryInputValue}
                          handleYearlyRecEveryInputValueChange={
                            handleYearlyRecEveryInputValueChange
                          }
                          yearlyOption1MonthValue={yearlyOption1MonthValue}
                          handleYearlyOption1MonthValueChange={
                            handleYearlyOption1MonthValueChange
                          }
                          yearlyOption1OnInputValue={yearlyOption1OnInputValue}
                          handleYearlyOption1OnInputValueChange={
                            handleYearlyOption1OnInputValueChange
                          }
                          yearlyOption2MonthValue={yearlyOption2MonthValue}
                          handleYearlyOption2MonthValueChange={
                            handleYearlyOption2MonthValueChange
                          }
                          yearlyRecDayOfValue={yearlyRecDayOfValue}
                          handleYearlyRecDayOfValueChange={
                            handleYearlyRecDayOfValueChange
                          }
                          yearlyRecWeekDays={yearlyRecWeekDays}
                          handleYearlyRecWeekDaysChange={
                            handleYearlyRecWeekDaysChange
                          }
                        />
                      )}
                    </Box>
                  </Box>
                }
              />
              <SbuxCustomRow
                name={"End by"}
                value={
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginRight: 0.5 }}>
                      <ClosureMsgRangeRecurrence
                        endByOption={endByOption}
                        endByEndDate={endByEndDate}
                        isAdd={true}
                        handleEndByEndDateChange={handleEndByEndDateChange}
                        handleEndByOptionsValueChange={
                          handleEndByOptionsValueChange
                        }
                      />
                    </Box>
                  </Box>
                }
              />
            </>
          )}

          <SbuxCustomRow
            name={<Box sx={{ fontSize: 13 }}>{`Audio Configuration`}</Box>}
            value={
              <Box
                sx={{
                  display: "flex",
                  height: 165,
                }}
              >
                <Box sx={{ height: 165, border: "2px solid lightgrey" }}>
                  <SubTabsComponent />
                  <Box sx={{ width: "20vw", marginLeft: 2 }}>
                    <ClosureAudioConfig
                      languageId={languageTabId}
                      handleLanguageMediaFile={handleLanguageMediaFile}
                      row={rowData}
                    />
                  </Box>
                </Box>
              </Box>
            }
          />
        </SbuxCustomTable>
      </Box>
    </Box>
  );
};

export default ClosureMsgAdd;
