import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "74vh",
  },
  dataGridRoot: { 
    "& .MuiDataGrid-iconSeparator": {
      visibility: 'hidden'
    },
    display: "flex", 
    border: "0 !important",
    paddingTop: "5px"
  },
}));
export default useStyles;
