export const columns = [
  {
    label: "",
    id: "sno",
    flex: 1,
  },
  {
    label: "Channel",
    id: "channel",
    flex: 1,
    sortable: true,
  },
  {
    label: "Contact Id",
    id: "contactId",
    flex: 1,
    sortable: true,
  },
  {
    label: "Event Type",
    id: "eventType",
    flex: 1,
    height: 20,
    sortable: true,
  },
  {
    label: "Initial ContactId",
    id: "initialContactId",
    minWidth: 320,
    sortable: true,
  },
  {
    label: "Initiation Method",
    id: "initiationMethod",
    minWidth: 320,
    sortable: true,
  },
  {
    label: "Instance Id",
    id: "instanceId",
    minWidth: 320,
    sortable: true,
  },
  {
    label: "Previous ContactId",
    id: "previousContactId",
    minWidth: 320,
    sortable: true,
  },
];
