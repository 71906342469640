import React, { useState, useEffect } from "react";
import SbuxTranslate from "../SbuxTranslate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { TabScrollButton } from "@mui/material";
import SbuxLoadingIndicator from "../SbuxLoadingIndicator";
import { withStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";

import useStyles from "./styles";

const SbuxTabs = ({ tabList, tabPanelList, handleTabChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    tabs: { activeTabId, tabsStabus },
    ccp: {
      ccpAgent: { queues },
      isCcpInitialized,
    },
    userInstance: { connectedInstance },
  } = useSelector((state) => state);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(tabList);
  }, [tabList]);

  const tabScrollButton = withStyles((theme) => ({
    root: {
      width: "20px !important",
      overflow: "hidden",
      transition: "width .1s",
      "&.Mui-disabled": {
        width: 0,
      },
    },
  }))(TabScrollButton);

  const renderTabList = () => (
    <Box sx={{ width: "100%" }}>
      <Tabs
        sx={{
          "&.MuiTabs-root": {
            minHeight: "37px !important",
          },
        }}
        value={activeTabId}
        onChange={handleTabChange}
        variant="scrollable"
        allowScrollButtonsMobile
        ScrollButtonComponent={tabScrollButton}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {tabs.map(
          (tab, index) =>
            tab.visible && (
              <Tab
                sx={{ minHeight: "37px", height: "37px", fontSize: 16 }}
                label={<SbuxTranslate>{tab.label}</SbuxTranslate>}
                value={tab.tabId}
                key={index}
                className={classes.rootTab}
                disabled={tabsStabus === "enabled" ? false : true}
              />
            )
        )}
      </Tabs>
    </Box>
  );

  const openURLsInNewTab = (urls) => {
    urls.forEach((url) => {
      window.open(url, "_blank");
    });
  };

  useEffect(() => {
    if (tabsStabus === "enabled" && isCcpInitialized) {
      const autoOpenURLs = connectedInstance?.autoOpenTargetURLs;
      if (autoOpenURLs && autoOpenURLs.length > 0) {
        openURLsInNewTab(autoOpenURLs);
      }
    }
  }, [tabsStabus, isCcpInitialized]);

  return (
    <>
      {isCcpInitialized && (
        <SbuxLoadingIndicator
          isLoading={tabsStabus === "enabled" ? false : true}
        />
      )}
      <Box>{renderTabList()}</Box>
      {tabPanelList.map(
        (tabPanel, index) =>
          tabPanel.visible && (
            <Box
              key={index}
              hidden={activeTabId !== tabPanel.tabPanelId}
              value={activeTabId}
            >
              {tabPanel.component}
            </Box>
          )
      )}
    </>
  );
};

export default React.memo(SbuxTabs);
