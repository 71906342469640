import { useState, useEffect } from "react";
import SbuxSelect from "../../../../../components/SbuxSelect";
import SbuxButtonGroup from "../../../../../components/SbuxButtonGroup";
import { Box } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxMediaRecorder from "../../../../../components/SbuxMediaRecorder";
import MediaMgtFileUpload from "./MediaMgtFileUpload";
import useTextTranslate from "../../../hooks/useTextTranslate";
import { useSelector } from "react-redux";
import { MEDIAMGT_FILE_NAME_ERROR } from "../../../../../constants";
import MediaTextToSpeech from "./MediaTextToSpeech";
import { getTextLengthErrorMessage } from "../../utils";

const MediaMgtAdd = ({
  languages,
  row,
  handleMediaMgtRow,
  handleMediaInfo,
  buttonList,
  handleDisableSaveBtn,
}) => {
  const {
    mediaMgt: {
      mediaMgtData: { data: mediaMgtRows },
    },
  } = useSelector((state) => state);
  const [toggleButton, setToggleButton] = useState(1);
  const [languageCode, setLanguageCode] = useState(languages[0].value);
  const [promptName, setPromptName] = useState("");
  const [description, setDescription] = useState("");
  const [fileNameError, setFileNameError] = useState(false);
  const [fileNameLengthError, setFileNameLengthError] = useState(false);
  const [descriptionLengthError, setDescriptionLengthError] = useState(false);
  const [searchRowData, setSearchRowData] = useState([]);

  useEffect(() => {
    setSearchRowData(mediaMgtRows);
  }, [mediaMgtRows]);

  const handleToggle = (newValue) => {
    setToggleButton(newValue);
  };

  const fileNamePlaceHolder = useTextTranslate("File Name");
  const descriptionPlaceHolder = useTextTranslate("Description");
  const handleLanguageChange = (value) => {
    const result = languages.find((f) => f.value === value);
    setLanguageCode(value);
    row.languageCode = value;
    row.language = result.name;
    handleMediaMgtRow(row);
  };

  const handlePromptChange = (event) => {
    const value = event.target.value;
    if (
      searchRowData.some(
        (row) => row.promptName.toLowerCase() === value.toLowerCase()
      )
    ) {
      setFileNameError(true);
      row.promptName = "";
    } else {
      setFileNameError(false);
      row.promptName = value;
    }
    let isError = false;
    if (value.length > 50) {
      isError = true;
    }
    setFileNameLengthError(isError);
    setPromptName(value);

    handleMediaMgtRow(row, isError || descriptionLengthError);
  };

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    let isError = false;
    if (value.length > 150) {
      isError = true;
    }
    setDescriptionLengthError(isError);

    setDescription(value);
    row.description = value;
    handleMediaMgtRow(row, isError || fileNameLengthError);
  };

  const getErrorMessage = () => {
    if (fileNameError) {
      return MEDIAMGT_FILE_NAME_ERROR;
    }
    if (fileNameLengthError) {
      return getTextLengthErrorMessage(50);
    }
  };

  return (
    <Box sx={{ minHeight: "70vh" }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 0.5,
          "& .MuiTextField-root": {
            width: "70ch",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ marginLeft: 0.5, marginBottom: 1 }}>
          <Box
            sx={{
              fontSize: 13,
              color: "#006241",
              fontWeight: 600,
              paddingBottom: 0.5,
            }}
          >
            <SbuxTranslate>{`Step 1: Select the language`}</SbuxTranslate>
          </Box>
          <SbuxSelect
            menuItems={languages}
            defaultValue={languageCode}
            value={languageCode}
            handleSelectChange={(value) => handleLanguageChange(value)}
          />
        </Box>
        <Box sx={{ marginLeft: 0.5, minHeight: 210 }}>
          <Box
            sx={{
              fontSize: 13,
              color: "#006241",
              fontWeight: 600,
              paddingBottom: 0.5,
            }}
          >
            <SbuxTranslate>{`Step 2: Upload or record a file`}</SbuxTranslate>
            <Box
              sx={{
                marginTop: 1,
                width: "100%",
                height: 300,
                border: "1px solid lightgrey",
                borderRadius: 2,
              }}
            >
              <SbuxButtonGroup
                buttonList={buttonList}
                value={toggleButton}
                handleButtonToggle={handleToggle}
                height={26}
              />
              {toggleButton === 1 && (
                <MediaMgtFileUpload
                  row={row}
                  handleMediaInfo={handleMediaInfo}
                />
              )}
              {toggleButton === 2 && (
                <SbuxMediaRecorder
                  row={row}
                  handleMediaInfo={handleMediaInfo}
                />
              )}
              {toggleButton === 3 && (
                <MediaTextToSpeech
                  selectedLanguageCode={languageCode}
                  handleMediaInfo={handleMediaInfo}
                  handleDisableSaveBtn={handleDisableSaveBtn}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            placeholder={fileNamePlaceHolder}
            label={
              <SbuxTranslate>{`Step 3: Please enter a file name`}</SbuxTranslate>
            }
            value={promptName}
            handleChange={handlePromptChange}
            errorMessage={getErrorMessage()}
            maxLength="50"
            isError={fileNameError || fileNameLengthError}
            error={fileNameError || fileNameLengthError}
            displayCounter={true}
          />
        </Box>
        <Box>
          <SbuxTextField
            variant={"outlined"}
            placeholder={descriptionPlaceHolder}
            label={
              <SbuxTranslate>{`Step 4: Description (Optional)`}</SbuxTranslate>
            }
            value={description}
            handleChange={handleDescriptionChange}
            errorMessage={getTextLengthErrorMessage(150)}
            isError={descriptionLengthError}
            error={descriptionLengthError}
            maxLength="150"
            displayCounter={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MediaMgtAdd;
