import { BorderBottom } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ padding = null }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundImage: "linear-gradient(#006241, #1a7254)",
    color: "#f2f0eb",
    fontSize: 15,
    padding: "6px",
    fontWeight: 500,
    borderRight: "1px solid white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: padding ? padding : "6px",
  },
}));

export default StyledTableCell;
