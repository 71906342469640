import React from "react";
import { StyledDataGrid } from "./styled";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import useStyles from "./styles";

const SbuxDataGrid = ({
  rows,
  columns,
  handleEditRowsModelChange,
  handleIsCellEditable,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StyledDataGrid
        className={classes.dataGridRoot}
        autoHeight={true}
        rows={rows}
        columns={columns}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        hideFooterSelectedRowCount
        onEditRowsModelChange={handleEditRowsModelChange}
        isCellEditable={handleIsCellEditable}
        disableSelectionOnClick
        hideFooterPagination
      />
    </div>
  );
};

export default React.memo(SbuxDataGrid);
