import SbuxHeaderCrate from "../components/SbuxHeaderCrate";
import { useSelector } from "react-redux";
import CCP from "../features/ccp";
import SbuxFallback from "../components/SbuxFallback";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "../services/logging";
import useStyles from "./styles";

const HeaderCrate = () => {
  const classes = useStyles();
  const { status, instances, connectedInstance } = useSelector(
    (state) => state.userInstance
  );

  const renderFallback = () => {
    clientLog({
      component: `HeaderCreate`,
      message: `There was no instance provided.`,
    });
    logMessage(`HeaderCreate`, `${`There was no instance provided.`}`, `error`);
    return (
      <div className={classes.container}>
        <div className={classes.heading}>
          <SbuxFallback />
        </div>
      </div>
    );
  };
  return (
    <SbuxHeaderCrate>
      {connectedInstance ? (
        <CCP />
      ) : (
        (status === "success" || status === "rejected") &&
        instances.length === 0 &&
        renderFallback()
      )}
    </SbuxHeaderCrate>
  );
};

export default HeaderCrate;
