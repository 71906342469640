import HeaderCrate from "@cxeweb/pattern-library/lib/components/header-crate";
import classNames from "classnames";
import useStyles from "./styles";
const SbuxHeaderCrate = ({ children }) => {
  const classes = useStyles();
  return (
    <HeaderCrate
      className={classNames("flex-shrink-none", classes.root)}
      hasGlobalNav={true}
    >
      <HeaderCrate.Inner className={classes.innerHeader}>
        {children}
      </HeaderCrate.Inner>
    </HeaderCrate>
  );
};
export default SbuxHeaderCrate;
