import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const SbuxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    border: "1px solid #d6d1d1de",
    backgroundColor: "#fff",
    color: "#000000de",
    padding: "5px",
    fontSize: "9px",
  },
}));

export default React.memo(SbuxTooltip);
