import { Auth } from "aws-amplify";
import { AUTH_HEADER_NAME } from "../../constants";
import { logMessage } from "../../utils/amplifyLogger";

export const jwtToken = async () => {
  try {
    const session = await Auth.currentSession();
    if (!session) {
      throw new Error(`Something went wrong in Session`);
    }
    const jwtToken = session.getIdToken().getJwtToken();
    return {
      headers: {
        [AUTH_HEADER_NAME]: jwtToken,
      },
    };
  } catch (err) {
    logMessage(
      `JWT Token`,
      `Could not get the jwt token due to error => ${err}`,
      `error`
    );
  }
};
