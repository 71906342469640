import LoadingCoffeeCup from "@cxeweb/pattern-library/lib/components/loading-coffee-cup";
import useStyles from "./styles";

const SbuxLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
    <div className={classes.center}>
      <LoadingCoffeeCup title="Loading animation" />
    </div>
    </div>
  );
};

export default SbuxLoader;
