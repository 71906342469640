export const dataMgtColumns = [
  {
    label: "Name",
    id: "name",
    minWidth: 100,
    sortable: false,
  },
  {
    label: "Value",
    id: "value",
    minWidth: 500,
    sortable: false,
  },
];

export const externalTransferColumns = [
  {
    lockedForEdit: true,
    uiPosition: 1,
    partitionKey: true,
    name: "var_externalTransfer",
    type: "alpha",
    friendlyName: "External Transfer",
    maxLength: 20,
  },
  {
    name: "var_dtmf",
    uiPosition: 2,
    type: "alpha",
    friendlyName: "DTMF",
    maxLength: 4,
  },
  {
    name: "var_dtmfrouting",
    uiPosition: 3,
    type: "boolean",
    friendlyName: "DTMF Routing",
    required: true,
  },
  {
    name: "var_externalNumber",
    uiPosition: 4,
    type: "numeric",
    friendlyName: "External Number",
    maxLength: 10,
  },
];

export const vipJobListColumns = [
  {
    lockedForEdit: true,
    uiPosition: 1,
    partitionKey: true,
    name: "var_jobNumber",
    type: "numeric",
    friendlyName: "Job Number",
    maxLength: 6,
  },
  {
    name: "var_isVIP",
    uiPosition: 2,
    hideOnUi: false,
    type: "boolean",
    friendlyName: "Is VIP?",
    required: true,
  },
  {
    name: "var_title",
    uiPosition: 3,
    type: "alpha",
    friendlyName: "Title",
    required: false,
    maxLength: 25,
  },
];
