import { useCallback } from "react";
import SbuxCard from "../../../components/SbuxCard";
import SbuxDataGrid from "../../../components/SbuxDataGrid";
import SbuxTranslate from "../../../components/SbuxTranslate";
import { CURRENT_TICKET_OR_CASE_NUMBER } from "../../../constants";

const CurrentDataGrid = ({ rows, columns }) => {
  const handleIsCellEditable = useCallback((params) => {
    return params.row.name === CURRENT_TICKET_OR_CASE_NUMBER;
  }, []);

  const getTranslatedColumns = () => {
    return columns.map((column) => {
      return {
        ...column,
        headerName: <SbuxTranslate>{column.headerName}</SbuxTranslate>,
      };
    });
  };
  return (
    <SbuxCard className="insideTabContainer">
      <SbuxDataGrid
        columns={getTranslatedColumns()}
        rows={rows}
        handleIsCellEditable={handleIsCellEditable}
      />
    </SbuxCard>
  );
};

export default CurrentDataGrid;
