import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
  },
  input: {
    padding: 0,
  },
  error: {
    color: "red",
    paddingLeft: 5,
  },
  inputContainer: {
    display: "contents",
  },
  inputError: {
    "& .MuiInput-input": {
      marginTop: "-3px",
      paddingLeft: "4px",
      borderRadius: "4px",
      border: "1px solid red !important",
    },
  },
}));

export default useStyles;
