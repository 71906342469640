import SbuxContentCrate from "../components/SbuxContentCrate";
import SbuxCard from "../components/SbuxCard";
import { useSelector } from "react-redux";
import Content from "../features/content";
import ContentTabs from "../features/content/ContentTabs";

const ContentCrate = () => {
  const { status, instances, connectedInstance } = useSelector(
    (state) => state.userInstance
  );
  return (
    <SbuxContentCrate>
      <SbuxCard>
        {connectedInstance ? (
          <Content />
        ) : (
          (status === "success" || status === "rejected") &&
          instances.length === 0 && <ContentTabs />
        )}
      </SbuxCard>
    </SbuxContentCrate>
  );
};

export default ContentCrate;
