import ClosureMsgWeeklyCheckbox from "./ClosureMsgWeeklyCheckbox";
import { Box } from "@mui/material";

const ClosureMsgWeeklyFrequency = ({
  isEdit = false,
  weeklyRecDays,
  weeklyRecEveryInputValue,
  handleWeeklyRecEveryInputValueChange,
  handleCheckboxChange,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ flex: 1, marginLeft: 3 }}>
        <Box
          sx={{
            display: "flex",
            height: 22,
          }}
        >
          <Box
            sx={{
              marginTop: 0.4,
              fontSize: 13,
              width: 80,
            }}
          >
            {"Recur every"}
          </Box>
          <Box>
            <input
              type={"number"}
              value={weeklyRecEveryInputValue}
              style={{
                width: 40,
                height: 24,
                fontSize: 12,
              }}
              onChange={(event) =>
                handleWeeklyRecEveryInputValueChange(event.target.value)
              }
            />
          </Box>
          <Box sx={{ marginTop: 0.4, fontSize: 13, marginLeft: 1, width: 200 }}>
            {"week(s) on:"}
          </Box>
        </Box>
      </Box>

      <Box sx={{ flex: 1, marginLeft: 3, marginTop: 1 }}>
        <ClosureMsgWeeklyCheckbox
          isEdit={isEdit}
          weeklyRecDays={weeklyRecDays}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Box>
    </Box>
  );
};

export default ClosureMsgWeeklyFrequency;
