import { useEffect, useCallback, useState } from "react";
import {
  CONTACT_CONNECTING_STATUS,
  CONTACT_CLOSED_STATUS,
  CURRENT_TICKET_OR_CASE_NUMBER,
  CONTACT_ENDED_STATUS,
  CALL_METHOD_OUTBOUND,
  AUDIO_FILE,
} from "../../constants";
import { getCcpContact, getCcpContactStatus } from "../../services/ccp";
import { getCallEndedStatus } from "../../services/outboundCall";
import {
  getTicketOrCaseNumberDefault,
  getTicketOrCaseNumber,
  saveContactToHistoryAction,
} from "../../services/current";
import { getResetTimers } from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import ContentTabs from "./ContentTabs";
import { logMessage } from "../../utils/amplifyLogger";
import useBrowserTabClose from "../content/hooks/useBrowserTabClose";
import { getAttributesWithMissedCallStatus } from "../ccp/utils";
import { clearCCPContact } from "../../slices/ccpSlice";

const Content = () => {
  const dispatch = useDispatch();
  const {
    auth: { cognitoUserId },
    ccp: { isCcpInitialized, ccpContactStatus },
    userInstance: { connectedInstance },
  } = useSelector((state) => state);
  const instanceUrl = connectedInstance ? connectedInstance.instanceUrl : null;

  const [contactEndedAttributes, setContactEndedAttributes] = useState({
    ended: false,
    contact: null,
  });

  useEffect(() => {
    if (contactEndedAttributes?.ended) {
      handleContactEnded(contactEndedAttributes?.contact);
    }
  }, [contactEndedAttributes]);

  //useBrowserTabClose hooks setAgentOffline when the user close  windnow/tab.
  useBrowserTabClose(instanceUrl, cognitoUserId);

  const callMethod = (contact) => {
    const attributes = contact?.getAttributes();
    if (!!attributes && !!attributes.Method) {
      return attributes?.Method?.value;
    }

    return "NONE";
  };

  const contactStatus = (contact) => {
    const { type } = contact.getStatus();

    return type;
  };
  useEffect(() => {
    isCcpInitialized &&
      window.connect.contact((contact) => {
        contact.onRefresh(handleContactRefresh);
        contact.onConnecting(handleContactConnecting);
        contact.onConnected(handleContactConnected);
        contact.onIncoming(handleContactIncoming);
        contact.onMissed(handleContactMissed);
        contact.onEnded((contact) =>
          setContactEndedAttributes({ ended: true, contact })
        );
        contact.onDestroy((contact) =>
          setContactEndedAttributes({ ended: true, contact })
        );
        contact.onError(handleContactError);
      });
    isCcpInitialized &&
      window.connect.agent((agent) =>
        agent.onStateChange(handleAgentStateChange)
      );
  }, [isCcpInitialized]);

  const handleContactRefresh = useCallback(
    async (contact) => {
      const contactAttributes = contact.getAttributes();
      if (Object.keys(contactAttributes).length > 0) {
        const ticketOrCaseNumberAttribute = Object.values(
          contactAttributes
        ).find((attribute) => attribute.name === CURRENT_TICKET_OR_CASE_NUMBER);
        // Make sure the call isn't ended
        !ccpContactStatus &&
          Object.entries(contactAttributes).length !== 0 &&
          contact.getStatus().type === CONTACT_CONNECTING_STATUS &&
          //PR: dispatch only once per contact
          (await dispatch(
            getCcpContact({
              contactId: contact.contactId,
              contactAttributes: contactAttributes,
              contactData: null,
            })
          ));
      }
    },
    [ccpContactStatus]
  );

  const handleContactConnecting = useCallback(async (contact) => {
    await dispatch(
      getCcpContactStatus({
        status: contactStatus(contact),
        method: callMethod(contact),
      })
    );
  }, []);

  const handleContactConnected = useCallback(async (contact) => {
    await dispatch(
      getCcpContactStatus({
        status: contactStatus(contact),
        method: callMethod(contact),
      })
    );

    if (callMethod(contact) === CALL_METHOD_OUTBOUND) {
      const audio = new Audio(AUDIO_FILE.BEEP);
      audio.load();
      audio.play();
    }
  }, []);

  const handleContactEnded = async (contact) => {
    if (!contact) {
      return;
    }
    let audio = null;
    if (!!contact) {
      dispatch(
        getCcpContactStatus({
          status: CONTACT_CLOSED_STATUS,
          method: undefined,
        })
      );
    }
    if (contact && !contact.hasOwnProperty("contactData")) {
      audio = new Audio(AUDIO_FILE.CALL_ENDED);
      audio.load();
      audio.play();
      dispatch(
        getCcpContactStatus({
          status: CONTACT_ENDED_STATUS,
          method: callMethod(contact),
        })
      );
    }
    if (contact && contact.contactData) {
      await dispatch(
        getCcpContact({
          contactId: contact.contactId,
          contactData: contact.contactData,
        })
      );
      await dispatch(
        saveContactToHistoryAction({
          history: true,
          historyData: contact.contactData,
        })
      );
      await dispatch(clearCCPContact());
    }
    await dispatch(getCallEndedStatus());
    setContactEndedAttributes({ ended: false, contact: null });
  };

  const handleContactIncoming = useCallback(async (contact) => {
    const tasks = [
      (dispatch(
        getCcpContactStatus({
          status: contactStatus(contact),
          method: callMethod(contact),
        })
      ),
      dispatch(
        getCcpContact({
          contactId: contact.contactId,
          contactAttributes: contact.getAttributes(),
          contactData: contact.contactData,
        })
      )),
    ];

    await Promise.all(tasks);
  }, []);

  const handleContactMissed = useCallback(async (contact) => {
    const contactAttributes = getAttributesWithMissedCallStatus(contact);
    dispatch(
      getCcpContact({
        contactId: contact.contactId,
        contactAttributes: contactAttributes,
        contactData: contact.contactData,
      })
    );
  }, []);

  const handleContactError = useCallback(async (contact) => {
    logMessage(`Content Page`, `Contact Error. ${contact}`, `error`);
  }, []);

  const handleAgentStateChange = useCallback(async (agent) => {
    if (agent.oldState !== agent.newState) {
      await dispatch(getResetTimers(true));
    }
  }, []);

  return <ContentTabs />;
};

export default Content;
