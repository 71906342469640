import { styled } from "@mui/material/styles";
import Button from "@cxeweb/pattern-library/lib/components/button";
import {
  COLOR_DARK_BLACK,
  COLOR_LIGHT_BLACK,
  COLOR_DARK_RED,
  COLOR_LIGHT_GREEN,
  COLOR_GREEN_ACCENT,
  COLOR_NEUTURAL_WARM,
  BUTTON_TYPE_SAVE,
  BUTTON_TYPE_EDIT,
  BUTTON_TYPE_DELETE,
  BUTTON_TYPE_CANCEL,
  BUTTON_TYPE_NAV,
} from "../../constants";

const StyledSbuxButton = styled(Button)((props) => ({
  fontSize: props.type === BUTTON_TYPE_NAV ? 14 : 13,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: props.type === BUTTON_TYPE_CANCEL ? props.height : props.height + 1.3,
  marginRight: props.type === BUTTON_TYPE_NAV ? "1.66rem" : 3,
  width: props.width,
  whiteSpace: "nowrap",
  padding: "0px !important",
  backgroundColor:
    props.type === BUTTON_TYPE_SAVE || props.type === BUTTON_TYPE_EDIT
      ? COLOR_GREEN_ACCENT
      : props.type === BUTTON_TYPE_DELETE
      ? COLOR_DARK_RED
      : "none",
  color:
    props.type === BUTTON_TYPE_CANCEL
      ? COLOR_GREEN_ACCENT
      : props.type === BUTTON_TYPE_NAV
      ? COLOR_LIGHT_BLACK
      : COLOR_NEUTURAL_WARM,
  "&:focus": {
    outline: 0,
  },
  "&:hover": {
    outline: 0,
    backgroundColor:
      props.type === BUTTON_TYPE_SAVE || props.type === BUTTON_TYPE_EDIT
        ? `${COLOR_GREEN_ACCENT} !important`
        : props.type === BUTTON_TYPE_DELETE
        ? `${COLOR_DARK_RED} !important`
        : props.type === BUTTON_TYPE_CANCEL
        ? `${COLOR_LIGHT_GREEN} !important`
        : `${COLOR_DARK_BLACK} !important`,
    color:
      props.type === BUTTON_TYPE_CANCEL
        ? COLOR_GREEN_ACCENT
        : props.type === BUTTON_TYPE_NAV
        ? COLOR_LIGHT_BLACK
        : COLOR_NEUTURAL_WARM,
  },
  "@media only screen and (max-width: 1023px) and (min-width: 400px)": {
    width: 130,
    marginRight: 1,
  },
}));

export default StyledSbuxButton;
