export const iconButton = {
  cursor: "pointer",
  color: "red",
  fontSize: 16,
  width: "2vw",
  marginTop: -0.5,
  height: 18,
  "&:focus": {
    outline: 0,
  },
  "&.MuiIconButton-root": {
    padding: 0,
    paddingLeft: "5px",
  },
};
