import { useState } from "react";
import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxButton from "../../../../components/SbuxButton";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import { Divider, Box } from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import useStyles from "../styles";

const ClosureMsgCustomFilter = ({
  title,
  open,
  handleCustomFilterOkClick,
  toggleOpen,
}) => {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleFromDateChange = (value) => {
    setFromDate(value);
    setToDate(value);
  };
  const handleToDateChange = (value) => {
    setToDate(value);
  };

  const handleCustomOkClick = () => {
    handleCustomFilterOkClick(fromDate, toDate);
    toggleOpen();
  };

  return (
    <>
      <SbuxLayer isOpen={open}>
        <SbuxDialog
          toggleDialogOpen={toggleOpen}
          isrenderCloseButton={true}
          isClickAwayEnabled={true}
        >
          <div className={classes.dialogRoot}>
            <SbuxHeading tagName="h6" size="xs" className={classes.dialogTitle}>
              {title}
            </SbuxHeading>
            <Divider />
            <div className={classes.dialogContent}>
              <Box sx={{ display: "flex", minWidth: 500, maxHeight: "30vh" }}>
                <Box sx={{ fontSize: 14, marginRight: 0.5 }}>{"From"}</Box>
                <Box
                  sx={{
                    flex: 1,
                    marginTop: 0.1,
                    marginRight: 1,
                    minHeight: 300,
                  }}
                >
                  <DatePicker
                    className={classes.datePicker}
                    selected={fromDate}
                    minDate={moment().subtract(90, "days").toDate()}
                    onChange={(date) => handleFromDateChange(date)}
                    timeFormat="YYYY-MM-DD"
                  />
                </Box>
                <Box sx={{ fontSize: 14, marginRight: 0.5 }}>{"To"}</Box>
                <Box sx={{ flex: 1, marginTop: 0.1, marginRight: 1 }}>
                  <DatePicker
                    className={classes.datePicker}
                    selected={toDate}
                    minDate={moment(fromDate).add(1, "days").toDate()}
                    maxDate={moment(fromDate).add(31, "days").toDate()}
                    onChange={(date) => handleToDateChange(date)}
                    timeFormat="YYYY-MM-DD"
                  />
                </Box>
              </Box>
            </div>
            <div className={classes.dialogFooter}>
              <SbuxButton type="cancel" onClick={toggleOpen}>
                <SbuxTranslate>{`Cancel`}</SbuxTranslate>
              </SbuxButton>
              <SbuxButton type="save" onClick={handleCustomOkClick}>
                <SbuxTranslate>{`Okay`}</SbuxTranslate>
              </SbuxButton>
            </div>
          </div>
        </SbuxDialog>
      </SbuxLayer>
    </>
  );
};

export default ClosureMsgCustomFilter;
