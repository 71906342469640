import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSvgIcon-root": {
    fontSize: "3rem",
    color: "#fff",
    right: 1,
    paddingBottom: 0,
  },
  position: "absolute",
  width: "100%",
  height: 27,
  color: "#fff",
  fontSize: 14,
  paddingTop: 4,
  paddingBottom: 4,
  marginRight: 4,

  "& .MuiSelect-select": {
    paddingLeft: "4px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid  #fff !important",
  },
}));

export default StyledSelect;
