import { useEffect, useState, useCallback } from "react";
import MasterRow from "../table/MasterRow";
import DetailRow from "../table/DetailRow";
import SbuxHeading from "../../../../components/SbuxHeading";
import SbuxLayer from "../../../../components/SbuxLayer";
import SbuxDialog from "../../../../components/SbuxDialog";
import SbuxSnackbars from "../../../../components/SbuxSnackbars";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import PhoneNumberDialog from "../common/PhoneNumberDialog";
import DetailTable from "../table/DetailTable";
import Link from "@mui/material/Link";
import { phoneNumberDetail } from "../../../../utils/phoneNumber";
import { useSelector } from "react-redux";
import {
  COPIED_TO_CLIPBOARD,
  HISTORY_NOT_A_VALID_PHONE_NUMBER,
  SNACK_BARS_SEVERITY_ERROR,
  SNACK_BARS_SEVERITY_SUCCESS,
  TRANSCRIPTS_FEATURE_FLAG_NAME,
  TRANSCRIPT_PARTICIPANTS,
} from "../../../../constants";
import useStyles from "../styles";
import TranscriptContent from "../transcript";
import { useDispatch } from "react-redux";
import { getTranscriptDataByContactId } from "../../../../services/history";
import { formatMillisToMinutesAndSeconds } from "../../../../utils/dateUtil";
import { omitKeyFromObject } from "../utils";

const Row = ({ row }) => {
  const classes = useStyles();
  const {
    ccp: { ccpValidationButtonStatus },
    userInstance: {
      connectedInstance: {
        appConfig: { transcriptsBucketName },
      },
    },
    history: {
      updatedTicketOrCaseNumber,
      timestamp,
      status,
      historyDialNumberValidateStatus,
    },
    featureFlags: { featureFlags },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openTranscriptDialog, setOpenTranscriptDialog] = useState(false);
  const [openDialPrompt, setOpenDialPrompt] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showTranscriptError, setShowTranscriptError] = useState(false);
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState(null);
  const [rowData, setRowData] = useState(row);
  const [showTranscriptCopiedSnackbar, setShowTranscriptCopiedSnackbar] =
    useState(false);

  const areTranscriptsEnabledForInstance =
    featureFlags?.find((ff) => ff?.name === TRANSCRIPTS_FEATURE_FLAG_NAME)
      ?.enabled ?? false;

  useEffect(async () => {
    if (status === "updated" && rowData.timestamp === timestamp) {
      let rowUpdate = { ...rowData };
      rowUpdate["Ticket or Case #"] = updatedTicketOrCaseNumber;
      setRowData(rowUpdate);
    }
  }, [status, updatedTicketOrCaseNumber]);

  useEffect(() => {
    if (historyDialNumberValidateStatus === "failure") {
      setOpenSnackbar(true);
    }
  }, [historyDialNumberValidateStatus]);

  const handleDialNumber = async (parsedPhoneNumber) => {
    setOpenDialPrompt(true);
    setParsedPhoneNumber(parsedPhoneNumber);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setShowTranscriptError(false);
    setShowTranscriptCopiedSnackbar(false);
  };

  const linkRenderer = (value) => {
    const parsedPhoneNumber = phoneNumberDetail(value);
    return !isNaN(value) ? (
      <Link
        component="button"
        disabled={ccpValidationButtonStatus}
        onClick={() => handleDialNumber(parsedPhoneNumber)}
      >
        {parsedPhoneNumber.formattedPhoneNumber}
      </Link>
    ) : (
      value
    );
  };

  const toggleDialogOpen = () => {
    setOpen(!open);
  };

  const copyTranscriptInPlainTextFormat = async (transcripts = []) => {
    let transcript = "";
    [...transcripts]
      .sort((t1, t2) => t1.BeginOffsetMillis - t2.BeginOffsetMillis)
      .map((trnscript) => {
        transcript += `${formatMillisToMinutesAndSeconds(
          trnscript?.["EndOffsetMillis"]
        )} \t ${
          TRANSCRIPT_PARTICIPANTS[trnscript?.["ParticipantId"]] ??
          trnscript?.["ParticipantId"]
        } \t ${trnscript?.Content} \n`;
      });
    await navigator.clipboard.writeText(transcript);
    setShowTranscriptCopiedSnackbar(true);
  };

  const transcriptBtnClickEvent = async (copyTranscript = false) => {
    let response;
    if (
      !rowData?.transcripts &&
      !!rowData?.["ContactID"] &&
      areTranscriptsEnabledForInstance &&
      !!transcriptsBucketName &&
      !!rowData?.timestamp
    ) {
      response = await dispatch(
        getTranscriptDataByContactId({
          contactId: rowData?.["ContactID"],
          bucketName: transcriptsBucketName,
          areTranscriptsEnabledForInstance,
          timestamp: rowData?.timestamp,
        })
      );
      if (response?.payload?.transcripts) {
        setRowData(response.payload);
      }
    }
    if (rowData?.transcripts || response?.payload?.transcripts) {
      if (copyTranscript) {
        await copyTranscriptInPlainTextFormat(
          (rowData?.transcripts || response?.payload?.transcripts) ?? []
        );
      } else {
        setOpenTranscriptDialog(!openTranscriptDialog);
      }
    } else {
      setShowTranscriptError(!showTranscriptError);
    }
  };

  const toggleOpenDialPrompt = () => {
    setOpenDialPrompt(!openDialPrompt);
  };

  const getSnackBarMessage = () => {
    if (!!openSnackbar) {
      return "Invalid number";
    }
    if (!!showTranscriptError) {
      return "Transcripts are not available at the moment. Please try again later...";
    }
    if (!!showTranscriptCopiedSnackbar) {
      return COPIED_TO_CLIPBOARD;
    }
  };

  return (
    <>
      {rowData.detailsRows ? (
        <DetailRow row={rowData} linkRenderer={linkRenderer} />
      ) : (
        <MasterRow
          row={rowData}
          toggleDialogOpen={toggleDialogOpen}
          transcriptBtnClickEvent={transcriptBtnClickEvent}
          linkRenderer={linkRenderer}
          areTranscriptsEnabledForInstance={areTranscriptsEnabledForInstance}
          showTranscriptCopiedSnackbar={showTranscriptCopiedSnackbar}
        />
      )}
      {open && (
        <SbuxLayer isOpen={open}>
          <SbuxDialog
            toggleDialogOpen={toggleDialogOpen}
            isrenderCloseButton={true}
            isClickAwayEnabled={true}
          >
            <div>
              <SbuxHeading
                tagName="h6"
                size="xs"
                className={classes.dialogTitle}
              >
                <SbuxTranslate> {`Contact Details`}</SbuxTranslate>
              </SbuxHeading>
              <DetailTable
                masterRow={omitKeyFromObject(
                  "areTranscriptsEnabledForCall",
                  rowData
                )}
              />
            </div>
          </SbuxDialog>
        </SbuxLayer>
      )}
      {openTranscriptDialog && (
        <SbuxLayer isOpen={openTranscriptDialog}>
          <SbuxDialog
            toggleDialogOpen={() =>
              setOpenTranscriptDialog(!openTranscriptDialog)
            }
            isrenderCloseButton={true}
            isClickAwayEnabled={true}
          >
            <div>
              <SbuxHeading
                tagName="h6"
                size="xs"
                className={classes.dialogTitle}
              >
                <SbuxTranslate> {"Transcript"}</SbuxTranslate>
              </SbuxHeading>
              <TranscriptContent data={rowData} />
            </div>
          </SbuxDialog>
        </SbuxLayer>
      )}
      {openDialPrompt && (
        <PhoneNumberDialog
          openDialPrompt={openDialPrompt}
          toggleOpenDialPrompt={toggleOpenDialPrompt}
          parsedPhoneNumber={parsedPhoneNumber}
        />
      )}
      {(openSnackbar ||
        showTranscriptError ||
        showTranscriptCopiedSnackbar) && (
        <SbuxSnackbars
          open={
            openSnackbar || showTranscriptError || showTranscriptCopiedSnackbar
          }
          anchorOriginHorizontal={openSnackbar ? "left" : "right"}
          width={openSnackbar ? "26%" : "100%"}
          top={"18%"}
          message={
            <>
              <div style={{ fontWeight: 600 }}>
                <SbuxTranslate>{getSnackBarMessage()}</SbuxTranslate>
              </div>
              {openSnackbar && <div>{HISTORY_NOT_A_VALID_PHONE_NUMBER}</div>}
            </>
          }
          severity={
            openSnackbar || showTranscriptError
              ? SNACK_BARS_SEVERITY_ERROR
              : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default Row;
