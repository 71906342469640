import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
root: {
    "@media (min-width: 1024px)": {
      "&.sb-contentCrate": {
       marginLeft:"27vw",
      },
    },
  },
}));


export default useStyles;
