import SbuxSelect from "../../../../../../components/SbuxSelect";
import SbuxCustomRow from "../../../../../../components/SbuxTable/SbuxCustomRow";
import TemporaryMsgAutoCompleteTime from "./TemporaryMsgAutoCompleteTime";
import { getTime } from "../../../../../../utils/timeUtil";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import useStyles from "../../../styles";
import moment from "moment-timezone";

const TemporaryMsgTimePicker = ({
  name,
  startDate,
  endDate,
  startTimeValue,
  endTimeValue,
  timeZoneValue,
  timeZoneList,
  handleDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleTimeZoneChange,
  disabled = false,
  startTimeOption,
  endTimeOption,
  row,
}) => {
  const classes = useStyles();
  return (
    <SbuxCustomRow
      name=" "
      value={
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1, marginTop: 0.1, marginRight: 1 }}>
            <DatePicker
              className={classes.datePicker}
              selected={name === "Start Time" ? startDate : endDate}
              dateValue={name === "Start Time" ? startDate : endDate}
              minDate={moment().toDate()}
              onChange={(date) => handleDateChange({ date, name })}
              timeFormat="YYYY-MM-DD"
            />
          </Box>
          <Box sx={{ flex: 1, marginTop: 0.1, marginRight: 1 }}>
            <TemporaryMsgAutoCompleteTime
              startTimeOptions={getTime(null)}
              endTimeOptions={getTime(endTimeValue)}
              name={name}
              startDate={startDate}
              endDate={endDate}
              startTimeValue={startTimeValue}
              endTimeValue={endTimeValue}
              startTimeOption={startTimeOption}
              endTimeOption={endTimeOption}
              timeZone={timeZoneValue}
              handleStartTimeChange={(time) =>
                handleStartTimeChange({ time, name })
              }
              handleEndTimeChange={(time) =>
                handleEndTimeChange({ time, name })
              }
              row={row}
            />
          </Box>
          {name === "Start Time" ? (
            <>
              <Box
                sx={{
                  flex: 1,
                  opacity: disabled ? 0.65 : 1,
                  pointerEvents: disabled ? "none" : "initial",
                }}
              >
                <SbuxSelect
                  width={"16vw"}
                  menuItems={timeZoneList}
                  defaultValue={timeZoneValue}
                  value={timeZoneValue}
                  handleSelectChange={(timeZone) =>
                    handleTimeZoneChange(timeZone)
                  }
                />
              </Box>
            </>
          ) : null}
        </Box>
      }
    />
  );
};

export default TemporaryMsgTimePicker;
