import "amazon-connect-streams";
import { useEffect, useCallback, useState } from "react";
import SbuxCard from "../../../components/SbuxCard";
import OverLayCcp from "./OverlayCcp";
import CallerIdOverlayCcp from "./CallerIdOverlayCcp";
import { useDispatch, useSelector } from "react-redux";
import { getCcpInitializedStatus, getCcpAgent } from "../../../services/ccp";
import { getFeatureFlags } from "../../../services/featureFlags";
import { getCallHistory } from "../../../services/history";
import {
  getSettings,
  createOrUpdateLocaleSettings,
  getCCPLocaleStatus,
} from "../../../services/settings";
import { getGlobalConfig } from "../../../services/globalConfig";
import languageConfig from "../../../components/SbuxSettings/config/language.json";
import { getMetricsQueue, getMyMetricsData } from "../../../services/metrics";
import { getResources } from "../../../services/resources";
import { getMainHours } from "../../../services/mainHours";
import { getMediaMgt } from "../../../services/mediaMgt";
import { getClosureMsg } from "../../../services/closureMsg";
import { getTemporaryMsg } from "../../../services/temporaryMsg";
import { getTabsStatus } from "../../../services/tabs";
import { setOutboundCallerId } from "../../../services/outboundCall";
import { clientLog } from "../../../services/logging";
import { logMessage } from "../../../utils/amplifyLogger";
import useStyles from "../styles";

const InitCcp = ({ ccpUrl, loginUrl }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    ccp: {
      ccpAgent: { queues, agentRoutingQueues, agentRoutingName },
    },
    userInstance: {
      connectedInstance,
      connectedInstance: {
        appConfig: { isOutboundPhoneEnabled, outboundPhones },
      },
    },
    settings: { locale, ccpLocaleStatus, translatedStatus },
  } = useSelector((state) => state);

  const instanceId = connectedInstance ? connectedInstance.instanceId : null;
  const instanceArn = connectedInstance ? connectedInstance.arn : null;
  const [isIframeRendered, setIsIframeRendered] = useState(false);

  const metricsPayload = {
    instanceId,
    queues,
  };
  const myMetricsPayload = {
    instanceArn,
  };

  const handleCcpInitialize = useCallback(async () => {
    await dispatch(getCcpInitializedStatus(true));
  }, []);

  const handleAgentInitialize = useCallback(async (agent) => {
    agent.getEndpoints(agent.getAllQueueARNs(), {
      success: async (data) => {
        await dispatch(
          getCcpAgent({
            agentStatus: agent.getStatus().name,
            configuration: agent.getConfiguration(),
            endpoints: data.endpoints,
          })
        );
      },
      failure: function (err) {
        logMessage(`Init CCP`, `Failed. ${err}`, `error`);
      },
    });
  }, []);

  const handleIframOnLoad = useCallback(
    () => setIsIframeRendered(true),
    [isIframeRendered]
  );

  useEffect(async () => {
    try {
      if (instanceId) {
        outboundPhones &&
          (await dispatch(setOutboundCallerId(outboundPhones?.[0].value)));
        await dispatch(getSettings());
        await dispatch(getGlobalConfig());
        await dispatch(
          createOrUpdateLocaleSettings(languageConfig.translations)
        );
        await dispatch(getCallHistory());
        await dispatch(getClosureMsg());
        await dispatch(getTemporaryMsg());
        await dispatch(getMainHours());
        await dispatch(getMediaMgt());
        await dispatch(getFeatureFlags());
      } else {
        dispatch(getTabsStatus("disabled"));
      }
    } catch (error) {
      dispatch(getTabsStatus("disabled"));
      clientLog({
        component: `initCCP`,
        message: `Something went wrong in calling all api => ${error}`,
      });
    }
  }, []);

  useEffect(async () => {
    if (isIframeRendered) {
      const container = document.getElementById("ccp");
      window.connect.core.initCCP(container, {
        ccpUrl: ccpUrl,
        loginPopup: false,
        softphone: {
          allowFramedSoftphone: true,
        },
        loginOptions: {
          height: 400,
        },
        pageOptions: {
          enablePhoneTypeSettings: false,
        },
      });

      window.connect.core.onInitialized(handleCcpInitialize);
      window.connect.agent(handleAgentInitialize);
    }
  }, [isIframeRendered]);

  useEffect(async () => {
    if (!!agentRoutingName && instanceId) {
      Promise.allSettled([
        await dispatch(getMetricsQueue(metricsPayload)),
        await dispatch(getMyMetricsData(myMetricsPayload)),
        await dispatch(getResources()),
      ])
        .then(() => dispatch(getTabsStatus("enabled")))
        .catch((error) => {
          dispatch(getTabsStatus("disabled"));
          clientLog({
            component: `initCCP`,
            message: `Something went wrong in calling all api => ${error}`,
          });
        });
    }
  }, [agentRoutingName]);

  useEffect(async () => {
    if (ccpLocaleStatus === "updated" && translatedStatus === "success") {
      await window.connect.agent((agent) => {
        const config = agent.getConfiguration();
        const language = languageConfig.lanaguages.find(
          (f) => f.code === locale
        );
        const userPreferedLocale =
          language && language.hasOwnProperty("ccpCode")
            ? language.ccpCode
            : null;
        if (userPreferedLocale) {
          config.agentPreferences.locale = userPreferedLocale;
          agent.setConfiguration(config, {
            success: () => {
              console.log("success setting config");
            },
            failure: (err) => {
              console.warn("failed to set config", err);
            },
          });
        }
      });
      await dispatch(getCCPLocaleStatus());
    }
  }, [ccpLocaleStatus, translatedStatus]);

  return (
    <>
      {!isIframeRendered ? (
        <iframe
          className={classes.iframeDisplay}
          onLoad={handleIframOnLoad}
          src={loginUrl}
          id={"iframe"}
          frameBorder="0"
        />
      ) : (
        isIframeRendered && (
          <SbuxCard>
            <div
              id="ccp"
              style={
                isOutboundPhoneEnabled
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "-1px",
                    }
                  : {}
              }
              className={classes.ccpRoot}
            >
              <CallerIdOverlayCcp />
              <OverLayCcp />
            </div>
          </SbuxCard>
        )
      )}
    </>
  );
};

export default InitCcp;
